import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EcheanciersComponent } from './echeanciers.component';

const routes: Routes = [{ path: '', component: EcheanciersComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EcheanciersRoutingModule { }
