<div class="hamburger">
  <div class="toggle-btn" (click)="onToggleMenu()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<nav class="sidebar" id="sidebar" [ngClass]="toggle ? 'sidebar-close' :  'sidebar-open'">
  <div class="logo">
    <!-- <img src="assets/images/logo-menu.png" alt="Vie-Ecoles"> -->
  </div>
  <div class="user">
    <a class="" href="" class=""><span></span> {{user.name}} </a>
    <small> {{user.role}} </small>
  </div>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home" routerLinkActive="active" (click)="onToggleMenu()">Tableau de
        Bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/produits" routerLinkActive="active"
        (click)="onToggleMenu()">Produits scolaires</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/zones" routerLinkActive="active" (click)="onToggleMenu()">Zone de
        transport</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/activites" routerLinkActive="active"
        (click)="onToggleMenu()">Activites</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/souscripteurs" routerLinkActive="active"
        (click)="onToggleMenu()">Souscripteurs</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/vehicules" routerLinkActive="active"
        (click)="onToggleMenu()">Parc-auto</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/encadreurs" routerLinkActive="active"
        (click)="onToggleMenu()">Encardreurs</a>
    </li>


    <li class="nav-item">
      <a class="nav-link" (click)="logout()">Deconnexion</a>
    </li>
  </ul>

</nav>
<div id="main">
  <div class="container-fluid">
    <main role="main">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
