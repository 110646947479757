import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-souscripteurs-echeancier',
  templateUrl: './souscripteurs-echeancier.component.html',
  styleUrls: ['./souscripteurs-echeancier.component.css']
})
export class SouscripteursEcheancierComponent implements OnInit, OnDestroy {
  @Input() eleve: string;
  @Input() produit: string;
  subscription = new Subscription();
  frais: Logistiques[];
  errorLoading = '';
  loading = true;
  today: Date = new Date();
  day;
  constructor(
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.day = `${this.today.getFullYear()}-${(this.today.getMonth() + 1)}-${this.today.getDate()}`;
    this.subscription.add(
      this.service.deuxArgs('abonnes_echeancier', this.eleve, this.produit)
        .subscribe(arg => {
          this.frais = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
