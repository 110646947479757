<h1>Sanctions</h1>
<div *ngIf="eleve">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/saanctions">Sanctions</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled"> {{eleve.nom}} {{eleve.prenoms}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-5">
                <h4>{{eleve.nom}} {{eleve.prenoms}} </h4>
                <div class="row">
                    <div class="col-md-6">
                        <p> Classe: <strong> {{eleve.idclasse}} </strong></p>
                    </div>
                    <div class="col-md-6">
                        <p>Redoublant: <strong> {{eleve.redoublant}} </strong></p>
                    </div>
                    <div class="col-md-6">
                        <p>Statut: <strong> {{eleve.statut}} </strong> </p>
                    </div>

                    <div class="col-md-6">
                        <p> {{collection}} fois sanctionné </p>
                    </div>
                </div>

                <p> <button class="button" (click)="open(content)">Nouvelle sanction</button> </p>
            </div>
            <div class="col-md-6 offset-md-1">
                <h5>Rechercher</h5>
                <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="classe">Du</label>
                            <input type="date" name="debut" class="form-control" ngModel>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="classe">Au</label>
                            <input type="date" name="fin" class="form-control" ngModel>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" [disabled]="submitted || search.value == null">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
                    </div>

                </form>
            </div>
        </div>

    </div>
    <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
    <div class="table-responsive" *ngIf="collection; else noSanction">
        <table class="table table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col">Faute</th>
                    <th scope="col">Sanction</th>
                    <th scope="col">Convocation</th>
                    <th scope="col">Auteur</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sanction of sanctions" [ngClass]="{'alert-danger ': sanction.exclu == 1 && sanction.levee == 0}">

                    <td>
                        <h6>{{sanction.fauteType}}</h6>
                        <p>{{sanction.faute}}</p>
                    </td>
                    <td>
                        <p *ngIf="sanction.point > 0">Points rétirés: <strong>{{sanction.point | number:'1.0-2 '}}</strong> </p>
                        <p *ngIf="sanction.exclu == 1">Exclu du {{sanction.excluDebut | date}} au {{sanction.excluFin | date}} </p>
                        <p>{{sanction.punition}}</p>

                    </td>
                    <td>
                        <p class="text-danger" *ngIf="sanction.convocationDate !== null">
                            {{sanction.convocationDate | date}} à {{sanction.convocationHeure}}
                        </p>

                    </td>
                    <td> {{sanction.nom}} {{sanction.prenoms}}
                        <p>{{sanction.sanctionDate | date}} </p>
                    </td>
                    <!-- <td><button class="button" (click)="open(content)">Sanctionner</button>



                        <ng-template #content let-c="close" let-d="dismiss">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="modal-basic-title">
                                        Sanction pour {{sanction.fauteType}} </h5>
                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click ')">
      <span aria-hidden="true">&times;</span>
    </button>
                                </div>
                                <div class="modal-body">
                                    <ngb-alert type="danger" (close)="errorMsg=' '" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
                                    <input type="hidden" formControlName="id" [(ngModel)]="sanction.idsanction">
                                    <div class="form-group">
                                        <label for="type">Type de faute</label>
                                        <select formControlName="fauteType" id="faute" class="form-control" rows="3" [(ngModel)]="sanction.fauteType">
                                          <option *ngFor="let type of types"  [value]="type.id"> {{type.fauteType}} </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="faute">Faute commise</label>
                                        <textarea formControlName="faute" id="faute" class="form-control" rows="3" [(ngModel)]="sanction.faute"></textarea>
                                    </div>

                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" formControlName="reparation" id="reparation" [value]="1">
                                            <label class="form-check-label" for="reparation">   Reparation  </label>
                                        </div>
                                    </div>
                                    <fieldset *ngIf="form.get('reparation ').value == 1">
                                        <legend>Réparation</legend>
                                        <div class="form-group">
                                            <label for="punition" class="sr-only">Punition</label>
                                            <textarea formControlName="punition" id="punition" class="form-control" rows="3" [(ngModel)]="sanction.punition" placeholder="Indiquer l'action que l 'élève doit mener"></textarea>
                                        </div>
                                        <div class="form-group row">
                                            <label for="point" class="col-md-3 col-form-label">Point à retirer</label>
                                            <div class="col-md-3">
                                                <input type="number" formControlName="point" id="point" class="form-control" [(ngModel)]="sanction.point" min="0" max="5">
                                            </div>
                                        </div>

                                    </fieldset>
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" formControlName="exclu" id="exclu" [value]="1">
                                            <label class="form-check-label" for="exclu">   Exclusion  </label>
                                        </div>
                                    </div>
                                    <fieldset *ngIf="form.get('exclu ').value == 1">
                                        <legend>Exclusion de l'élève</legend>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="excluDebut">Du </label>
                                                <input type="date" formControlName="excluDebut" id="excluDebut" class="form-control" [(ngModel)]="sanction.excluDebut">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="excluDebut">Au </label>
                                                <input type="date" formControlName="excluFin" id="excluFin" class="form-control" [(ngModel)]="sanction.excluFin">
                                            </div>
                                        </div>
                                    </fieldset>



                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" formControlName="convocation" id="convocation" [value]="1">
                                            <label class="form-check-label" for="convocation">
    Convoquer les parents
  </label>
                                        </div>
                                    </div>
                                    <fieldset *ngIf="form.get('convocation').value == 1 ">
                                        <legend>Convocation</legend>
                                        <div class="form-row">
                                            <div class="form-group col-md-7">
                                                <label for="convocationDate">Date </label>
                                                <input type="date" formControlName="convocationDate" id="convocationDate" class="form-control" [(ngModel)]="sanction.convocationDate">
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label for="convocationHeure">Heure </label>
                                                <input type="time" formControlName="convocationHeure" id="convocationHeure" class="form-control" [(ngModel)]="sanction.convocationHeure">
                                            </div>
                                        </div>
                                    </fieldset>


                                    <div class="form-group">
                                        <button type="submit" [disabled]="form.invalid || submitted === true">
                    <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
                </button>
                                    </div>
                                </div>
                            </form>
                        </ng-template></td>
-->


                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noSanction>
        <h3 class="text-center errorLoading" *ngIf="!loading">Aucune sanction trouvée</h3>
    </ng-template>
</div>



<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title"> Nouvelle sanction</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click ')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=' '" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="type">Type de faute</label>
                <select formControlName="fauteType" id="fauteType" class="form-control">
                                          <option *ngFor="let type of types"  [value]="type.id"> {{type.fauteType}} </option>
                                        </select>
            </div>
            <div class="form-group">
                <label for="faute">Faute commise</label>
                <textarea formControlName="faute" id="faute" class="form-control" rows="3"></textarea>
            </div>
            <div class="form-group">
                <label for="sanctionDate">Date de Faute</label>
                <input formControlName="sanctionDate" id="sanctionDate" class="form-control">
            </div>

            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="reparation" id="reparation" [value]="1">
                    <label class="form-check-label" for="reparation">Reparation </label>
                </div>
            </div>
            <fieldset *ngIf="form.get('reparation').value == 1">
                <legend>Réparation</legend>
                <div class="form-group">
                    <label for="punition" class="sr-only">Punition</label>
                    <textarea formControlName="punition" id="punition" class="form-control" rows="3" placeholder="Indiquer l'action que l 'élève doit mener"></textarea>
                </div>
                <div class="form-group row">
                    <label for="point" class="col-md-3 col-form-label">Point à retirer</label>
                    <div class="col-md-3">
                        <input type="number" formControlName="point" id="point" class="form-control" min="0" max="5">
                    </div>
                </div>

            </fieldset>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="exclu" id="exclu" [value]="1">
                    <label class="form-check-label" for="exclu">Exclusion </label>
                </div>
            </div>
            <fieldset *ngIf="form.get('exclu').value == 1">
                <legend>Exclusion de l'élève</legend>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="excluDebut">Du </label>
                        <input type="date" formControlName="excluDebut" id="excluDebut" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="excluDebut">Au </label>
                        <input type="date" formControlName="excluFin" id="excluFin" class="form-control">
                    </div>
                </div>
            </fieldset>



            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="convocation" id="convocation" [value]="1">
                    <label class="form-check-label" for="convocation">
    Convoquer les parents
  </label>
                </div>
            </div>
            <fieldset *ngIf="form.get('convocation').value == 1 ">
                <legend>Convocation</legend>
                <div class="form-row">
                    <div class="form-group col-md-7">
                        <label for="convocationDate">Date </label>
                        <input type="date" formControlName="convocationDate" id="convocationDate" class="form-control">
                    </div>
                    <div class="form-group col-md-5">
                        <label for="convocationHeure">Heure </label>
                        <input type="time" formControlName="convocationHeure" id="convocationHeure" class="form-control">
                    </div>
                </div>
            </fieldset>


            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                    <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>
