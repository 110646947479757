import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EncadreursRoutingModule } from './encadreurs-routing.module';
import { EncadreursComponent } from './encadreurs.component';
import { EncadreursHomeComponent } from './encadreurs-home/encadreurs-home.component';
import { EncadreursViewComponent } from './encadreurs-view/encadreurs-view.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EncadreursUpdateComponent } from './encadreurs-update/encadreurs-update.component';


@NgModule({
  declarations: [
    EncadreursComponent,
    EncadreursHomeComponent,
    EncadreursViewComponent,
    EncadreursUpdateComponent
  ],
  imports: [
    CommonModule,
    EncadreursRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class EncadreursModule { }
