import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormattingService } from '../../config/formatting.service';
import { Subscription } from 'rxjs';
import { Directeur } from '../directeur';
import { DirecteurService } from '../directeur.service';
import { SuivisService } from '../../suivis/suivis.service';


@Component({
  selector: 'app-directeur-home',
  templateUrl: './directeur-home.component.html',
  styleUrls: ['./directeur-home.component.css']
})
export class DirecteurHomeComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  seances: number;
  absences: number = 0;
  nonRenseignes = 0;
  errorMsg = '';
  bilan: Directeur;
  branches: Directeur[];
  matieres: Directeur[];
  classes: Directeur[];
  profs: Directeur[];
  loading = true;
  today: Date = new Date();


  constructor(
    private service: DirecteurService,
    private service2: SuivisService,
    public formatting: FormattingService
  ) { }


  ngOnInit(): void {
    this.getSeanceBilan();
    this.getNonRenseignes();
    this.getAbsences();
  }

  getSeanceBilan(): void {
    this.subscription.add(
      this.service.view('seancesBilanDuJour')
        .subscribe(arg => {
          this.bilan = arg;
          this.loading = false;
        })
    );
  }

  getNonRenseignes(): void {
    this.subscription.add(
      this.service2.list('seances?cahier=0')
        .subscribe(arg => {
          this.nonRenseignes = arg.length;
          this.loading = false;
        }, erg => {
          this.errorMsg = erg;
          this.loading = false;
        })
    );
  }

  getAbsences(): void {
    this.subscription.add(
      this.service2.list('absences?today=1')
        .subscribe(arg => {
          this.absences = arg.length;
          this.loading = false;
        }, erg => {
          this.errorMsg = erg;
          this.loading = false;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
