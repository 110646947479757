import { Component, OnInit, OnDestroy } from '@angular/core';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-parc-auto-vehicules',
  templateUrl: './parc-auto-vehicules.component.html',
  styleUrls: ['./parc-auto-vehicules.component.css']
})
export class ParcAutoVehiculesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  vehicules: Logistiques[];
  form: FormGroup;
  loading = true;
  transmission = ['Manuel', 'Automatique'];
  carburant = ['Essence', 'Diesel'];
  collection = 0;
  page = 1;
  pageSize = 10;
  errorMsg = '';
  submitted = false;
  errorLoading = '';

  constructor(
    private service: LogistiquesService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getVehicules();
    this.createForm();
  }

  getVehicules(): void {
    this.subscription.add(this.service.list('vehicules')
      .subscribe(arg => {
        this.vehicules = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        }));
  }

  open(content): void {
    this.modalService.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      modele: ['', Validators.required],
      immatriculation: ['', Validators.required],
      annee: ['', Validators.required],
      places: ['', Validators.required],
      portes: [''],
      couleur: ['', Validators.required],
      transmission: ['', Validators.required],
      carburant: ['', Validators.required],
      puissance: ['', Validators.required],
      acquisition: [''],
      etat: [''],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('vehicules', this.form.value)
        .subscribe(arg => {
          this.getVehicules();
          this.modalService.dismissAll();
        }, erg => {
          this.errorMsg = erg.error.message;
          this.submitted = false;
        })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
