import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-evaluations-view',
  templateUrl: './evaluations-view.component.html',
  styleUrls: ['./evaluations-view.component.css']
})
export class EvaluationsViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  id: string;
  e: Suivis;
  bilan: Suivis;
  eleves: Suivis[];
  loading = true;
  errorMsg = '';
  submitted = false;
  today: Date = new Date();
  errorType = '';
  types: Suivis[];
  classes: Suivis[];
  matieres: Suivis[];
  periodes = ['1er Trimestre', '2e Trimestre', '3e Trimestre', '1er Semestre', '2e Semestre'];
  jour: any;


  constructor(
    private modal: NgbModal,
    private route: Router,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private service: SuivisService,
    @Inject(LOCALE_ID) private locale: string) {
    this.jour = formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
  }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getEvaluation(this.id);
    this.getBilan(this.id);
    this.getEleves(this.id);
    this.getEvaluationTypes();
    this.createForm();
  }
  getEvaluation(id: string): void {
    this.subscription.add(
      this.service.view('evaluations', id)
        .subscribe(
          (arg: Suivis) => {
            this.e = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getBilan(id: string): void {
    this.subscription.add(
      this.service.view('evaluations_bilan', id)
        .subscribe(
          (arg: Suivis) => {
            this.bilan = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getEleves(evaluation: string): void {
    this.subscription.add(
      this.service.views('evaluation_eleves', evaluation)
        .subscribe(
          (arg: Suivis[]) => {
            this.eleves = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }



  appreciation(note: number, notation: number): string {
    if (note > (notation * 0.7)) {
      return 'Excellent';
    }
    else if (note <= (notation * 0.7) && note >= (notation * 0.6)) { return 'Bon'; }
    else if (note <= (notation * 0.59) && note >= (notation * 0.5)) { return 'Passable'; }
    else if (note <= (notation * 0.5) && note >= (notation * 0.4)) { return 'Passable'; }
    else { return 'Faible'; }
  }

  onEvaluationExecutee(e: Suivis): void {
    this.subscription.add(
      this.service.update('evaluations_executee', this.id, e)
        .subscribe(
          arg => {
            this.getEvaluation(this.id);
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getMatieres(): void {
    this.subscription.add(
      this.service.list('matieres')
        .subscribe(arg => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  getEvaluationTypes(): void {
    this.subscription.add(
      this.service.list('evaluations_types')
        .subscribe(arg => {
          this.types = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes_evaluation')
        .subscribe(arg => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onDelEvaluation(): void {
    const r = confirm('Vous êtes sûr de la suppression ?');
    if (r) {

      this.subscription.add(
        this.service.delete('evaluations', this.id)
          .subscribe(arg => {
            this.loading = false;
            this.route.navigateByUrl('/' + this.user.role + '/evaluations');
          },
            (erg: never) => {
              this.errorMsg = erg;
              this.loading = false;
            })
      );
    }
  }


  open(c): void {
    this.modal.open(c);
  }



  createForm(): void {
    this.form = this.fb.group({
      type: ['', Validators.required],
      note: ['', Validators.required],
      jour: ['', Validators.required],
      periode: ['', Validators.required],
      heure: ['', Validators.required],
      duree: [''],
      calculer: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('evaluations', this.id, this.form.value)
        .subscribe(
          arg => {
            this.getEvaluation(this.id);
            this.submitted = false;
            location.reload();
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }

  onTerminerNotation(ev: Suivis): void {
    this.subscription.add(
      this.service.troisArg('moyennes_classement', ev.idclasse, ev.periode, ev.idmatiere)
        .subscribe(arg => {
          this.route.navigateByUrl('/' + this.user.role + '/evaluations');
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
