<div class="row justify-content-center">
    <div class="col-lg-5">
        <ngb-alert *ngIf="errorMsg" [type]="errorType" (close)="errorMsg=''"> {{errorMsg}} </ngb-alert>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="col-lg-7">
                    <div class="form-group">
                        <input type="email" formControlName="email" placeholder="ADRESSE E-MAIL" class="form-control">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="form-group">
                        <input type="text" formControlName="code" placeholder="CODE ÉCOLE" class="form-control">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">Envoyer</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Envoi
                  </span>
                </button>
            </div>
        </form>
        <p class="text-center"><a routerLink="/authentication/login">Aller à la page de connexion</a></p>

    </div>
</div>