import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Logistiques } from 'src/app/logistiques/logistiques';
import { LogistiquesService } from 'src/app/logistiques/logistiques.service';

@Component({
  selector: 'app-produits-abonne-groupe',
  templateUrl: './produits-abonne-groupe.component.html',
  styleUrls: ['./produits-abonne-groupe.component.css']
})
export class ProduitsAbonneGroupeComponent implements OnInit, OnDestroy {
  @Input() eleve: string;
  @Input() produit: string;
  @Input() prod: string;
  subscription: Subscription = new Subscription();
  g: Logistiques;
  groupes: Logistiques[];
  form: FormGroup;
  submitted = false;
  errorMg = '';
  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getGroupe(this.eleve, this.produit);
    this.getGroupes(this.produit);
    this.createForm();
  }

  getGroupe(e: string, gr: string): void {
    this.subscription.add(
      this.service.deuxArg('eleve_groupe', e, gr)
        .subscribe(arg => {
          this.g = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getGroupes(id: string): void {
    this.subscription.add(
      this.service.deuxArgs('groupes_produit', this.prod, id)
        .subscribe(arg => {
          this.groupes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  open(c: any): void {
    this.modal.open(c);
  }


  createForm(): void {
    this.form = this.fb.group({
      eleve: [this.eleve, Validators.required],
      groupe: ['', Validators.required],
      arret: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('groupes_eleves', this.form.value)
        .subscribe(arg => {
          this.getGroupe(this.eleve, this.produit);
          this.modal.dismissAll();
          this.submitted = false;
        },
          erg => {
            this.errorMg = erg.error.message;
            this.submitted = false;
          },
          () => {
            this.submitted = false;
          })
    );

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
