<div *ngIf="eleve">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-inline">
        <div class="form-group">
            <input type="text" formControlName="note" class="form-control" [max]="evaluation.notation" *ngIf="note" [(ngModel)]="note.note">
            <input type="number" formControlName="note" class="form-control" max="{{evaluation.notation}}" *ngIf="!note">/{{evaluation.notation}}
            <span class="submitted" *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <fa-icon [icon]="errorType === 'success' ? faSuccess : faError" flip="horizontal" pull="center" [ngClass]="{'success': errorType === 'success', 'error': errorType === 'error'}" *ngIf="errorType"> </fa-icon>
        </div>
    </form>
</div>
