import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-produits-view',
  templateUrl: './produits-view.component.html',
  styleUrls: ['./produits-view.component.css']
})
export class ProduitsViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  id: string;
  s: Logistiques;
  produits: Logistiques[];
  abonnes = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getServices(this.id);
    this.getProduits(this.id);
    this.getAbonnes(this.id);
  }

  getServices(id: string): void {
    this.subscription.add(
      this.service.view('services', id)
        .subscribe(arg => {
          this.s = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('services_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getProduits(id: string): void {
    this.subscription.add(
      this.service.views('produits', id)
        .subscribe(arg => {
          this.produits = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
