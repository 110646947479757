<div *ngIf="d">
    <a class="button float-right" (click)="open(content)">Ajouter</a>
    <h5>{{d.designation}} </h5>
    <p> <small>{{d.description}}</small> </p>
    <div class="table-responsive" *ngIf="frais; else noFrais">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Nouveau</th>
                    <th scope="col">Ancien</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let f of frais">
                    <td> {{ f.datelimite | date }} </td>
                    <td> {{f.montant2 | currency:'XOF'}} </td>
                    <td> {{f.montant | currency:'XOF'}} </td>

                    <td> {{ f.statut }} </td>
                    <td>
                        <a (click)="onDelete(f.idfrais)" class="text-danger"> X </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noFrais>
        <p *ngIf="errorLoading" class="text-center"> {{errorLoading}} </p>
    </ng-template>

    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">Echéance pour {{d.designation}} </h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="branche">Niveau</label>
                        <select formControlName="niveau" class="form-control" id="branche">
                    <option [value]="'*'">Tous les niveaux</option>
                      <option *ngFor="let b of branches" [value]="b.code"> {{b.nom}} </option>
                    </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="statut">Statut</label>
                        <select formControlName="statut" class="form-control" id="branche">

                      <option *ngFor="let s of statuts" [value]="s.code"> {{s.nom}} </option>
                    </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="rubrique">Echeancier</label>
                        <select formControlName="echeance" class="form-control" id="echeance">
                      <option *ngFor="let e of echeances" [value]="e.code"> {{e.nom}} </option>
                    </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="datelimite">Date limite</label>
                        <input type="date" formControlName="datelimite" class="form-control">
                    </div>

                    <div class="form-group  col-md-4">
                        <label for="libelle">Libellé</label>
                        <input type="text" formControlName="libelle" class="form-control">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="montant">Montant Ancien</label>
                        <input type="number" formControlName="montant" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="monatnt2">Montant Nouveau</label>
                        <input type="number" formControlName="montant2" class="form-control">
                    </div>
                </div>

                <div class="form-group">
                    <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
                </div>
            </div>
        </form>
    </ng-template>
</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>