import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';

@Component({
  selector: 'app-seances-appel',
  templateUrl: './seances-appel.component.html',
  styleUrls: ['./seances-appel.component.css']
})
export class SeancesAppelComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  seance: Suivis;
  eleves: Suivis[] = [];
  loading = true;
  errorMsg = '';
  submitted = false;
  faError = faTimes;
  faSuccess = faCheck;
  faVerrou = faLock;


  constructor(
    private ar: ActivatedRoute,
    private router: Router,
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getSeance(this.id);
    this.getEleves(this.id);
  }

  getSeance(id: string): void {
    this.subscription.add(
      this.service.view('seances', id)
        .subscribe((arg: Suivis) => {
          this.seance = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getEleves(id: string): void {
    this.subscription.add(
      this.service.list(`seances_eleves?seance=${id}`)
        .subscribe((arg: Suivis[]) => {
          this.eleves = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }

  onProfpresent(a): void {
    this.submitted = true;
    console.log(a);
    this.subscription.add(
      this.service.update('seances_profpresent', this.id, this.seance)
        .subscribe(
          (arg: string) => {
            this.submitted = false;
            this.router.navigateByUrl('/' + this.user.role + '/seances/view/' + this.id);

          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }
  onAssiduite(eleve: Suivis): void {
    this.submitted = true;
    eleve.presence = eleve.presence === '1' ? 0 : 1;
    console.log(eleve);
    this.subscription.add(
      this.service.update('seances_eleves', eleve.id, eleve)
        .subscribe(
          (arg: string) => {
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
