<div class="text-center">
    <fa-icon [icon]="seances > 0   && (!cahier.dateLimite || cahier.dateLimite < (today | date: 'Y-MM-dd')) ? faLock : faLockOpen" flip="horizontal" pull="center" [class]="seances > 0   && (!cahier.dateLimite || cahier.dateLimite < (today | date: 'Y-MM-dd'))  ? 'error': 'static'"
        title="Séances non renseignée">
    </fa-icon>

    <fa-icon [icon]="retard > 0 ? faCalendarTimes : faCalendar " flip="horizontal" pull="center" [class]="retard > 0 ?'error':'static'" title="En retard sur la progression"> </fa-icon>
    <div *ngIf="user.role === 'directeur' ">
        <button class="button btn-sm" *ngIf="seances > 0   && (!cahier.dateLimite || cahier.dateLimite < (today | date: 'Y-MM-dd')) " (click)="open(content)">Debloquer le cahier </button>
    </div>
    <p class="text-danger" *ngIf="seances > 0   && cahier.dateLimite > (today | date: 'Y-MM-dd') "> Vérrouilage du cahier après le {{cahier.dateLimite | date}} </p>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Déblocage du Cahier de texte</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <ngb-alert type="danger" *ngIf="errorMsg" (close)="errorMsg=''"> {{errorMsg}} </ngb-alert>
        <p>Cette action est irreversible</p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="debloque">Date limite</label>
                <input type="date" formControlName="debloque" id="debloque" class="form-control">
            </div>
            <div class="form-group">
                <button type="submit">
                  <span *ngIf="!submitted">Enrégistrer</span>
                  <span *ngIf="submitted">
                      <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              enrégistrement
                  </span>
                </button>
            </div>
        </form>
    </div>
</ng-template>