<div *ngIf="e">
    <div class="alert" [ngClass]="{'alert-danger': evaluation.delaiNote < jour, 'alert-success': evaluation.delaiNote > jour, 'alert-warning': evaluation.delaiNote == jour }">
        <p>Date limite de saisir des notes:</p>
        <p> <strong>{{e.delaiNote | date:'fullDate'}}</strong></p>

        <small *ngIf=" evaluation.delaiNote < jour">Le delai d'enrégistrement de note a expiré</small>
    </div>
    <div>
        <p *ngIf="evaluation.delaiNote < jour">
            <button class="button btn-block" *ngIf="user.role === 'directeur' " (click)="open(content)">Modifier le délai de saisir de notes</button>
        </p>
    </div>





    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h6 class="modal-title" id="modal-basic-title">
                    Délai de saisir de note pour évaluation {{evaluation.id}}
                </h6>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

                <div class="form-group">
                    <label for="delai">Nouvelle date limite</label>
                    <input type="date" formControlName="delai" id="delai" class="form-control" [(ngModel)]="evaluation.delaiNote">
                </div>


                <div class="form-group">
                    <button type="submit" [disabled]="form.invalid || submitted === true">
                    <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
                </button>
                </div>
            </div>
        </form>
    </ng-template>
</div>