import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SuivisService } from '../../Suivis.service';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';

@Component({
  selector: 'app-cahiers-textes-lecon',
  templateUrl: './cahiers-textes-lecon.component.html',
  styleUrls: ['./cahiers-textes-lecon.component.css']
})
export class CahiersTextesLeconComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() id: string;
  lecon: Suivis;
  errorMsg = '';

  constructor(
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.getLecon(this.id);
  }

  getLecon(id: string): void {
    this.subscription.add(
      this.service.view('lecons', id)
        .subscribe(
          (arg: Suivis) => {
            this.lecon = arg;
          },
          (erg: never) => {
            this.errorMsg = erg;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
