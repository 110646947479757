<h1>Services scolaires</h1>
<div *ngIf="s">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{s.nom}}</a>
        </li>
    </ul>
    <div class="recherche">
        <h2> {{s.nom}}
            <small *ngIf="abonnes"> {{abonnes | number}} inscrits  </small> </h2>
    </div>
</div>
<div class="table-responsive" *ngIf="produits; else noProduits">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Produits</th>
                <th scope="col">Zone</th>
                <th scope="col">Nouveau</th>
                <th scope="col">Anciens</th>
                <th scope="col">Inscrits</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of produits">
                <td>
                    <a routerLink="/{{user.role}}/services/produits/{{p.iddetail}}" title="Voir le detail">
                       {{ p.designation }}</a>
                    <small> {{p.description}} </small>
                </td>
                <td> {{p.zone}} </td>
                <td> {{p.prix2 | currency:'XOF'}} </td>
                <td> {{p.prix | currency:'XOF'}} </td>
                <td>
                    <app-logistiques-produits-abonnes [id]="p.iddetail"></app-logistiques-produits-abonnes>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noProduits>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
