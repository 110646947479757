import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../convoyeur';
import { ConvoyeurService } from '../convoyeur.service';

@Component({
  selector: 'app-groupes',
  templateUrl: './groupes.component.html',
  styleUrls: ['./groupes.component.css']
})
export class GroupesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  groupes: Convoyeur[];
  collection = 0;
  loading = true;
  errorMsg = '';


  constructor(
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    this.getGroupes();
  }

  getGroupes(): void {
    this.subscription.add(
      this.service.list('groupes')
        .subscribe(arg => {
          this.groupes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  back(): void {
    history.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
