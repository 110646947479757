import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-produits-detail',
  templateUrl: './produits-detail.component.html',
  styleUrls: ['./produits-detail.component.css']
})
export class ProduitsDetailComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  form: FormGroup;
  id: string;
  p: Logistiques;
  frais: Logistiques[];
  abonnes: Logistiques[];
  classes: Logistiques[];
  sexes = ['M', 'F'];
  statuts = ['AFF', 'NAFF', 'PC'];
  collection = 0;
  errorLoading = '';
  loading = true;
  submitted = false;
  errorMsg = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getProduit(this.id);
    this.getAbonnes(this.id);
    this.getEcheanciers(this.id);
    this.getClasses();
    this.createForm();
  }

  getProduit(id: string): void {
    this.subscription.add(
      this.service.view('produit', id)
        .subscribe(arg => {
          this.p = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('produits_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getEcheanciers(id: string): void {
    this.subscription.add(
      this.service.views('frais', id)
        .subscribe(arg => {
          this.frais = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe(arg => {
          this.classes = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  onRechercheNom(search): void {
    this.subscription.add(
      this.service.deuxArgs('produits_nom_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  onRechercheClasse(search): void {
    this.subscription.add(
      this.service.deuxArgs('produits_classe_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  onRechercheSexe(search): void {
    this.subscription.add(
      this.service.deuxArgs('produits_sexe_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      classe: [''],
      sexe: [''],
      statut: ['']
    });
  }


  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.search('produits_abonnes_recherche', this.form.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
