import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-logistiques',
  templateUrl: './logistiques.component.html',
  styleUrls: ['./logistiques.component.css']
})
export class LogistiquesComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  toggle: boolean;

  constructor(private service: AuthenticationService) { }

  ngOnInit(): void { }

  onToggleMenu(): boolean {
    return !this.toggle;
  }

  logout(): void {
    this.service.logout();
  }
}
