<div *ngIf="activite">
    <div class="header-mobile">{{activite.designation}}
        <i class="bi bi-chevron-compact-left" (click)="back()"></i>
    </div>

    <div class="body-mobile">
        <div class="container">
            <h4>{{activite.activite}} | <small>{{activite.action}}</small> </h4>
            <h5>{{activite.activite_date | date }} à {{activite.activite_heure}} </h5>

            <div class="table-responsible" *ngIf="collection > 0; else noAbonnes">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Souscripteur</th>
                            <th scope="col">Sexe</th>
                            <th scope="col">Classe</th>
                            <th scope="col">Presence</th>
                            <th scope="col">Date de validation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of abonnes">
                            <td> {{a.nom}} {{a.prenoms}} </td>
                            <td> {{a.sexe}} </td>
                            <td> {{a.idclasse}} </td>
                            <td [ngClass]="{'text-success': a.present == 1, 'text-danger': a.present == 0}"> {{a.present == 1 ? 'Present':'Absent' }} </td>
                            <td>{{a.date_validation | date:'medium'}} </td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noAbonnes>
                <p class="errorLoading"> Aucune données </p>
            </ng-template>
        </div>

    </div>

</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
