import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Subscription } from 'rxjs';
import { Users } from '../users';

@Component({
  selector: 'app-authentication-login',
  templateUrl: './authentication-login.component.html',
  styleUrls: ['./authentication-login.component.css']
})
export class AuthenticationLoginComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  form: FormGroup;
  errorMsg: string;
  submitted = false;

  constructor(
    private authenticationSce: AuthenticationService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.authenticationSce.login(this.form.value)
        .subscribe(
          (arg: Users) => {
            this.router.navigate([`/${arg.role}/home`]);
            this.submitted = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
