import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Administration } from '../../administration';
import { AdministrationService } from '../../administration.service';

@Component({
  selector: 'app-administration-service',
  templateUrl: './administration-service.component.html',
  styleUrls: ['./administration-service.component.css']
})
export class AdministrationServiceComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  s: Administration;
  details: Administration[];
  subscription = new Subscription();
  form: FormGroup;
  ecole: string;
  prod: string;
  collection = 0;
  loading = true;
  submitted = false;
  errorMsg = '';
  errorLoading = '';

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private modalService: NgbModal,
    private service: AdministrationService
  ) { }

  ngOnInit(): void {
    this.ecole = this.ar.snapshot.params.id;
    this.prod = this.ar.snapshot.params.s;
    this.getService(this.ecole, this.prod);
    this.getDetails(this.ecole, this.prod);
    this.createForm();
  }

  getService(ecole: string, s: string): void {
    this.subscription.add(
      this.service.deuxArg('services', ecole, s)
        .subscribe(arg => {
          this.s = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement';
            this.loading = false;
          })
    );
  }

  getDetails(ecole: string, s: string): void {
    this.subscription.add(
      this.service.deuxArgs('produits', ecole, s)
        .subscribe(arg => {
          this.details = arg;
          this.loading = false;
        })
    );
  }

  open(content): void {
    this.modalService.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      ecole: [this.ecole, Validators.required],
      service: [this.prod, Validators.required],
      designation: ['', Validators.required],
      prix: ['', Validators.required],
      prix2: ['', Validators.required],
      description: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('produits', this.form.value)
        .subscribe(
          arg => {
            this.getDetails(this.ecole, this.prod);
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }


  onDelete(id): void {
    this.subscription.add(
      this.service.delete('produits', id, this.ecole)
        .subscribe(arg => {
          this.getDetails(this.ecole, this.prod);
        },
          erg => {
            this.errorMsg = erg.error.message;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
