<h1>Établissements scolaires</h1>

<div *ngIf="ecole">
    <div class="recherche">
        <h2> {{ecole.nom}} </h2>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="encard">
                <h4>Produits et services</h4>
                <p>Définir les échéanciers des services et produits</p>
                <p>
                    <button class="button" routerLink="/{{user.role}}/services/{{ecole.code}}">Voir plus</button>
                </p>
            </div>
        </div>
    </div>


</div>
