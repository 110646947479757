import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';

@Component({
  selector: 'app-groupes-eleve-classe',
  templateUrl: './groupes-eleve-classe.component.html',
  styleUrls: ['./groupes-eleve-classe.component.css']
})
export class GroupesEleveClasseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string;
  subscription: Subscription = new Subscription();
  classe: Convoyeur;
  loading = true;
  errorMsg = '';

  constructor(
    private service: ConvoyeurService
  ) { }

  ngOnChanges(): void {
    this.getClasse(this.id);
  }

  ngOnInit(): void {

  }

  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
