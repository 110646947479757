<h1>Notes et moyennes </h1>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
<div *ngIf="classe">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/notes">Notes et moyennes</a>
    </li>

    <li class="nav-item">
      <a class="nav-link disabled">{{classe.classe_nom}} </a>
    </li>

  </ul>

  <div class="recherche">
    <div class="row">
      <div class="col-md-8">
        <h4> {{classe.classe_nom}} </h4>
      </div>
      <div class="col-md-4">
        <a routerLink="/{{user.role}}/notes">Aller la liste des classes</a>
      </div>
    </div>

  </div>

  <div class="alert alert-danger" *ngIf="errorMsg">{{errorMsg}} </div>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Discipline</th>
          <th scope="col">Enseignant</th>
          <th scope="col" *ngFor="let periode of periodes">
            {{periode.periode}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let matiere of matieres; let i=index">
          <td> {{i+1}} </td>
          <td> {{matiere.matiere_nom}} </td>
          <td> {{matiere.profNom}} {{matiere.profPrenoms}} </td>

          <td *ngFor="let periode of periodes">
            <a [routerLink]="'/'+user.role+'/notes/classe/'+classe.idclasse+'/'+matiere.idmatiere+'/'+periode.periode">Voir
              les details</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>


<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
