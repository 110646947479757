import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationsRoutingModule } from './evaluations-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EvaluationsViewComponent } from './evaluations-view/evaluations-view.component';
import { EvaluationsComponent } from './evaluations.component';
import { EvaluationsNoteDelaiComponent } from './evaluations-view/evaluations-note-delai/evaluations-note-delai.component';
import { EvaluationsNoterComponent } from './evaluations-noter/evaluations-noter.component';


@NgModule({
  declarations: [
    EvaluationsComponent,
    EvaluationsViewComponent,
    EvaluationsNoteDelaiComponent,
    EvaluationsNoterComponent
  ],
  imports: [
    CommonModule,
    EvaluationsRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    FontAwesomeModule
  ]
})
export class EvaluationsModule { }
