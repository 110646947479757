<h1>Produits et services</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Services</a>
    </li>
</ul>
<div class="recherche">
    <h5> {{collection}} produits et services </h5>
    <p> <a class="a" (click)="open(content)">Ajouter un nouveau service</a> </p>
</div>
<h3 *ngIf="loadError"> {{loadError}} </h3>
<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Service</th>
                <th scope="col">Abonnés</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of services; let i=index">
                <td>{{i+1}}</td>
                <td> {{s.nom}} </td>
                <td>
                    <app-abonnes [id]="s.code" routerLink="/{{user.role}}/services/abonnes/{{s.code}}"></app-abonnes>
                </td>
                <td> <a routerLink="/{{user.role}}/services/{{ecole}}/{{s.code}}">Voir detail</a> </td>
            </tr>
        </tbody>
    </table>
</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>



<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Nouveau service
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="service">Nom du service</label>
                <input type="text" formControlName="service" class="form-control">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
            </div>
        </div>
    </form>
</ng-template>