import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZonesComponent } from './zones.component';
import { ZonesViewComponent } from './zones-view/zones-view.component';

const routes: Routes = [
  { path: '', component: ZonesComponent },
  { path: ':id', component: ZonesViewComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ZonesRoutingModule { }
