<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Véhicules</a>
    </li>
</ul>

<div class="recherche">
    <div class="row">
        <div class="col-lg-7">
            <h2>Parc-auto <small>Véhicules</small></h2>
            <p> <a (click)="open(content)">Ajouter un nouveau véhicule</a> </p>
        </div>
        <div class="col-lg-5">
            <h6>Rechercher</h6>
            <div class="form-group">
                <input type="search" class="form-control" placeholder="Rechercher">
            </div>
        </div>
    </div>
</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize">
    <ng-template ngbPaginationFirst>Premier</ng-template>
    <ng-template ngbPaginationLast>Dernier</ng-template>
    <ng-template ngbPaginationPrevious>Précédent</ng-template>
    <ng-template ngbPaginationNext>Suivant</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>
<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Immatriculation</th>
                <th scope="col">Description</th>
                <th scope="col">Caburant</th>
                <th scope="col">Hors service</th>
                <th scope="col">Date</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let v of vehicules | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" [ngClass]="{'alert-danger': v.hors_service == 1}">
                <td> <a routerLink="{{v.idvehicule}}"> {{v.immatriculation | uppercase}}</a> </td>
                <td><span class="vehicule-color" [ngStyle]="{'background-color': v.couleur}"></span> {{v.modele}}, {{v.places}} places </td>
                <td> {{v.carburant}} </td>
                <td>{{v.hors_service == 1 ? 'OUI' : 'NON'}}</td>
                <td>{{v.acquisition_date | date }}</td>
            </tr>
        </tbody>
    </table>
</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize">
    <ng-template ngbPaginationFirst>Premier</ng-template>
    <ng-template ngbPaginationLast>Dernier</ng-template>
    <ng-template ngbPaginationPrevious>Précédent</ng-template>
    <ng-template ngbPaginationNext>Suivant</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>

<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Nouveau véhicule
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-row">
                <div class="form-group col-lg-8">
                    <label for="immatriculation">Imatriculation</label>
                    <input type="text" formControlName="immatriculation" class="form-control" id="immatriculation">
                </div>
                <div class="form-group col-lg-4">
                    <label for="annee">Année</label>
                    <input type="number" formControlName="annee" class="form-control" id="annee" maxlength="4" min="1990" max="2021">
                </div>
            </div>
            <div class="form-group">
                <label for="modele">Modèle</label>
                <input type="text" formControlName="modele" class="form-control" id="modele" placeholder="Marque, serie, ...">
            </div>
            <div class="form-row">
                <div class="form-group col-lg-4">
                    <label for="couleur">Couleur</label>
                    <input type="color" formControlName="couleur" class="form-control" id="couleur" required>
                </div>

                <div class="form-group col-lg-4">
                    <label for="places">Nombre de places</label>
                    <input type="number" formControlName="places" class="form-control" id="places" min="2" maxlength="2">
                </div>
                <div class="form-group col-lg-4">
                    <label for="portes">Nombre de portes</label>
                    <input type="number" formControlName="portes" class="form-control" id="portes" min="1" max="6">
                </div>

                <div class="form-group col-lg-4">
                    <label for="transmission">Transmission</label>
                    <select formControlName="transmission" class="form-control" id="transmission">
                      <option *ngFor="let t of transmission" [value]="t" > {{t}} </option>
                    </select>
                </div>
                <div class="form-group col-lg-4">
                    <label for="carburant">Caburant</label>
                    <select formControlName="carburant" class="form-control" id="carburant">
                      <option *ngFor="let c of carburant" [value]="c" > {{c}} </option>
                    </select>
                </div>
                <div class="form-group col-lg-4">
                    <label for="puissance">Puissance</label>
                    <input type="number" formControlName="puissance" class="form-control" id="puissance">
                </div>
            </div>
            <div class="form-group">
                <label for="acquisition">Date d'acquisition</label>
                <input type="date" formControlName="acquisition" class="form-control" id="acquisition">
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" formControlName="etat" [value]="1" id="etat">
                    <label class="form-check-label" for="etat">
                      Definir le véhicule hors service
                    </label>
                </div>
            </div>



            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



            </div>
        </div>
    </form>
</ng-template>