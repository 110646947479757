<h4>Activités</h4>

<div class="table-responsive table-responsive-sm" *ngIf="activites; else noActivite">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Heure</th>
                <th scope="col">Activité</th>
                <th scope="col">Présent</th>
                <th scope="col">Observation</th>
                <th scope="col">Date de validation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of activites">
                <td> {{a.activite_date | date}}</td>
                <td> {{a.activite_heure}}</td>
                <td> {{a.activite}} | {{a.action}} </td>
                <td [ngClass]="{'text-success': a.present == 1, 'text-danger': a.present == 0}"> {{a.present == 1 ? 'present' :'absent' }} <br> </td>
                <td> {{ a.remarque }} </td>
                <td> {{a.validation | date:'medium'}} </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #noActivite>
    <p class="errorLoading"> {{errorLoading}} </p>
</ng-template>
