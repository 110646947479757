<div class="table-responsive">
    <table class="table table-sm table-bordered">
        <thead>
            <tr>
                <th scope="col">Matricule</th>
                <th scope="col">Nom et prenoms </th>
                <th scope="col">R</th>
                <th scope="col" class="text-center">Notes </th>
                <th scope="col">Moy </th>
                <th scope="col" class="no-print">Action </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let eleve of eleves; let i=index">
                <td> {{eleve.matricule}} </td>
                <td> {{eleve.nom}} {{eleve.prenoms}} </td>
                <td> {{eleve.redoublant}} </td>
                <td>
                    <app-notes-eleve [eleve]="eleve" [matiere]="matiere"></app-notes-eleve>
                </td>
                <td> <strong>{{eleve.moyenne  | number:'1.0-2'}}</strong> </td>
                <td class="no-print"> <a routerLink="/{{user.role}}/notes/view/{{eleve.ideleve}}/{{matiere}}">Voir plus</a> </td>
            </tr>
        </tbody>
    </table>

</div>
