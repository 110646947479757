<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row ">
        <label for="activite" class="col-lg-2 sr-only">Titre</label>
        <div class="form-group col-md-1">
            <input type="text" formControlName="reference" class="form-control" placeholder="Ref">
        </div>
        <div class="form-group col-md-7">
            <input type="text" formControlName="activite" class="form-control" placeholder="Titre étudié">
        </div>

        <div class="form-group col-md-2">
            <div class="form-check">
                <input formControlName="titre" class="form-check-input" type="checkbox" [value]="1" id="titre">
                <label class="form-check-label" for="titre">Grand titre ?</label>
            </div>
        </div>
        <div class="form-group col-md-2">
            <button type="submit" class="button btn-sm" [disabled]="form.invalid || submitted === true">
                                  <span *ngIf="!submitted">Ajouter</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>
                </button>
        </div>
    </div>
</form>
<div *ngFor="let c of cahiers">
    <h6 *ngIf="c.grand_titre == 1"> {{c.reference}} - {{c.activite}} <a (click)="onDelete(c)" class="float-right text-danger font-weight-bold">X</a> </h6>
    <p *ngIf="c.grand_titre == 0">{{c.reference}} - {{c.activite}} <a (click)="onDelete(c)" class="float-right text-danger font-weight-bold">X</a></p>
</div>