<div *ngIf="echeance">
    <button [ngClass]="{'btn-danger': echeance.rapayer > 0, 'btn-success': echeance.rapayer <= 0}" class="btn btn-sm" (click)="open(content)"> {{echeance.rapayer > 0 ? 'Interdire':'Autoriser' }} </button>


    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title"> {{echeance.nom}} {{echeance.prenoms}} </h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
                <div class="alert alert-danger" *ngIf="echeance.rapayer > 0">L'abonné n'a pas droit au service pour impayé </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="moment">Moment</label>
                        <select class="form-control" formControlName="moment" id="moment">
                <option *ngFor="let m of moments" [value]="m" >{{m}}</option>
              </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="action">Type d'action</label>
                        <select class="form-control" formControlName="action" id="action">
                <option *ngFor="let a of actions" [value]="a" >{{a }}</option>
              </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-5"> <label>Est present ou absent ?</label></div>
                    <div class="col-md-7">
                        <div class="form-group">

                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="statut" id="present" [value]="1">
                                <label class="form-check-label" for="present">Présent(e)</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="statut" id="absent" [value]="0">
                                <label class="form-check-label" for="absent">Absent(e)</label>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-group">
                    <label for="chauffeur_mobile">Remarque</label>
                    <textarea formControlName="remarque" class="form-control" id="remarque" rows="3"></textarea>
                </div>

                <div class="form-group">
                    <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



                </div>
            </div>
        </form>
    </ng-template>
</div>