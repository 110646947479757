import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationLoginComponent } from './authentication-login/authentication-login.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationOublieComponent } from './authentication-oublie/authentication-oublie.component';


@NgModule({
  declarations: [
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthenticationOublieComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbAlertModule
  ],
  providers: [AuthenticationService]
})
export class AuthenticationModule { }
