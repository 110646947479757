import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotesComponent } from './notes.component';
import { NotesViewComponent } from './notes-view/notes-view.component';
import { NotesClasseComponent } from './notes-classe/notes-classe.component';
import { NotesNoterComponent } from './notes-noter/notes-noter.component';
import { NotesClasseGlobalComponent } from './notes-classe-global/notes-classe-global.component';
import { NotesMoyennesComponent } from './notes-moyennes/notes-moyennes.component';
import { NotesMatieresComponent } from './notes-matieres/notes-matieres.component';

const routes: Routes = [
  { path: '', component: NotesComponent },
  { path: 'matieres/:id', component: NotesMatieresComponent },
  { path: 'noter/:id/:classe', component: NotesNoterComponent },
  { path: 'view/:id/:matiere', component: NotesViewComponent },
  { path: 'classe/:id', component: NotesClasseGlobalComponent },
  { path: 'classe/:id/:matiere/:periode', component: NotesClasseComponent },
  { path: 'moyennes/:id/:matiere/:periode', component: NotesMoyennesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotesRoutingModule { }
