import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-logistiques-services-abonnes',
  templateUrl: './logistiques-services-abonnes.component.html',
  styleUrls: ['./logistiques-services-abonnes.component.css']
})
export class LogistiquesServicesAbonnesComponent implements OnChanges, OnDestroy {
  @Input() id: string;
  subscription = new Subscription();
  p: Logistiques;
  abonnes = 0;
  loading = true;
  errorLoading = '';

  constructor(
    private service: LogistiquesService
  ) { }

  ngOnChanges(): void {
    this.getServiceAbonnes(this.id);
  }

  getServiceAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('services_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
