import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Administration } from '../administration';
import { AdministrationService } from '../administration.service';

@Component({
  selector: 'app-administration-ecole',
  templateUrl: './administration-ecole.component.html',
  styleUrls: ['./administration-ecole.component.css']
})
export class AdministrationEcoleComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  id: string;
  ecole: Administration;
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: AdministrationService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getEcoles(this.id);
  }

  getEcoles(id: string): void {
    this.service.view('ecoles', id)
      .subscribe(arg => {
        this.ecole = arg;
        this.loading = false;
      });
  }


}
