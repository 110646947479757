<div class="hamburger">
    <div class="toggle-btn" (click)="onToggleMenu()">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<nav class="sidebar" [ngClass]="toggle ? 'sidebar-close' :  'sidebar-open'">

    <div class="logo">
        <img src="assets/images/logo-menu.png" alt="Vie-Ecoles">
    </div>
    <div class="user">
        <a class="" href="" class=""><span></span> {{user.name}} </a>
        <small> {{user.role}} </small>
    </div>
    <ul class="nav flex-column">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home" routerLinkActive="active"
                (click)="onToggleMenu()">Tableau de Bord</a>
        </li>

        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/ecoles" routerLinkActive="active"
                (click)="onToggleMenu()">Ecoles</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/progressions" routerLinkActive="active"
                (click)="onToggleMenu()">Progression nationale</a>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="logout()">Deconnexion</a>
        </li>
    </ul>

</nav>
<div id="main">
    <div class="container-fluid">
        <main role="main">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>