import { Component, Input, OnDestroy, OnChanges, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-evaluations-noter',
  templateUrl: './evaluations-noter.component.html',
  styleUrls: ['./evaluations-noter.component.css']
})
export class EvaluationsNoterComponent implements OnChanges, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() evaluation: Suivis;
  @Input() eleve: string;
  form: FormGroup;
  note: Suivis;
  e: Suivis;
  loading = true;
  submitted = false;
  errorMsg = '';
  errorType = '';
  jour: any;


  constructor(
    private service: SuivisService,
    @Inject(LOCALE_ID) private locale: string) {
    this.jour = formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
  }

  ngOnChanges(): void {
    this.getNote(this.eleve);
  }


  getNote(eleve: string): void {
    this.subscription.add(
      this.service.deuxArg('notes', this.evaluation.idevaluation, eleve)
        .subscribe(arg => {
          this.note = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  onNoter(data): void {
    if (!!data.note.target.value) {
      this.subscription.add(
        this.service.add('notes', {
          eleve: data.eleve,
          note: data.note.target.value,
          evaluation: data.evaluation
        })
          .subscribe(arg => {
            this.getNote(this.eleve);
            /* const index = data.input + 1;
             const next = index === this.eleves.length ? 0 : index;
             document.getElementById(`input${next}`).focus();*/
          },
            erg => {
              this.errorMsg = erg;
              this.errorType = 'error';
              this.submitted = false;
            }
          )
      );
    }

  }

  appreciation(note: number, notation: number): string {
    if (note > (notation * 0.7)) {
      return 'Excellent';
    }
    else if (note <= (notation * 0.7) && note >= (notation * 0.6)) { return 'Bon'; }
    else if (note <= (notation * 0.59) && note >= (notation * 0.5)) { return 'Passable'; }
    else if (note <= (notation * 0.5) && note >= (notation * 0.4)) { return 'Passable'; }
    else { return 'Faible'; }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
