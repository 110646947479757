<h1>Services scolaires</h1>
<div *ngIf="p">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services/{{p.service}}"> {{p.nom}} </a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{p.designation}}</a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-7">
                <h2>{{p.designation}} <small> {{p.nom}} </small> </h2>
            </div>
            <div class="col-md-3">
                <p>{{p.description}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h4>Echéanciers</h4>
            <div class="recherche">

                <div class="table-responsive" *ngIf="frais; else noFrais">
                    <table class="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Statut</th>
                                <th scope="col">Ancien</th>
                                <th scope="col">Nouveau</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of frais">
                                <td> {{ f.datelimite | date }} </td>
                                <td> {{f.statut}} </td>
                                <td> {{f.montant | currency:'XOF'}} </td>
                                <td>{{f.montant2 | currency:'XOF'}} </td>

                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th scope="col" colspan="2">Total</th>
                                <th scope="col"> {{p.prix | currency:'XOF'}} </th>
                                <th scope="col"> {{p.prix2 | currency:'XOF'}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <ng-template #noFrais>
                    <p class="errorLoading text-center"> {{errorLoading}} </p>
                </ng-template>
            </div>
        </div>
        <div class="col-md-6">
            <app-produits-itineraires [id]="p.iddetail" [prod]="p.service"></app-produits-itineraires>
        </div>

    </div>

    <h4>Souscripteurs</h4>
    <div class="table-responsive" *ngIf="abonnes; else noAbonnes">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Nom et prenoms</th>
                    <th scope="col">Classe</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Groupe</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of abonnes">
                    <td>
                        <a routerLink="/{{user.role}}/services/souscripteurs/{{a.uid}}/{{p.code}}" title="Voir le detail">
                       {{ a.nom }} {{a.prenoms}} </a>
                    </td>
                    <td> {{a.classe}} </td>
                    <td>{{a.statut}} </td>
                    <td>
                        <app-produits-abonne-groupe [eleve]="a.uid" [produit]="p.iddetail" [prod]="p.service"></app-produits-abonne-groupe>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #noAbonnes>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
