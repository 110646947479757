import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationModule } from './authentication/authentication.module';
import { DirecteurModule } from './directeur/directeur.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogistiquesModule } from './logistiques/logistiques.module';
import { ProfesseurModule } from './professeur/professeur.module';
import { AdministrationModule } from './administration/administration.module';
import { CachingInterceptor } from './interceptors/caching.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { CacheService } from './config/cache.service';
import { FormattingService } from './config/formatting.service';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConvoyeurModule } from './convoyeur/convoyeur.module';
import { ConvoyeurService } from './convoyeur/convoyeur.service';
import { ProfesseurService } from './professeur/professeur.service';
import { LogistiquesService } from './logistiques/logistiques.service';
import { SuivisService } from './suivis/suivis.service';
import { SuivisModule } from './suivis/suivis.module';
import { CameraComponent } from './camera/camera.component';
import {WebcamModule} from 'ngx-webcam';

@NgModule({
  declarations: [
    AppComponent,
    CameraComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthenticationModule,
    AdministrationModule,
    ProfesseurModule,
    DirecteurModule,
    LogistiquesModule,
    ConvoyeurModule,
    ProfesseurModule,
    SuivisModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NgbAlertModule,
    FontAwesomeModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    WebcamModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    AuthenticationService,
    ConvoyeurService,
    ProfesseurService,
    SuivisService,
    CacheService,
    FormattingService,
    ConvoyeurService,
    LogistiquesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
