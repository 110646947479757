import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  toggle: boolean;

  constructor(
    private service: AuthenticationService
  ) { }

  ngOnInit(): void { console.log(this.toggle); }


  public onToggleMenu(): void {
    this.toggle = !this.toggle;
  }

  logout(): void {
    this.service.logout();
  }

}
