import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-souscripteurs-medical',
  templateUrl: './souscripteurs-medical.component.html',
  styleUrls: ['./souscripteurs-medical.component.css']
})
export class SouscripteursMedicalComponent implements OnInit, OnDestroy {
  @Input() eleve: string;
  subscription = new Subscription();
  form: FormGroup;
  fiches: Logistiques[];
  collection = 0;
  errorLoading = '';
  errorMsg = '';
  loading = true;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.getFiche(this.eleve);
    this.createForm();
  }
  getFiche(id: string): void {
    this.subscription.add(
      this.service.views('fiche_medical', id)
        .subscribe(arg => {
          this.fiches = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      eleve: [this.eleve, Validators.required],
      anormalie: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('fiche_medical', this.form.value)
        .subscribe(
          arg => {
            this.getFiche(this.eleve);
            this.form.get('anormalie').reset();
            this.form.get('description').reset();
            this.modal.dismissAll();
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
