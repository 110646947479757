<h1>Cahiers de texte</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Cahiers de texte</a>
    </li>
</ul>
<div class="recherche">
    <div class="row">
        <div class="col-md-4">
            <h4>Cahiers de texte</h4>
            <p *ngIf="collection"> {{collection | number}} cahiers de textes </p>
            <p *ngIf="user.role !== 'professeur'"> <a (click)="getCahiers()" class="a">Afficher toutes les cahiers de texte</a> </p>

        </div>
        <div class="col-md-8" *ngIf="user.role !== 'professeur'">
            <h5>Recherche</h5>
            <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="classe">Classe</label>
                        <select name="classe" class="form-control" id="classe" ngModel>
                          <option *ngFor="let c of classes" [value]="c.idclasse">{{c.classe_nom}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="matiere">Matière</label>
                        <select name="matiere" class="form-control" id="matiere" ngModel>
                          <option *ngFor="let m of matieres" [value]="m.idmatiere">{{m.matiere_nom}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="professeur">Enseignant</label>
                        <select name="prof" class="form-control" id="prof" ngModel>
                          <option *ngFor="let p of profs" [value]="p.idprof">{{p.nom}} {{p.prenoms}} </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" [disabled]="submitted || !search.value">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
                </div>

            </form>
        </div>
    </div>
</div>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}}</div>
<div class="row">
    <div class="col-lg-3 col-md-3 col-sm-6" *ngFor="let c of cahiers">
        <div class="cahier">
            <h5> {{c.classe_nom}} </h5>
            <p> {{c.matiere_nom}}</p>
            <p> <small> {{c.profNom}} {{c.profPrenoms}} </small> </p>
            <p>
                <button class="button btn-sm" routerLink="/{{user.role}}/cahiers-textes/view/{{c.id}}">Ouvrir</button>
            </p>
            <app-cahiers-textes-etat class="text-center" [cahier]="c"></app-cahiers-textes-etat>
        </div>

    </div>
</div>
<div *ngIf="user.role === 'professeur'  && collection > 0">
    <h4>Séances non renseignées</h4>
    <div class="table-responsive">
        <table class="table table-sm table-striped">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Heure</th>
                    <th scope="col">Classe</th>
                    <th scope="col">Matière</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let s of seances | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <td> {{s.debut | date}} </td>
                    <td> {{s.debut | date:'shortTime'}} - {{s.fin | date:'shortTime'}} </td>
                    <td> {{s.classe_nom}} </td>
                    <td> {{s.matiere_nom}} </td>
                    <td>
                        <div *ngIf="seance; else noPremier">

                            <button *ngIf="s.idseance == seance.idseance" class="button btn-sm" routerLink="/{{user.role}}/cahiers-textes/add/{{s.idseance}}">Renseigner </button>
                        </div>
                        <ng-template #noPremier>
                            <button class="button btn-sm" routerLink="/{{user.role}}/cahiers-textes/add/{{s.idseance}}">Renseigner </button>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [boundaryLinks]="true " [maxSize]="maxSize" *ngIf="collection > pageSize">
        <ng-template ngbPaginationFirst>Premier</ng-template>
        <ng-template ngbPaginationLast>Dernier</ng-template>
        <ng-template ngbPaginationPrevious>Précédent</ng-template>
        <ng-template ngbPaginationNext>Suivant</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>