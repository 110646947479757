import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogistiquesComponent } from './logistiques.component';
import { LogistiquesHomeComponent } from './logistiques-home/logistiques-home.component';
import { ParcAutoVehiculesComponent } from './parc-auto/parc-auto-vehicules/parc-auto-vehicules.component';
import { ParcAutoVehiculeComponent } from './parc-auto/parc-auto-vehicule/parc-auto-vehicule.component';

const routes: Routes = [
  {
    path: '',
    component: LogistiquesComponent,
    children: [
      { path: 'home', component: LogistiquesHomeComponent },
      { path: 'activites', loadChildren: () => import('./activites/activites.module').then(m => m.ActivitesModule) },
      { path: 'encadreurs', loadChildren: () => import('./encadreurs/encadreurs.module').then(m => m.EncadreursModule) },
      { path: 'produits', loadChildren: () => import('./produits/produits.module').then(m => m.ProduitsModule) },
      { path: 'zones', loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule) },
      { path: 'souscripteurs', loadChildren: () => import('./souscripteurs/souscripteurs.module').then(m => m.SouscripteursModule) },
      { path: 'vehicules', loadChildren: () => import('./vehicules/vehicules.module').then(m => m.VehiculesModule) }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogistiquesRoutingModule { }
