<h1>Evaluations </h1>
<div *ngIf="e">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/evaluations">Evaluations</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled">Evaluation N° {{e.id}}</a>
    </li>
  </ul>
  <div class="recherche">
    <h4>Evaluation N° {{e.id}} du {{e.date | date }} </h4>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4"> Classe: <strong>{{e.classe_nom}}</strong> </div>
          <div class="col-md-4"> Matière: <strong>{{e.idmatiere}} </strong> </div>
          <div class="col-md-4"> Discipline: <strong>{{e.matiere_nom}} </strong> </div>
          <div class="col-md-4">Periode: <strong>{{e.periode}}</strong> </div>
          <div class="col-md-4">Type: <strong>{{e.type_evaluation}}</strong> </div>
          <div class="col-md-4"> Date: <strong> {{e.date | date }}</strong> </div>
          <div class="col-md-4"> Heure: <strong> {{e.heure }}</strong> </div>
        </div>
        <div class="bilan" *ngIf="bilan">
          <p class="alert"
            [ngClass]="{'alert-danger': bilan.moy < 10,'alert-warning': bilan.moy < 12, 'alert-secondary': bilan.moy < 14, 'alert-success': bilan.moy >= 14}">
            Niveau de classe <strong>{{appreciation(bilan.moy, 20) }}</strong> avec une moyenne de
            <strong>{{bilan.moy | number }}</strong>
          </p>
          <ul>
            <li>Note plus fort: <strong>{{ bilan.max | number}}</strong> </li>
            <li>Note plus faible: <strong>{{bilan.min | number}}</strong> </li>
            <li>Coefficient: <strong> {{e.notation/20}} </strong> </li>
          </ul>
        </div>
        <p>
          <button class="button" (click)="onTerminerNotation(e)">
            Terminer la saisie des notes
          </button>
          <button class="button" routerLink="/{{user.role}}/notes/moyennes/{{e.idclasse}}/{{e.idmatiere}}/{{e.periode}}"
            *ngIf="(user.role === 'professeur' || user.role === 'directeur')">
            Gestion de moyennes
          </button>
          <button class="button" (click)="onEvaluationExecutee(e)" *ngIf="e.executee == 0">
            Définir comme éffectuée
          </button>
        </p>
      </div>
      <div class="col-md-4">
        <app-evaluations-note-delai [evaluation]="e"></app-evaluations-note-delai>

        <div class="row" *ngIf="user.role === 'directeur'">
          <div class="col-6">
            <button class="button btn-block" (click)="open(content)">Modifier</button>
          </div>
          <div class="col-6">
            <button class="btn btn-block btn-danger" (click)=" onDelEvaluation()">Supprimer</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert-alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>

  <div class="table-responsive">
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom et prenoms</th>
          <th scope="col">Sexe</th>
          <th scope="col">R</th>
          <th scope="col">Note</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let eleve of eleves; let i=index">
          <td> {{i+1}} </td>
          <td> {{eleve.nom}} {{eleve.prenoms}} </td>
          <td> {{eleve.sexe }} </td>
          <td> {{eleve.redoublant }} </td>
          <td>
            <app-evaluations-noter [eleve]="eleve.ideleve" [evaluation]="e"></app-evaluations-noter>
          </td>
          <td>
            <a routerLink="/{{user.role}}/notes/view/{{eleve.ideleve}}/{{e.idmatiere}}">Voir detail</a>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">
          Modification d'évaluation {{e.id}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
        <div class="form-group">
          <label for="type">Type d'évaluation</label>
          <select formControlName="type" id="type" class="form-control" [(ngModel)]="e.type">
            <option *ngFor="let t of types"> {{t.type_evaluation}} </option>
          </select>
        </div>

        <div class="form-row">
          <div class="form-group col-md-8">
            <label for="periode">Période</label>
            <select formControlName="periode" id="periode" class="form-control" [(ngModel)]="e.periode">
              <option *ngFor="let p of periodes" [value]="p"> {{p}} </option>
            </select>
          </div>


          <div class="form-group col-md-4">
            <label for="note">Note maximale</label>
            <input type="number" formControlName="note" id="note" class="form-control" [min]="'5'"
              [(ngModel)]="e.notation">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="jour">Date</label>
            <input type="date" formControlName="jour" id="jour" class="form-control" [(ngModel)]="e.date">
          </div>
          <div class="form-group col-md-4">
            <label for="heure">Heure</label>
            <input type="time" formControlName="heure" id="heure" class="form-control" [(ngModel)]="e.heure">
          </div>
          <div class="form-group col-md-4">
            <label for="duree">Durée</label>
            <input type="time" formControlName="duree" id="duree" class="form-control" [(ngModel)]="e.duree">
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" formControlName="calculer" type="checkbox" [value]="1" id="calculer">
            <label class="form-check-label" for="calculer">
              Appliquer aussi le changement sur les notes
            </label>
          </div>
        </div>
        <div class="form-group">
          <button type="submit" [disabled]="form.invalid || submitted === true">
            <span *ngIf="!submitted">Enrégistrer</span>
            <span *ngIf="submitted">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
            </span>
          </button>
        </div>
      </div>
    </form>
  </ng-template>

</div>


<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
