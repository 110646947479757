import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';


@Component({
  selector: 'app-seances-jour',
  templateUrl: './seances-jour.component.html',
  styleUrls: ['./seances-jour.component.css']
})
export class SeancesJourComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  seances: Suivis[] = [];
  id = '';
  errorMsg = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getSeances(this.id);

  }

  getSeances(id: string): void {
    this.subscription.add(
      this.service.views('seances_jour', id)
        .subscribe((arg: Suivis[]) => {
          this.seances = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }





  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
