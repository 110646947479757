import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { faTimes, faCheck, faCalendarTimes, faCalendarCheck, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cahiers-textes-contenu',
  templateUrl: './cahiers-textes-contenu.component.html',
  styleUrls: ['./cahiers-textes-contenu.component.css']
})
export class CahiersTextesContenuComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  @Input() seance: Suivis;
  cahiers: Suivis[] = [];
  presents = 0;
  loading = true;
  errorMsg = '';
  faError = faTimes;
  faSuccess = faCheck;
  today = Date.now();
  submitted = false;

  faRetard = faCalendarTimes;
  faDansTemps = faCalendarCheck;
  faVerrou = faLock;
  faDeverrou = faLockOpen;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.getCahier(this.seance.idseance);
    // this.getPresent(this.seance.idseance);
    this.createForm();
  }

  getCahier(id: string): void {
    this.subscription.add(
      this.service.views('seances_cahier', id)
        .subscribe((arg: Suivis[]) => {
          this.cahiers = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  /*getPresent(id: string): void {
    this.subscription.add(
      this.service.list(`seances_eleves?seance=${id}&presence=1`)
        .subscribe((arg: Suivis[]) => {
          this.presents = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }*/

  open(c): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      cahier: ['', Validators.required],
      observation: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('cahier_observation', this.form.get('cahier').value, this.form.value)
        .subscribe(
          arg => {
            this.getCahier(this.seance.idseance);
            this.submitted = false;
            this.modal.dismissAll();

          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
