import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-logistiques-services',
  templateUrl: './logistiques-services.component.html',
  styleUrls: ['./logistiques-services.component.css']
})
export class LogistiquesServicesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  id: string;
  s: Logistiques;
  services: Logistiques[];
  abonnes = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getServices(this.id);
    this.getAbonnes(this.id);
  }

  getServices(id: string): void {
    this.subscription.add(
      this.service.list('services')
        .subscribe(arg => {
          this.services = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('services_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
