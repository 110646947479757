import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notes-matieres',
  templateUrl: './notes-matieres.component.html',
  styleUrls: ['./notes-matieres.component.css']
})
export class NotesMatieresComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string = '';
  classe: Suivis;
  matieres: Suivis[] = [];
  periodes: Suivis[] = [];
  loading = true;
  errorMsg = '';

  constructor(
    private service: SuivisService,
    private ar: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getClasse(this.id);
    this.getMatieres(this.id);
    this.getPeriodes(this.id);
  }


  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe((arg: Suivis) => {
          this.classe = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMatieres(id: string): void {
    this.subscription.add(
      this.service.list('classes_details?classe=' + id)
        .subscribe((arg: Suivis[]) => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getPeriodes(id): void {
    this.subscription.add(
      this.service.views('evaluations_periodes', id)
        .subscribe((arg: Suivis[]) => {
          this.periodes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
