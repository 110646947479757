<h1>Tableau de bord</h1>
<br>

<div class="recherche">
    <h4 class="text-center">Services scolaires</h4>
    <div class="row text-center">
        <div class="col-md-4">
            <h5> {{produits | number}} produits scolaires</h5>
            <p> <a routerLink="/{{user.role}}/produits">Voir details</a> </p>
        </div>
        <div class="col-md-4">
            <h5> {{abonnes | number}} souscripteurs</h5>
            <p> <a routerLink="/{{user.role}}/souscripteurs">Voir details</a> </p>
        </div>
        <div class="col-md-4">
            <h5> {{encadreurs | number}} Encadreurs</h5>
            <p> <a routerLink="/{{user.role}}/encadreurs">Voir details</a> </p>
        </div>
    </div>

    <ng-template #noServices>
        <p class="text-center">{{errorLoading}}</p>
    </ng-template>
</div>


<div class="recherche">
    <h4 class="text-center">Parc-auto</h4>

    <div class="row">
        <div class="col-md-4">
            <h5 class="text-center"> {{zones | number}} souscripteurs</h5>
            <p class="text-center"> <a routerLink="/{{user.role}}/zones">Voir details</a> </p>
        </div>
        <div class="col-lg-4">
            <h5 class="text-center"> {{vehicules}} Véhicules ({{horsservice}} hors service) </h5>
            <p class="text-center"> <a routerLink="/{{user.role}}/parc-auto/vehicules">Voir details</a> </p>
        </div>
    </div>
</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
