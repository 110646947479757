import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivitesComponent } from './activites.component';
import { ActivitesProduitsComponent } from './activites-produits/activites-produits.component';
import { ActivitesViewComponent } from './activites-view/activites-view.component';

const routes: Routes = [
  { path: '', component: ActivitesComponent },
  { path: 'view/:id', component: ActivitesViewComponent },
  { path: 'produits/:id', component: ActivitesProduitsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivitesRoutingModule { }
