import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-services-zones',
  templateUrl: './services-zones.component.html',
  styleUrls: ['./services-zones.component.css']
})
export class ServicesZonesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  zones: Logistiques[];
  collection = 0;
  errorLoading = '';
  loading = true;
  submitted = false;
  errorMsg = '';

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getZones();
    this.createForm();
  }

  getZones(): void {
    this.subscription.add(
      this.service.list('zones')
        .subscribe(arg => {
          this.zones = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  open(c: any): void {
    this.modal.open(c);
  }
  createForm(): void {
    this.form = this.fb.group({
      zone: ['', Validators.required],
      code: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('zones', this.form.value)
        .subscribe(arg => {
          this.getZones();
          this.modal.dismissAll();
          this.submitted = false;
        },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          },
          () => {
            this.submitted = false;
          })
    );

  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
