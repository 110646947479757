import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DirecteurRoutingModule } from './directeur-routing.module';

import { DirecteurComponent } from './directeur.component';
import { DirecteurHomeComponent } from './directeur-home/directeur-home.component';


@NgModule({
  declarations: [
    DirecteurComponent,
    DirecteurHomeComponent
  ],
  imports: [
    CommonModule,
    DirecteurRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ]
})
export class DirecteurModule { }
