import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EncadreursComponent } from './encadreurs.component';
import { EncadreursViewComponent } from './encadreurs-view/encadreurs-view.component';

const routes: Routes = [
  { path: '', component: EncadreursComponent },
  { path: ':id', component: EncadreursViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EncadreursRoutingModule { }
