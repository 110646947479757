import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../Suivis';
import { SuivisService } from '../Suivis.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sanctions',
  templateUrl: './sanctions.component.html',
  styleUrls: ['./sanctions.component.css']
})
export class SanctionsComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  sanctions: Suivis[] = [];
  collection = 0;
  loading = true;
  today: Date = new Date();
  errorMsg = '';
  submitted = false;
  classes: Suivis[] = [];

  constructor(
    private modal: NgbModal,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.getSanctions();
    this.getClasses();
  }

  getSanctions(): void {
    this.service.list('sanctions?groupe=eleve')
      .subscribe((arg: Suivis[]) => {
        this.sanctions = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    const nom = data.nom ? `&nom=${data.nom}` : '';
    const classe = data.classe ? `&classe=${data.classe}` : '';
    const periode = `&debut=${data.debut}&fin=${data.fin}`;
    this.subscription.add(
      this.service.list(`sanctions?groupe=eleve${nom}${classe}${periode}`)
        .subscribe((arg: Suivis[]) => {
          this.sanctions = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  open(content: any): void {
    this.modal.open(content, { size: 'md' });
  }

  onClickSearch(data: Suivis): void {
    this.submitted = true;
    const nom = data.nom ? `nom=${data.nom}` : '';
    const classe = data.classe ? `classe=${data.classe}` : '';
    this.subscription.add(
      this.service.list(`eleves?${nom}&${classe}`)
        .subscribe((arg: Suivis[]) => {
          this.sanctions = arg;
          this.collection = arg.length;
          this.submitted = false;
          this.modal.dismissAll();
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
