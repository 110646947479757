<div class="card" *ngIf="groupe">
    <div class="card-header">{{groupe.designation}} </div>
    <div class="card-body">
        <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <label for="">Activité</label>
            <div class="form-group">
                <div class="custom-control form-check form-check-inline" *ngFor="let ac of activites; let i=index">
                    <input class="form-check-input" type="radio" formControlName="activite" [value]="ac" required>
                    <label class="form-check-label"> {{ac}} </label>
                </div>
            </div>
            <label for="">Periode</label>
            <div class="form-group">
                <div class="form-check form-check-inline" *ngFor="let a of actions; let i=index">
                    <input class="form-check-input" type="radio" formControlName="action" [value]="a">
                    <label class="form-check-label"> {{a}} </label>
                </div>
            </div>
            <div class="form-group">
                <button type="submit">Commencer</button>
            </div>
        </form>
    </div>
</div>
