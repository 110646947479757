import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Suivis } from '../suivis';
import { SuivisService } from '../suivis.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-seances',
  templateUrl: './seances.component.html',
  styleUrls: ['./seances.component.css']
})
export class SeancesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  seances: Suivis[] = [];
  classes: Suivis[] = [];
  matieres: Suivis[] = [];
  form: FormGroup;
  faError = faTimes;
  faSuccess = faCheck;

  bilan: Suivis;
  loading = true;
  collection = 0;
  page = 1;
  pageSize = 20;
  errorMsg = '';
  today: Date = new Date();
  submitted = false;

  constructor(
    private service: SuivisService,
    private fb: FormBuilder
  ) { }


  ngOnInit(): void {
    this.getSeances();
    this.getBilan();
    this.getClasses();
    this.getMatieres();
  }

  getSeances(): void {
    this.subscription.add(this.service.list('seances?limite=20')
      .subscribe((arg: Suivis[]) => {
        this.seances = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.loading = false;
        }));
  }
  getBilan(): void {
    this.subscription.add(
      this.service.view('seances_bilan')
        .subscribe((arg: Suivis) => {
          this.bilan = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMatieres(): void {
    this.subscription.add(
      this.service.list('matieres')
        .subscribe((arg: Suivis[]) => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      classe: [''],
      matiere: [''],
      presence: [''],
      cahier: [''],
      pointage: [''],
      debut: [''],
      fin: ['']
    });
  }

  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    const classe = data.classe ? 'classe=' + data.classe + '&' : '';
    const matiere = data.matiere ? 'matiere=' + data.matiere + '&' : '';
    const cahier = data.cahier ? 'cahier=' + data.cahier + '&' : '';
    const pointage = data.pointage ? 'pointage=' + data.pointage + '&' : '';
    const presence = data.presence ? 'presence=' + data.presence + '&' : '';
    const debut = data.debut ? 'debut=' + data.debut + '&' : '';
    const fin = data.fin ? 'fin=' + data.fin + '&' : '';
    const ordre = data.ordre ? `ordre=${data.ordre}&` : '';
    const limite = data.limite ? `limite=${data.limite}&` : '';

    this.subscription.add(this.service.list('seances?' + classe + matiere + cahier + pointage + presence + ordre + limite + debut + fin)
      .subscribe((arg: Suivis[]) => {
        this.seances = arg;
        this.collection = arg.length;
        this.submitted = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.submitted = false;
        }
      )
    );

    this.subscription.add(this.service.one('seances_bilan?' + classe + matiere + cahier + pointage + presence + debut + fin)
      .subscribe((arg: Suivis) => {
        this.bilan = arg;
        this.submitted = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.submitted = false;
        }
      )
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
