import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';

@Component({
  selector: 'app-groupes-eleve',
  templateUrl: './groupes-eleve.component.html',
  styleUrls: ['./groupes-eleve.component.css']
})
export class GroupesEleveComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() eleve: Convoyeur;
  @Input() rubrique: string;
  echeance: Convoyeur;
  form: FormGroup;
  gps;
  loading = true;
  submitted = false;
  errorMsg = '';
  today: Date = new Date();
  moments = [];
  actions = [];

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.gps = position.coords;
    }, erg => {
      this.errorMsg = 'Vous devez activer la localisation';
    });
    this.getEcheance(this.eleve.ideleve, this.rubrique);
    this.createForm();
  }

  onAssiduite(a): void {
    this.submitted = true;
    this.eleve.present = a.target.value;
    this.subscription.add(
      this.service.update('activites_abonnes', this.eleve.idactivite, this.eleve)
        .subscribe(
          arg => {
            this.submitted = false;
            this.getEcheance(this.eleve.ideleve, this.rubrique);
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }

  getService(id: string): void {
    this.subscription.add(
      this.service.view('groupes', id)
        .subscribe((arg: Convoyeur) => {
          if (arg.service === 'TRANS') {
            this.moments = ['MATIN', 'SOIR'];
            this.actions = ['MONTEE', 'DESCENTE'];
          } else if (arg.service === 'CANT') {
            this.moments = ['ENTREE CANTINE', 'PRISE DE REPAS', 'SIESTE'];
            this.actions = ['SORTIE', 'ENTREE'];
          } else {
            this.moments = ['ENTRAINERMENT', 'COMPETITION'];
            this.actions = ['SORTIE', 'ENTREE'];
          }
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg.error.message;
            this.loading = false;
          })
    );
  }

  getEcheance(id: string, rubrique: string): void {
    this.subscription.add(
      this.service.deuxArg('echeanciers', id, rubrique)
        .subscribe(arg => {
          this.echeance = arg;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg.error.message;
            this.loading = false;
          }
        )
    );
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      eleve: [this.eleve.ideleve, Validators.required],
      activite: [this.eleve.idactivite, Validators.required],
      remarque: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('activites_remarque', this.eleve.idactivite, this.form.value)
        .subscribe(
          arg => {
            this.modal.dismissAll();
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
