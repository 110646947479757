<div *ngIf="v; else noV">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/transports/vehicules">Véhicules</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled"> {{v.modele}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-6">
                <h4>{{v.immatriculation}}
                    <small>
                     <span class="vehicule-color" [ngStyle]="{'background-color': v.couleur}"></span>  {{v.modele}}, {{v.places}} places  </small> </h4>
                <a [ngClass]="{'text-danger': v.hors_service ==1,'text-success': v.hors_service == 0}" routerLink="/{{user.role}}/transports/vehicules/horsservice/{{v.idvehicule}}">
                   {{v.hors_service == 1 ? 'HORS SERVICE':'EN SERVICE' }} </a>
            </div>

        </div>
    </div>

    <div class="recherche">
        <div class="row">
            <div class="col-md-3">
                <app-parc-auto-assurance [id]="v.idvehicule"></app-parc-auto-assurance>
            </div>
            <div class="col-md-3">
                <app-parc-auto-visite [id]="v.idvehicule"></app-parc-auto-visite>
            </div>
            <div class="col-md-3">
                <app-parc-auto-patente [id]="v.idvehicule"></app-parc-auto-patente>
            </div>
            <div class="col-md-3">
                <app-parc-auto-stationnement [id]="v.idvehicule"></app-parc-auto-stationnement>
            </div>
        </div>
    </div>

    <div class="recherche">
        <div class="row">
            <div class="col-lg-5">
                <div class="form-group">
                    <label for="chargeType">Type de charge</label>
                    <select class="form-control" id="chargeType" (change)="onTypeCharge($event)">
                      <option *ngFor="let t of typeCharge" [value]="t.idtype"> {{t.charge_type}} </option>
                    </select>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="chargeType">Debut</label>
                        <input type="date" name="debut" class="form-control" id="debut">
                    </div>

                    <div class="form-group col-6">
                        <label for="chargeType">Fin</label>
                        <input type="date" name="fin" class="form-control" id="debut">
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit">Rechercher</button>
                </div>

            </div>
            <div class="col-lg-4">
                <h4 *ngIf="total">
                    <small>COÛT TOTAL DES CHARGES</small>
                    <br> {{total.total | currency:'XOF'}}
                </h4>
                <p><a class="a" (click)="open(content)">Déclarer une nouvelle charge</a></p>
            </div>

            <div class="col-lg-3" *ngIf="parcours">
                <h4> <small>KM PARCOURUS</small>
                    <br> {{parcours.autre | number}} KM
                </h4>
            </div>

        </div>
    </div>
    <div class="table-responsive" *ngIf="charges; else noCharges">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Charge</th>
                    <th scope="col">Type de charge </th>
                    <th scope="col">Coût</th>
                    <th scope="col">Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of charges | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <td>{{c.idcharge}}</td>
                    <td> {{c.charge}} </td>
                    <td> {{c.charge_type}} </td>
                    <td> {{c.cout | currency:'XOF'}} </td>
                    <td> {{c.chargeDate | date:'medium' }} </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noCharges>
        <p class="errorLoading"> {{errorLoading}} </p>
    </ng-template>

    <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize=5 *ngIf="collection > 10">
        <ng-template ngbPaginationFirst>Premier</ng-template>
        <ng-template ngbPaginationLast>Dernier</ng-template>
        <ng-template ngbPaginationPrevious>Précédent</ng-template>
        <ng-template ngbPaginationNext>Suivant</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>

    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title"> Nouvelle charge </h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

                <div class="form-group">
                    <label for="chargeType">Type de charge</label>
                    <select formControlName="chargeType" class="form-control" id="chargeType">
                      <option *ngFor="let t of typeCharge" [value]="t.idtype"> {{t.charge_type}} </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="charge">Charge</label>
                    <input type="text" formControlName="charge" class="form-control" id="charge">
                </div>
                <div class="form-row">
                    <div class="form-group col-md-5">
                        <label for="cout">Coût de la charge</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <input type="number" formControlName="cout" class="form-control" id="cout">
                            <div class="input-group-prepend">
                                <div class="input-group-text">CFA</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-7">
                        <label for="fournisseur">Fournisseur</label>
                        <input type="text" formControlName="fournisseur" class="form-control">
                    </div>
                </div>
                <div class="form-group" *ngIf="form.get('chargeType').value ==='CARBU' ">
                    <label for="cout">Dernier relevé</label>
                    <div class="input-group mb-2 mr-sm-2">
                        <input type="number" formControlName="autre" class="form-control" id="autre">
                        <div class="input-group-prepend">
                            <div class="input-group-text">KM</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
                </div>
            </div>
        </form>

    </ng-template>
</div>
<ng-template #noV>
    <p class="errorLoading"> {{errorLoading}} </p>
</ng-template>

<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
