import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-historiques-view',
  templateUrl: './historiques-view.component.html',
  styleUrls: ['./historiques-view.component.css']
})
export class HistoriquesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  activite: Convoyeur;
  abonnes: Convoyeur[];
  collection = 0;
  submitted = false;
  loading = true;
  errorMsg = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getActivite(this.id);
    this.getAbonnes(this.id);
  }

  getActivite(id: string): void {
    this.service.view('activites', id)
      .subscribe(arg => {
        this.activite = arg;
        this.loading = false;
      },
        erg => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  getAbonnes(id: string): void {
    this.service.views('activites_abonnes', id)
      .subscribe(arg => {
        this.abonnes = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        erg => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  back(): void {
    history.back();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
