<h1>Services scolaires</h1>
<div *ngIf="p">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits/{{p.service}}"> {{p.nom}} </a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{p.designation}}</a>
        </li>
    </ul>
    <div class="recherche">

        <div class="row">
            <div class="col-md-7">
                <h2> <small> {{p.nom}} </small> {{p.designation}}</h2>
                <app-produits-encadreur [id]="p.iddetail"></app-produits-encadreur>
            </div>
            <div class="col-md-5">
                <h4 class="text-center">Echéanciers</h4>
                <div class="table-responsive" *ngIf="frais; else noFrais">
                    <table class="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Date limite</th>
                                <th scope="col">Nouveau</th>
                                <th scope="col">Ancien</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of frais">
                                <td> {{ f.datelimite | date }} </td>
                                <td> {{f.montant | currency:'XOF'}} </td>
                                <td>{{f.montant2 | currency:'XOF'}} </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th scope="col">Total</th>
                                <th scope="col"> {{p.prix | currency:'XOF'}} </th>
                                <th scope="col"> {{p.prix2 | currency:'XOF'}} </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="recherche">
        <label for="">Rechercher</label>
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="classe" class="sr-only">Statut</label>
                <input type="text" class="form-control" (keyup)="onRechercheNom($event)" placeholder="Nom ou prenoms">
            </div>
            <div class="form-group col-md-3">
                <label for="classe" class="sr-only">Classe</label>
                <select class="form-control" (change)="onRechercheClasse($event)">
                             <option *ngFor="let c of classes" [value]="c.uid">{{c.nom}}</option>
                           </select>
            </div>
            <div class="form-group col-md-2">
                <label for="classe" class="sr-only">Sexe</label>
                <select class="form-control" (change)="onRechercheSexe($event)">
                             <option *ngFor="let s of sexes" [value]="s">{{s}}</option>
                           </select>
            </div>
            <div class="form-group col-md-2">
                <label for="classe" class="sr-only">Statut</label>
                <select formControlName="statut" class="form-control">
                             <option *ngFor="let s of statuts" [value]="s">{{s}}</option>
                           </select>
            </div>
            <div class="form-group col-md-2">
                <button type="submit" class="btn btn-sm">Rechercher</button>
            </div>
        </div>

        <p>{{collection}} enregistrements </p>
    </div>
    <div class="table-responsive" *ngIf="abonnes; else noAbonnes">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Souscripteur</th>
                    <th scope="col">Sexe</th>
                    <th scope="col">Classe</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Coût</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of abonnes">
                    <td> {{ a.nom }} {{a.prenoms}} </td>
                    <td> {{a.sexe}} </td>
                    <td>
                        <app-produits-classe [id]="a.classe"></app-produits-classe>
                    </td>
                    <td>{{a.statut}} </td>
                    <td>{{a.nouveau === 'OUI' ? (a.prix2 | currency:'XOF') : (a.prix | currency:'XOF') }} </td>
                    <td>
                        <a routerLink="/{{user.role}}/souscripteurs/view/{{a.idabonne}}">Voir detail</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #noAbonnes>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
