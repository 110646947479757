<h1>Absences</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Sanctions</a>
    </li>
</ul>
<div class="recherche">
    <div class="row">
        <div class="col-md-5">
            <h4>
                Sanctions
                <small> {{collection}} élèves trouvés</small>
            </h4>
            <p>
                <button class="button" (click)="open(content)">Rechercher l'élève à sanctionner</button>
            </p>

        </div>
        <div class="col-md-6 offset-md-1">
            <h5>Rechercher</h5>
            <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="classe">Nom de famille</label>
                        <input type="text" name="nom" class="form-control" ngModel>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="classe">Classe</label>
                        <select name="classe" class="form-control" ngModel>
                  <option *ngFor="let classe of classes" [value]="classe.idclasse">{{classe.classe_nom}}</option>
                </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="classe">Du</label>
                        <input type="date" name="debut" class="form-control" ngModel>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="classe">Au</label>
                        <input type="date" name="fin" class="form-control" ngModel>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" [disabled]="submitted || search.value == null">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
                </div>

            </form>
        </div>
    </div>

</div>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
<div class="table-responsive" *ngIf="collection; else noSanction">
    <table class="table table-striped table-sm">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Nom et prenoms</th>
                <th scope="col">Classe</th>
                <th scope="col">Statut</th>
                <th scope="col">S</th>
                <th scope="col">R</th>
                <th scope="col">Sanction</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sanction of sanctions; let i=index">
                <td> {{i+1}} </td>
                <td> {{sanction.nom}} {{sanction.prenoms}} </td>
                <td>
                    <app-sanctions-classe [id]="sanction.idclasse"></app-sanctions-classe>
                </td>
                <td> {{sanction.statut}} </td>
                <td> {{sanction.sexe}} </td>
                <td> {{sanction.redoublant}} </td>
                <td> {{sanction.sanctions | number:'2.0-2'}} </td>
                <td><a routerLink="view/{{sanction.ideleve}}">Voir detail</a></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #noSanction>
    <h3 class="text-center errorLoading" *ngIf="!loading">Aucune sanction trouvée</h3>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>


<ng-template #content let-c="close" let-d="dismiss">

    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Nouvelle sanction</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
        <form #search="ngForm" (ngSubmit)=" onClickSearch(search.value)">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="classe">Nom de famille</label>
                    <input type="text" name="nom" class="form-control" ngModel>
                </div>
                <div class="form-group col-md-6">
                    <label for="classe">Classe</label>
                    <select name="classe" class="form-control" ngModel>
                  <option *ngFor="let classe of classes" [value]="classe.idclasse">{{classe.classe_nom}}</option>
                </select>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="submitted || search.value == null">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
            </div>
        </form>
    </div>

</ng-template>
