<h1>Evaluations</h1>
<div *ngIf="classe">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/notes">Notes et moyennes</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/notes/matieres/{{classe.idclasse}}">{{classe.classe_nom}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled">{{classe.matiere_nom}}</a>
    </li>
  </ul>

  <div class="recherche">
    <div class="row">
      <div class="col-md-5">
        <p *ngIf="ecole"> {{ecole.nom}} <br>Année scolaire: {{classe.annee}} </p>
        <p><strong> {{periode}}</strong></p>

      </div>
      <div class="col-md-5">
        <h5> {{classe.classe_nom}} - {{classe.matiere_nom}} </h5>
        <p>Professeur: {{classe.profNom}} {{classe.profPrenoms}}</p>
      </div>
      <div class="col-md-2">
        <p class="text-center"> <button (click)="print()" class="button">Imprimer</button> </p>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th scope="col">N°</th>
          <th scope="col">Nom et prenoms</th>
          <th scope="col" *ngFor="let ev of evaluations">
            <small class="text-danger">{{ev.date | date:'shortDate' }}</small>
            <br> {{ev.id}}
          </th>
          <th scope="col" *ngFor="let mat of matieres"> {{mat == matiere ? 'Moy ' : mat }} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let eleve of eleves; let i=index">
          <td> {{i+1}} </td>
          <td> {{eleve.nom}} {{eleve.prenoms}} </td>
          <td *ngFor="let ev of evaluations">
            <app-note-eleve-evaluation [evaluation]="ev" [eleve]="eleve">
            </app-note-eleve-evaluation>
          </td>
          <td *ngFor="let matiere of matieres">
            <app-note-eleve-moyenne [eleve]="eleve.ideleve" [matiere]="matiere" [periode]="periode">
            </app-note-eleve-moyenne>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
