<div class="header-mobile">
    Paramètres
    <i class="bi bi-chevron-compact-left" (click)="back()"></i>
</div>
<div class="body-mobile">
    <div class="container" *ngIf="u">
        <h4 class="text-center">
            {{u.encadreur | uppercase}}
            <small>{{u.role | uppercase}} </small>
        </h4>
        <p class="text-center"><i class="bi bi-telephone-fill"></i> {{u.mobile}} </p>
        <br> <br>

        <app-parametres-password></app-parametres-password>

        <br> <br> <br>
        <p class="text-center">
            Déconnexion <br>
            <a class="a" (click)="logout()"><i class="bi bi-box-arrow-in-left icon-big"></i></a>
        </p>
    </div>
</div>

<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
