<h1>Cahiers de texte</h1>
<div *ngIf="c">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/cahiers-textes">Cahiers de texte</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled" tabindex="-1" aria-disabled="true">{{c.classe_nom}} | {{c.matiere_nom}}</a>
        </li>
    </ul>

    <div class="recherche">
        <div class="row">
            <div class="col-md-4">
                <h4>{{c.classe_nom}} <br> <small>{{c.matiere_nom}}</small> </h4>
                <h5> {{c.profNom}} {{c.profPrenoms}} </h5>

                <p> <a class="a" (click)="getSeances(id)">Afficher tout le cahier de texte</a> </p>
            </div>
            <div class="col-md-3">
                <h6 class="text-center">Suivi du cahier de texte</h6>
                <br>
                <app-cahiers-textes-etat [cahier]="c"></app-cahiers-textes-etat>

            </div>
            <div class="col-md-5">
                <h5>Recherche</h5>
                <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="debut">Du</label>
                            <input type="date" name="debut" id="debut" class="form-control" ngModel>

                        </div>
                        <div class="form-group col-md-6">
                            <label for="fin">Au</label>
                            <input type="date" name="fin" id="fin" class="form-control" ngModel>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" [disabled]="submitted || !search.value">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}}</div>
<div *ngFor="let s of seances">
    <app-cahiers-textes-contenu [seance]="s"></app-cahiers-textes-contenu>

</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>