import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../authentication/authentication.service';

@Component({
  selector: 'app-parametres-password',
  templateUrl: './parametres-password.component.html',
  styleUrls: ['./parametres-password.component.css']
})
export class ParametresPasswordComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  id: string;
  u: Convoyeur;
  submitted = false;
  loading = true;
  errorMsg = '';


  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: ConvoyeurService,
    private author: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.createForm();
  }

  getUser(): void {
    this.subscription.add(
      this.service.one()
        .subscribe(
          arg => {
            this.u = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  back(): void {
    history.back();
  }
  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      opassword: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.put(this.form.value)
        .subscribe(
          arg => {
            this.modal.dismissAll();
            this.submitted = false;
            Swal.fire(
              'Changer le mot de passe',
              'La modification de mot de passe a réussi',
              'success'
            );
            this.author.logout();
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
