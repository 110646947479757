<h1>Séances</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Séances</a>
    </li>
</ul>
<div class="recherche">
    <h4>Séances de cours</h4>
    <div class="row" *ngIf="bilan">
        <div class="col-md-3">
            Total séance: <strong>{{bilan.effectif | number}}</strong>
        </div>
        <div class="col-md-3">
            Appel numérique: <strong>{{bilan.presence |number}}</strong>
        </div>
        <div class="col-md-3">
            Nombre de pointage: <strong>{{bilan.pointage |number}}</strong>
        </div>
        <div class="col-md-3">
            Cahier de texte renseigné: <strong>{{bilan.cahierTexte |number}}</strong>
        </div>

    </div>
    <br> <br>
    <h5>Recherche</h5>
    <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="">Classe</label>
                <select name="classe" class="form-control" ngModel>
                          <option *ngFor="let c of classes" [value]="c.idclasse">{{c.classe_nom}}</option>
                        </select>
            </div>
            <div class="form-group col-md-3">
                <label for="">Matière</label>
                <select name="matiere" class="form-control" ngModel>
                          <option *ngFor="let m of matieres" [value]="m.idmatiere">{{m.matiere_nom}}</option>
                        </select>
            </div>
            <div class="form-group col-md-3">
                <label for="">Du</label>
                <input type="date" name="debut" class="form-control" ngModel>
            </div>
            <div class="form-group col-md-3">
                <label for="">Au</label>
                <input type="date" name="fin" class="form-control" ngModel>
            </div>

            <div class="form-group col-md-3">
                <label for="limite">Nombre à afficher</label>
                <select name="limite" class="form-control" ngModel>
                          <option [value]="'20'">20</option>
                          <option [value]="'50'">50</option>
                           <option [value]="'100'">100</option>
                           <option [value]="'250'">250</option>
                        </select>
            </div>
            <div class="form-group col-md-3">
                <label for="ordre">Ordre</label>
                <select name="ordre" class="form-control" ngModel>
                          <option [value]="'DESC'">DESC</option>
                          <option [value]="'ASC'">ASC</option>

                        </select>
            </div>
        </div>

        <div class="form-group" *ngIf="user.role === 'professeurd' ">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="pointage" id="pointage" [value]="1" ngModel>
                <label class="form-check-label" for="pointage">Pointage du professeur</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="presence" id="presence" [value]="1" ngModel>
                <label class="form-check-label" for="presence">Presence professeur</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="cahier" id="cahier" [value]="1" ngModel>
                <label class="form-check-label" for="cahier">Cahier de texte renseigné</label>
            </div>
        </div>

        <div class="form-group">
            <button type="submit" [disabled]="submitted || search.value == null">
                    <span *ngIf="!submitted">Rechercher</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Recherche...
                    </span>
                </button>
        </div>

    </form>
</div>
<ng-template #isVide>
    <h3 class="text-center errorLoading"> Aucune séance </h3>
</ng-template>

<div class="table-responsive" *ngIf="collection > 0; else isVide">
    <table class="table table-sm">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Heure</th>
                <th scope="col">Classe</th>
                <th scope="col">Matière</th>
                <th scope="col">PT</th>
                <th scope="col">AN</th>
                <th scope="col">CT</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of seances | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" [ngClass]="{'alert-success':s.presence == 1 && s.cahierTexte == 1,'alert-warning': (s.fin < (today | date: 'Y-MM-dd H:mm:ss') && s.presence == 1 ) || (s.fin < (today | date: 'Y-MM-dd H:mm:ss') && s.cahierTexte == 1),   'alert-danger':s.fin < (today | date: 'Y-MM-dd H:mm:ss') &&  s.presence == 0 && s.cahierTexte == 0}">

                <td> {{s.debut | date}} </td>
                <td> {{s.debut | date:'shortTime'}}-{{s.fin | date:'shortTime'}} </td>
                <td> {{s.classe_nom}} </td>
                <td> {{s.matiere_nom}} </td>
                <td>
                    <fa-icon [icon]="s.pointage == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.pointage == 0 ? 'error':'success' "> </fa-icon>
                </td>
                <td>
                    <fa-icon [icon]="s.presence == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.presence == 0 ? 'error':'success' "> </fa-icon>
                </td>
                <td>
                    <fa-icon [icon]="s.cahierTexte == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.cahierTexte == 0 ? 'error':'success' "> </fa-icon>
                </td>
                <td> <a routerLink="view/{{s.idseance}}">Voir detail</a> </td>
            </tr>
        </tbody>
    </table>
</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize=5 *ngIf="collection > pageSize" class="float-right">
    <ng-template ngbPaginationFirst>Premier</ng-template>
    <ng-template ngbPaginationLast>Dernier</ng-template>
    <ng-template ngbPaginationPrevious>Précédent</ng-template>
    <ng-template ngbPaginationNext>Suivant</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
