<div class="header-mobile">
    Activités
    <a (click)="back()"><i class="bi bi-chevron-compact-left"></i></a>
</div>
<div class="body-mobile">
    <div class="container">
        <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
        <p>Choisissez le groupe d'activité que vous souhaitez suivre</p>
        <div class="row">
            <div class="col-md-4" *ngFor="let g of groupes">
                <app-groupes-action [id]="g.iddetail"></app-groupes-action>
            </div>
        </div>
    </div>
    <p class="errorLoading text-center" *ngIf="!loading && collection == 0"> Aucun groupe d'activité </p>
</div>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
