<ngb-alert *ngIf="errorMsg" type="danger" (close)="errorMsg=''"> {{errorMsg}} </ngb-alert>
<div class="progress form-group" *ngIf="progress > 0">
    <div class="progress-bar progress-bar-striped" [ngClass]="{'bg-success':progress > 0,'bg-danger':progress <= 0}" role="progressbar" [style.width.%]="progress">{{progress}}%
    </div>
</div>

<div class="form-group">
    <label for="fichier" class="sr-only">Charger puis télécharger</label>
    <input type="file" class="form-control-file" (change)="onSelectFile($event)">
</div>
<div class="form-group">
    <button class="button" (click)="onSubmit()" [disabled]="submitted ||  !selectedFile">
                  <span *ngIf="!submitted">Télécharger</span>
                  <span *ngIf="submitted">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Téléchargement en cours
                  </span>
                </button>
</div>
