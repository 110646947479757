import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-activites-view',
  templateUrl: './activites-view.component.html',
  styleUrls: ['./activites-view.component.css']
})
export class ActivitesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  activite: Logistiques;
  abonnes: Logistiques[];
  collection = 0;
  submitted = false;
  loading = true;
  errorMsg = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getActivite(this.id);
    this.getAbonnes(this.id);
  }

  getActivite(id: string): void {
    this.service.view('activites', id)
      .subscribe(arg => {
        this.activite = arg;
        this.loading = false;
      },
        erg => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  getAbonnes(id: string): void {
    this.service.views('activites_abonnes', id)
      .subscribe(arg => {
        this.abonnes = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        erg => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  back(): void {
    history.back();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
