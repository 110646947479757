import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
  { path: 'authentication', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  {
    path: 'directeur',
    canActivate: [LoginGuard],
    loadChildren: () => import('./directeur/directeur.module').then(m => m.DirecteurModule)
  },
  {
    path: 'professeur',
    canActivate: [LoginGuard],
    loadChildren: () => import('./professeur/professeur.module').then(m => m.ProfesseurModule)
  },
  {
    path: 'logistiques',
    canActivate: [LoginGuard],
    loadChildren: () => import('./logistiques/logistiques.module').then(m => m.LogistiquesModule)
  },
  {
    path: 'administration',
    canActivate: [LoginGuard],
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'convoyeur',
    canActivate: [LoginGuard],
    loadChildren: () => import('./convoyeur/convoyeur.module').then(m => m.ConvoyeurModule)
  },
  { path: 'suivis', loadChildren: () => import('./suivis/suivis.module').then(m => m.SuivisModule) }
];
const config: ExtraOptions = {
  useHash: true,
};
@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
