import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SouscripteursRoutingModule } from './souscripteurs-routing.module';
import { SouscripteursComponent } from './souscripteurs.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SouscripteursClasseComponent } from './souscripteurs-classe/souscripteurs-classe.component';
import { SouscripteursViewComponent } from './souscripteurs-view/souscripteurs-view.component';
import { SouscripteursEcheancierComponent } from './souscripteurs-echeancier/souscripteurs-echeancier.component';
import { SouscripteursActivitesComponent } from './souscripteurs-activites/souscripteurs-activites.component';
import { SouscripteursMedicalComponent } from './souscripteurs-medical/souscripteurs-medical.component';


@NgModule({
  declarations: [
    SouscripteursComponent,
    SouscripteursClasseComponent,
    SouscripteursViewComponent,
    SouscripteursEcheancierComponent,
    SouscripteursActivitesComponent,
    SouscripteursMedicalComponent
  ],
  imports: [
    CommonModule,
    SouscripteursRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class SouscripteursModule { }
