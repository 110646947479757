import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../convoyeur';
import { ConvoyeurService } from '../convoyeur.service';

@Component({
  selector: 'app-historiques',
  templateUrl: './historiques.component.html',
  styleUrls: ['./historiques.component.css']
})
export class HistoriquesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  id: string;
  activites: Convoyeur[];
  collection = 0;
  submitted = false;
  loading = true;
  errorMsg = '';


  constructor(
    private fb: FormBuilder,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    this.getActivites();
    this.createForm();
  }

  getActivites(): void {
    this.service.list('activites')
      .subscribe(arg => {
        this.activites = arg;
        this.collection = arg.length;
        this.loading = false;
      });
  }

  back(): void {
    history.back();
  }

  createForm(): void {
    this.form = this.fb.group({
      debut: ['', Validators.required],
      fin: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.service.deuxArgs('activites_periode', this.form.get('debut').value, this.form.get('fin').value)
      .subscribe((arg: Convoyeur[]) => {
        this.activites = arg;
        this.collection = arg.length;
        this.submitted = false;
      },
        erg => {
          this.errorMsg = erg;
          this.submitted = false;
        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
