import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-souscripteurs-activites',
  templateUrl: './souscripteurs-activites.component.html',
  styleUrls: ['./souscripteurs-activites.component.css']
})
export class SouscripteursActivitesComponent implements OnInit, OnDestroy {
  @Input() eleve: string;
  @Input() produit: string;
  subscription = new Subscription();
  activites: Logistiques[];

  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.service.deuxArgs('activites_abonne', this.produit, this.eleve)
        .subscribe(arg => {
          this.activites = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
