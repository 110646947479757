import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';

import { ProfesseurRoutingModule } from './professeur-routing.module';
import { ProfesseurComponent } from './professeur.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfesseurHomeComponent } from './professeur-home/professeur-home.component';
import { ProfesseurService } from './professeur.service';



@NgModule({
  declarations: [
    ProfesseurComponent,
    ProfesseurHomeComponent,
  ],
  imports: [
    CommonModule,
    ProfesseurRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
  providers: [ProfesseurService]
})
export class ProfesseurModule { }
