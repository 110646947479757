<h1>Evaluations </h1>
<div *ngIf="classe">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/notes">Notes et moyennes</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{classe.classe_nom}}</a>
        </li>
    </ul>

    <div class="recherche">
        <div class="row">
            <div class="col-md-6">
                <h4> {{classe.classe_nom}} </h4>
            </div>
            <div class="col-md-3">
                <p> <button (click)="print()" class="button">Imprimer</button> </p>

            </div>
        </div>

    </div>

    <div class="matiere saut-page-after" *ngFor="let matiere of matieres">
        <div class="row">
            <div class="col-md-4">
                <p>Classe: <strong>{{classe.classe_nom}}  </strong> </p>
            </div>
            <div class="col-md-4">
                <p>Matière: <strong> {{matiere.matiere_nom}}  </strong> </p>
            </div>
            <div class="col-md-4">
                <p>Professeur: <strong> {{matiere.profNom}} {{matiere.profPrenoms}} </strong> </p>
            </div>
        </div>
        <app-notes-classe-global-eleves [classe]="classe.idclasse" [matiere]="matiere.idmatiere"></app-notes-classe-global-eleves>



    </div>
</div>
<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
