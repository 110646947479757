<a class="a button float-md-right" (click)="open(content)">Ajouter </a>
<h4>{{ prod === 'TRANS' ? 'Itinéraires' : 'Groupes' }} </h4>
<div class="table-responsive">
    <table class="table table-bordered table-striped" *ngIf="prod === 'TRANS'; else noTransport">
        <thead>
            <tr>
                <th scope="col">Itinéraire </th>
                <th scope="col">Car</th>
                <th scope="col">Chauffeur</th>
                <th scope="col">Convoyeur</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let g of groupes">
                <td> {{g.groupe}} </td>
                <td> {{g.immatriculation}} </td>
                <td> {{g.convoyeur}} </td>
                <td> {{g.chauffeur}} </td>
            </tr>
        </tbody>
    </table>
    <ng-template #noTransport>
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th scope="col">Groupe</th>
                    <th scope="col">Formateur/cantinier</th>
                    <th scope="col">Téléphone</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let g of groupes">
                    <td> {{g.groupe}} </td>
                    <td> {{g.formateur}}</td>
                    <td> {{g.mobile}}</td>
                </tr>
            </tbody>
        </table>

    </ng-template>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Créer {{ prod === 'TRANS' ? 'Itinéraire' : 'Groupe' }} </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
            <div class="form-group" *ngIf="prod === 'TRANS' ">
                <label for="car">Véhicule</label>
                <select formControlName="car" class="form-control" id="car">
                  <option *ngFor="let v of vehicules" [value]="v.idvehicule">{{v.immatriculation}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="groupe"> {{ prod === 'TRANS' ?'Itinéraire' : 'Groupe' }} </label>
                <input type="text" formControlName="groupe" class="form-control" id="groupe">
            </div>

            <div *ngIf="prod !== 'TRANS' ">
                <h6>Cantinier/Formateur</h6>
                <div class="form-group">
                    <label for="formateur"> Nom et prenoms </label>
                    <input type="text" formControlName="formateur" class="form-control" id="formateur">
                </div>
                <div class="form-group">
                    <label for="mobile">Téléphone</label>
                    <input type="tel" formControlName="mobile" class="form-control" id="mobile">
                </div>
            </div>


            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



            </div>
        </div>
    </form>
</ng-template>
