import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { AuthenticationLoginComponent } from './authentication-login/authentication-login.component';
import { AuthenticationOublieComponent } from './authentication-oublie/authentication-oublie.component';

const routes: Routes = [{
  path: '',
  component: AuthenticationComponent,
  children: [
    { path: 'login', component: AuthenticationLoginComponent },
    { path: 'mot-passe-oublie', component: AuthenticationOublieComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
