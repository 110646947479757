import { Component, OnDestroy, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-groupes-statut',
  templateUrl: './groupes-statut.component.html',
  styleUrls: ['./groupes-statut.component.css']
})
export class GroupesStatutComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() abonne: string;
  @Input() activite: string;
  @Input() rubrique: string;
  echeance: Convoyeur;
  form: FormGroup;
  gps;
  loading = true;
  submitted = false;
  errorMsg = '';
  today: Date = new Date();
  moments = [];
  actions = [];

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.gps = position.coords;
    }, erg => {
      this.errorMsg = 'Vous devez activer la localisation';
    });
    this.getService(this.activite);
    this.getEcheance(this.abonne, this.rubrique);
    this.createForm();
  }

  getService(id: string): void {
    this.subscription.add(
      this.service.view('groupes', id)
        .subscribe((arg: Convoyeur) => {
          if (arg.service === 'TRANS') {
            this.moments = ['MATIN', 'SOIR'];
            this.actions = ['MONTEE', 'DESCENTE'];
          } else if (arg.service === 'CANT') {
            this.moments = ['ENTREE CANTINE', 'PRISE DE REPAS', 'SIESTE'];
            this.actions = ['SORTIE', 'ENTREE'];
          } else {
            this.moments = ['ENTRAINERMENT', 'COMPETITION'];
            this.actions = ['SORTIE', 'ENTREE'];
          }
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getEcheance(id: string, rubrique: string): void {
    this.subscription.add(
      this.service.deuxArg('echeanciers', id, rubrique)
        .subscribe(arg => {
          this.echeance = arg;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      abonne: [this.abonne, Validators.required],
      activite: [this.activite, Validators.required],
      moment: ['', Validators.required],
      statut: ['', Validators.required],
      action: ['', Validators.required],
      gps: [this.gps],
      remarque: [''],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('activites', this.form.value)
        .subscribe(
          arg => {
            this.modal.dismissAll();
            this.submitted = false;
            Swal.fire(
              'Enrégistrement',
              'Information enrégistrée avec succès ',
              'success'
            );
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
