import { Component, OnInit, OnDestroy } from '@angular/core';
import { Logistiques } from '../logistiques';
import { LogistiquesService } from '../logistiques.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logistiques-home',
  templateUrl: './logistiques-home.component.html',
  styleUrls: ['./logistiques-home.component.css']
})
export class LogistiquesHomeComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  produits = 0;
  abonnes = 0;
  encadreurs = 0;
  zones = 0;
  errorLoading = '';
  horsservice = 0;
  cantine = 0;
  garde = 0;
  vehicules = 0;
  itineraires = 0;
  loading = true;

  constructor(
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getServices();
    this.getAbonnes();
    this.getVehicules();
    this.getHorsservice();
    this.getEncadreurs();
    this.getZones();
  }

  getServices(): void {
    this.subscription.add(
      this.service.list('services')
        .subscribe(arg => {
          this.produits = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbonnes(): void {
    this.subscription.add(
      this.service.list('abonnes')
        .subscribe(arg => {
          this.abonnes = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getVehicules(): void {
    this.subscription.add(
      this.service.list('vehicules')
        .subscribe(arg => {
          this.vehicules = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getHorsservice(): void {
    this.subscription.add(
      this.service.list('horsservices')
        .subscribe(arg => {
          this.horsservice = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );

  }

  getEncadreurs(): void {
    this.subscription.add(
      this.service.list('encadreurs')
        .subscribe(arg => {
          this.encadreurs = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getZones(): void {
    this.subscription.add(
      this.service.list('zones')
        .subscribe(arg => {
          this.zones = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
