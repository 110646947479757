<div class="table-responsive" *ngIf="cahiers">
    <table class="table table-sm table-bordered">
        <tbody>
            <tr *ngFor="let cahier of cahiers">
                <td class="seance">

                    <h5>
                        {{seance.debut | date:'fullDate'}}
                        <small>{{seance.debut | date:'shortTime'}} - {{seance.fin | date:'shortTime'}}</small>
                    </h5>
                    <p>Pointage biométrique:
                        <fa-icon [icon]="seance.pointage == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="seance.pointage == 0 ? 'error':'success' "> </fa-icon>

                    </p>
                    <p>Appel Numérique: <span class="font-weight-bold" *ngIf="seance.presence == 1">{{seance.appelDate | date:'medium'}}</span>
                    </p>

                    <p>
                        Renseigné le: <span class="font-weight-bold"> {{cahier.creationDate | date:'medium'}}</span>
                    </p>

                    <p *ngIf="seance.presence == 1">
                        Effectif d'élèves présents: <span class="font-weight-bold"> {{presents}} sur {{seance.effectif}}</span>
                    </p>

                    <p class="text-warning" *ngIf="seance.rattrapage == 1">Cours de rattrapage</p>


                    <div *ngIf="seance.cahierTexte == 1 && seance.presence == 1" class="alert alert-success">Séance a été validée </div>
                </td>
                <td>
                    <div class="container-fluid">
                        <span class="text-success float-right" *ngIf="cahier.terminee == 1">Leçon achevée</span>
                        <h4>
                            <app-cahiers-textes-lecon [id]="cahier.lecon_id"></app-cahiers-textes-lecon>
                        </h4>
                        <app-cahiers-textes-lecon-details [id]="cahier.idcahier"></app-cahiers-textes-lecon-details>
                        <hr>
                        <div class="row justify-content-center text-center">
                            <!--div class="col-md-6" *ngIf="cahier.fichier">
                                <h6>Résumé du cours</h6>
                                <a href="https://staff.vie-ecoles.com/server/uploads/{{cahier.fichier}}"> Télécharger le resumé </a>
                            </div-->
                            <div class="col-md-6">
                                <h6>Prochain cours</h6>
                                <p>{{cahier.prochainCoursDate | date}} <br> {{cahier.prochainCoursNote}} </p>
                            </div>
                            <div class="col-md-12">
                                <div *ngIf="cahier.observation">
                                    <h6> <u>Observation du Directeur</u> </h6>
                                    <p> {{cahier.observation}}
                                        <small> {{cahier.observationDate | date:'medium'}} </small>
                                    </p>
                                </div>
                                <p class="text-center" *ngIf="user.role === 'directeur' ">
                                    <button class="button" (click)="open(content)">Faire une observation</button>
                                </p>
                            </div>
                        </div>
                        <hr>
                        <p class="text-danger text-center marginBottom40" *ngIf="cahier.retard == 1"> En retard sur le programme de progression </p>
                    </div>

                </td>

                <ng-template #content let-c="close" let-d="dismiss">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modal-basic-title">Observation du directeur </h5>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
                        </div>
                        <div class="modal-body">
                            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
                            <input type="hidden" formControlName="cahier" [(ngModel)]="cahier.idcahier">
                            <div class="form-group">
                                <label for="type">Observation</label>
                                <textarea formControlName="observation" id="observation" class="form-control" rows="5" [(ngModel)]="cahier.observation"></textarea>

                            </div>

                            <div class="form-group">
                                <button type="submit" [disabled]="form.invalid || submitted === true">
                    <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
                </button>
                            </div>
                        </div>
                    </form>
                </ng-template>


            </tr>
        </tbody>
    </table>
</div>