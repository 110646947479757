<h5> <u>Convoyeur</u> </h5>
<div *ngIf="v">
    <p *ngIf="v.convoyeur; else noConvoyeur">
        <strong> {{v.convoyeur}}</strong> <br>Tel.: {{v.convoyeur_mobile}}
        <br> <a class="a" (click)="open(content)">Modifier</a>
    </p>
    <ng-template #noConvoyeur>
        <a class="a" (click)="open(content)">Ajouter</a>
    </ng-template>
</div>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title"> Convoyeur </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="convoyeur">Nom et prénoms</label>
                <input type="text" formControlName="convoyeur" class="form-control" id="convoyeur" [(ngModel)]="v.convoyeur">
            </div>
            <div class="form-group">
                <label for="convoyeur_mobile">Téléphone</label>
                <input type="tel" formControlName="mobile" class="form-control" id="mobile" [(ngModel)]="v.convoyeur_mobile">
            </div>
            <div class="form-group">
                <label for="convoyeur_mobile">Pièce d'identité</label>
                <input type="text" formControlName="piece" class="form-control" id="piece" [(ngModel)]="v.convoyeur_piece">
            </div>
            <div class="form-group">
                <label for="pin">Code PIN</label>
                <input type="text" formControlName="pin" class="form-control" id="pin" [(ngModel)]="v.pin" maxlength="4">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>