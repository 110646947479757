import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Users } from './users';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  url = 'https://staff.vie-ecoles.com/server/authentication';

  private currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Users>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Users {
    return JSON.parse(localStorage.getItem('user'));
  }

  login(body: Users): Observable<Users> {
    return this.http.post<Users>(this.url, body)
      .pipe(map(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }),
        catchError(this.handleError));
  }

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/authentication/login']);
  }

  oublie(body: Users): Observable<string> {
    return this.http.put<string>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let response = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      response = `Une erreur s'est produite: ${error.error}`;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error.message);
      response = ` ${error.error.message}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(response);
  }

}
