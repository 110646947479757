import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-groupes-action',
  templateUrl: './groupes-action.component.html',
  styleUrls: ['./groupes-action.component.css']
})
export class GroupesActionComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  @Input() id: string;
  subscription: Subscription = new Subscription();
  form: FormGroup;
  groupe: Convoyeur;
  loading = true;
  submitted = false;
  errorMsg = '';
  activites = [];
  actions = [];
  lat = 0;
  lng = 0;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.lng = position.coords.longitude;
      this.lat = position.coords.latitude;
    }, erg => {
      this.errorMsg = 'Vous devez activer la localisation';
    });
    this.getGroupe(this.id);
    this.createForm();
  }

  getGroupe(id: string): void {
    this.service.view('groupes', id)
      .subscribe(arg => {
        this.groupe = arg;
        if (arg.service === 'TRANS') {
          this.activites = ['LA MONTEE DU BUS', 'LA DESCENTE DU BUS'];
          this.actions = ['MATIN', 'SOIR'];

        } else if (arg.service === 'CANT') {
          this.activites = ['L\'ENTREE A LA CANTINE', 'LA PRISE DE REPAS', 'LA SIESTE'];
          this.actions = ['SORTIE', 'ENTREE'];
        } else {
          this.activites = ['SEANCE'];
          this.actions = ['DEBUT', 'FIN'];
        }
        this.loading = false;
      },
        erg => {
          this.errorMsg = erg.error.message;
          this.loading = false;
        });
  }

  createForm(): void {
    this.form = this.fb.group({
      service: [this.id, Validators.required],
      activite: ['', Validators.required],
      action: ['', Validators.required],
      lng: [this.lng],
      lat: [this.lat]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('activites', this.form.value)
        .subscribe(
          arg => {
            this.router.navigate(['/' + this.user.role + '/groupes/view/' + arg]);
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
