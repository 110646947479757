import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParametresRoutingModule } from './parametres-routing.module';
import { ParametresComponent } from './parametres.component';
import { ParametresPasswordComponent } from './parametres-password/parametres-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ParametresComponent,
    ParametresPasswordComponent
  ],
  imports: [
    CommonModule,
    ParametresRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModalModule,
    NgbAlertModule
  ]
})
export class ParametresModule { }
