<h1>Services scolaires</h1>
<div *ngIf="abonne">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services/{{abonne.iddetail}}">{{abonne.designation}}</a>
        </li>

        <li class="nav-item">
            <a class="nav-link disabled">{{abonne.nom}} {{abonne.prenoms}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-7">
                <h2>{{abonne.nom}} {{abonne.prenoms}} <small> {{abonne.classe}} </small> </h2>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-6">Service: <strong>{{abonne.designation}} </strong> </div>
                    <div class="col-md-6">Prix:
                        <strong *ngIf="abonne.nouveau === 'NON'; else noAncien"> {{abonne.prix  | currency:'XOF'}} </strong>
                        <ng-template #noAncien>
                            <strong> {{abonne.prix2 | currency:'XOF'}} </strong>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <h4>Echéanciers</h4>
    <div class="table-responsive" *ngIf="frais; else noFrais">
        <table class="table table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col">Date limite</th>
                    <th scope="col">Libellé</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Payé</th>
                    <th scope="col">Reste à payer</th>
                    <th scope="col">Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let f of frais" [ngClass]="{'alert-success': f.rapayer == 0, 'alert-warning': f.rapayer > 0, 'alert-danger': f.paye <= 0}">
                    <td> {{f.datelimite | date}} </td>
                    <td> {{f.libelle}} </td>
                    <td> {{f.montant | currency:'XOF'}} </td>
                    <td> {{f.paye | currency:'XOF'}} </td>
                    <td> {{f.rapayer | currency:'XOF'}} </td>
                    <td> {{f.dateenreg | date:'medium'}} </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #noFrais>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
