<a class="a float-md-right" (click)="open(content)">Ajouter</a>
<h4>Fiche médicale</h4>
<div *ngIf="fiches; else noFrais">
    <div *ngFor="let f of fiches">
        <h6> {{f.anormalie | uppercase }} </h6>
        <p> {{f.description}} </p>
    </div>
</div>
<ng-template #noFrais>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>



<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title"> Convoyeur </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="convoyeur">Anormalie</label>
                <input type="text" formControlName="anormalie" class="form-control" id="convoyeur">
            </div>
            <div class="form-group">
                <label for="description">Description</label>
                <textarea formControlName="description" class="form-control" id="description" cols="5"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>
