<ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>


<div class="row" *ngIf="echeance" [ngClass]="{'alert-danger': echeance.datelimite < (today | date:'Y-MM-d') &&  echeance.rapayer > 0}">
    <div class="col-7">
        <div class="form-group">
            <div class="custom-control form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="present" [value]="eleve.present == 1 ? 0 : 1" [checked]="eleve.present == 1" (change)="onAssiduite($event)">
                <label class="form-check-label"> {{echeance.nom}} {{echeance.prenoms}} </label>
            </div>
        </div>
    </div>
    <div class="col-3">
        <button class="button btn-sm" (click)="open(content)">Remarque</button>
    </div>

    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title"> {{echeance.nom}} {{echeance.prenoms}} </h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
                <div class="alert alert-danger" *ngIf="echeance.rapayer > 0">L'abonné n'a pas droit au service pour impayé </div>
                <div class="form-group">
                    <label for="chauffeur_mobile">Remarque</label>
                    <textarea formControlName="remarque" class="form-control" id="remarque" rows="3"></textarea>
                </div>

                <div class="form-group">
                    <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



                </div>
            </div>
        </form>
    </ng-template>
</div>
