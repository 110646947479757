import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-convoyeur-home',
  templateUrl: './convoyeur-home.component.html',
  styleUrls: ['./convoyeur-home.component.css']
})
export class ConvoyeurHomeComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  today: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
