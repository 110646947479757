import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-parc-auto-visite',
  templateUrl: './parc-auto-visite.component.html',
  styleUrls: ['./parc-auto-visite.component.css']
})
export class ParcAutoVisiteComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @Input() id: string;
  v: Logistiques;
  charge: Logistiques;
  form: FormGroup;
  submitted = false;
  loading = true;
  errorMsg = '';
  errorLoading = '';

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.getVehicule(this.id);
    this.getCharge(this.id);
    this.createForm();
  }
  getVehicule(id: string): void {
    this.subscription.add(
      this.service.view('vehicules', id)
        .subscribe(arg => {
          this.v = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          }
        )
    );
  }
  getCharge(id: string): void {
    this.subscription.add(
      this.service.deuxArg('vehiculeCharges', id, 'VISIT')
        .subscribe(arg => {
          this.charge = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          }
        )
    );
  }
  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      vehicule: [this.id, Validators.required],
      chargeType: ['VISIT', Validators.required],
      charge: ['', Validators.required],
      cout: ['', Validators.required],
      fournisseur: [''],
      debut: ['', Validators.required],
      fin: ['', Validators.required]

    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('vehiculeCharges', this.form.value)
        .subscribe(
          arg => {
            this.getCharge(this.id);
            this.modal.dismissAll();
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
