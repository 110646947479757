import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';

@Component({
  selector: 'app-notes-moyennes',
  templateUrl: './notes-moyennes.component.html',
  styleUrls: ['./notes-moyennes.component.css']
})
export class NotesMoyennesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  matiere: string;
  periode: string;
  classe: Suivis;
  detail: Suivis;
  eleves: Suivis[];
  bilans: Suivis[];

  evaluations: Suivis[] = [];

  e: Suivis;
  noteBilan: Suivis;
  loading = true;
  submitted = false;
  errorMsg = '';
  errorType = '';

  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.matiere = this.ar.snapshot.paramMap.get('matiere');
    this.periode = this.ar.snapshot.paramMap.get('periode');

    this.getClasse(this.id);
    this.getMoyennes(this.id, this.matiere, this.periode);
    this.getBilan(this.id, this.matiere, this.periode);
    this.getInfo(this.id, this.matiere);
  }

  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }


  getMoyennes(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`moyennes_classe?classe=${id}&matiere=${matiere}&groupe=eleve&periode=${periode}`)
        .subscribe(arg => {
          this.eleves = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  /*
    getEleves(id: string, matiere: string): void {
      this.subscription.add(
        this.service.list(`notes_classe?classe=${id}&matiere=${matiere}&groupe=eleve&periode=1er Trimestre`)
          .subscribe(arg => {
            this.eleves = arg;
            this.loading = false;
          },
            (erg: never) => {
              this.errorMsg = erg;
              this.loading = false;
            })
      );
    }
  */
  onEnter(i): void {
    const index = i + 1;
    const next = index === this.eleves.length ? 0 : index;
    document.getElementById(`input${next}`).focus();
  }

  getBilan(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`notes_classe?classe=${id}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.bilans = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getInfo(id: string, matiere: string): void {
    this.subscription.add(
      this.service.deuxArg('classe_prof', id, matiere)
        .subscribe(arg => {
          this.detail = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  print(): void {
    window.print();
  }
  onSubmit(data: any): void {
    console.log(data.moyenne.target.value);
    this.submitted = true;
    this.subscription.add(
      this.service.add('moyennes',
        {
          eleve: data.eleve,
          moyenne: data.moyenne.target.value,
          periode: data.periode,
          matiere: data.matiere
        })
        .subscribe(arg => {
          this.getMoyennes(this.id, this.matiere, this.periode);
          this.errorType = 'success';
          this.submitted = false;
          const index = data.input + 1;
          const next = index === this.eleves.length ? 0 : index;
          document.getElementById(`input${next}`).focus();
        },
          erg => {
            this.errorMsg = erg;
            this.errorType = 'error';
            this.submitted = false;
          }
        )
    );
  }

  getUpdate(event): void {
    this.getMoyennes(this.id, this.matiere, this.periode);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
