<h1>Echéanciers</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Echéanciers</a>
    </li>
</ul>
<div class="recherche">
    <h2>Echéanciers</h2>
    <p> {{collection }} échanciers</p>
    <p> <a (click)="open(content)" class="a">Ajouter un nouvel échéancier</a> </p>
</div>
<div class="table table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col">Branche</th>
                <th scope="col">Statut</th>
                <th scope="col">Rubrique</th>
                <th scope="col">Montant</th>
                <th scope="col">Date limite</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let f of frais | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <td> {{f.branche}} </td>
                <td> {{f.statut}} </td>
                <td> {{f.rubrique}} </td>
                <td> {{f.montant | currency:'CFA'}} </td>
                <td> {{f.datelimite | date}} </td>
            </tr>
        </tbody>
    </table>

</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize='5' *ngIf="collection > pageSize" class="float-right">
    <ng-template ngbPaginationFirst>Premier</ng-template>
    <ng-template ngbPaginationLast>Dernier</ng-template>
    <ng-template ngbPaginationPrevious>Précédent</ng-template>
    <ng-template ngbPaginationNext>Suivant</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Nouvelle charge
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="branche">Niveau</label>
                <select formControlName="branche" class="form-control" id="branche">
                    <option [value]="'*'">Tous les niveaux</option>
                      <option *ngFor="let b of branches" [value]="b.code"> {{b.nom}} </option>
                    </select>
            </div>

            <div class="form-group">
                <label for="statut">Statut</label>
                <select formControlName="statut" class="form-control" id="branche">

                      <option *ngFor="let s of statuts" [value]="s.code"> {{s.nom}} </option>
                    </select>
            </div>

            <div class="form-group">
                <label for="rubrique">Rubrique</label>
                <select formControlName="rubrique" class="form-control" id="rubrique">
                      <option *ngFor="let r of rubriques" [value]="r.code"> {{r.nom}} </option>
                    </select>
            </div>

            <div class="form-group">
                <label for="libelle">Libellé</label>
                <input type="text" formControlName="libelle" class="form-control">
            </div>

            <div class="form-group">
                <label for="rubrique">Echeancier</label>
                <select formControlName="echeance" class="form-control" id="echeance">
                      <option *ngFor="let e of echeances" [value]="e.code"> {{e.nom}} </option>
                    </select>
            </div>
            <div class="form-group">
                <label for="datelimite">Date limite</label>
                <input type="date" formControlName="datelimite" class="form-control">
            </div>
            <div class="form-group">
                <label for="montant">Montant Ancien</label>
                <input type="number" formControlName="montant" class="form-control">
            </div>
            <div class="form-group">
                <label for="monatnt2">Montant Nouveau</label>
                <input type="number" formControlName="montant2" class="form-control">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
            </div>
        </div>
    </form>
</ng-template>