import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdministrationService } from '../administration.service';
import { Administration } from '../administration';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-administration-ecoles',
  templateUrl: './administration-ecoles.component.html',
  styleUrls: ['./administration-ecoles.component.css']
})
export class AdministrationEcolesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  ecoles: Administration[];
  loading = true;
  suscription = new Subscription();
  collection = 0;
  page = 1;
  pageSize = 50;
  maxSize = 4;
  errorMsg = '';

  constructor(private service: AdministrationService) { }

  ngOnInit(): void {
    this.getEcoles();
  }

  getEcoles(): void {
    this.suscription.add(this.service.list('ecoles')
      .subscribe(arg => {
        this.ecoles = arg;
        this.collection = arg.length;
        this.loading = false;
      }, erg => {
        this.errorMsg = 'Erreur de chargement de données';
        this.loading = false;
      }));
  }


  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }


}
