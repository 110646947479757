<h1>Encadreurs</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Encadreur</a>
    </li>
</ul>
<div class="recherche">
    <h2>Encadreurs</h2>
    <button class="button" (click)="open(content)">Nouvel encadreur</button>
</div>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Nom et prenoms</th>
                <th scope="col">Rôle</th>
                <th scope="col">Contact</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of encadreurs ; let i= index">
                <td> {{i+1}} </td>
                <td> {{e.encadreur | uppercase}} </td>
                <td> {{e.role}} </td>
                <td> {{e.mobile}} </td>
                <td>
                    <app-encadreurs-update [id]="e.idencadreur"></app-encadreurs-update>
                </td>
            </tr>
        </tbody>
    </table>
</div>



<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title"> Nouvel encadreur </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="chauffeur">Nom et prenoms</label>
                <input type="text" formControlName="encadreur" class="form-control" id="chauffeur">
            </div>
            <div class="form-row">
                <div class="form-group col-md-7">
                    <label for="chauffeur">Rôle</label>
                    <select formControlName="role" class="form-control" id="role">
                      <option *ngFor="let r of roles" [value]="r">{{r | uppercase}}</option>
                    </select>
                </div>
                <div class="form-group col-md-5">
                    <label for="mobile">Téléphone</label>
                    <input type="tel" formControlName="mobile" class="form-control" id="mobile">
                </div>
            </div>
            <div class="form-group">
                <label for="pin">Code PIN</label>
                <input type="password" formControlName="pin" class="form-control" id="pin" maxlength="4">
            </div>
            <div class="form-group">
                <label for="piece">Pièce d'identité</label>
                <input type="tel" formControlName="piece" class="form-control" id="piece">
            </div>


            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>
