<h1>Evaluations </h1>
<div *ngIf="e">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/evaluations">Evaluations</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/evaluations/view/{{e.idevaluation}}">Evaluation N° {{e.id}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled">Notes</a>
    </li>

  </ul>
  <div class="recherche">
    <h4>Evaluation N° {{e.id}} </h4>
    <div class="row">
      <div class="col-md-4"> Classe: <strong>{{e.classe_nom}}</strong> </div>
      <div class="col-md-4"> Matière: <strong>{{e.matiere_nom}}
          <span *ngIf="e.sous_matiere"> {{e.sous_matiere}} </span> </strong>
      </div>
      <div class="col-md-4">
        Date: <strong> {{e.date | date:'fullDate' }}</strong>
      </div>
      <div class="col-md-4">Type: <strong>{{e.type_evaluation}}</strong> </div>
      <div class="col-md-4">Noter sur : <strong>{{e.notation | number}}</strong> </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-3">
        <button type="button" class="button btn-block"
          routerLink="/{{user.role}}/evaluations/view/{{e.idevaluation}}">Terminer la saisir</button>
      </div>
    </div>


  </div>
  <div class="alert alert-danger" *ngIf="errorMsg">{{errorMsg}} </div>
  <div class=" table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Matricule</th>
          <th scope="col">Nom et prenoms</th>
          <th scope="col">Sexe</th>
          <th scope="col">Note</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of eleves; let i=index">
          <td> {{i+1}} </td>

          <td> {{el.matricule | uppercase}} </td>
          <td> {{el.nom}} {{el.prenoms}} </td>
          <td> {{el.sexe}} </td>


          <td> <input type="number"
              (keyup.enter)="onNoter({eleve: el.ideleve, evaluation:e.idevaluation, note: $event, input: i })"
              id="input{{i}}" [value]="el.note | number:'2.0-2':'en'" [max]="e.notation" min="0" style=" width:
              50px;">/{{e.notation }}

          </td>

        </tr>
      </tbody>
    </table>
  </div>

</div>


<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>