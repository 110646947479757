<h1>Notes et moyennes </h1>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
<div *ngIf="eleve">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/evaluations">Evaluations</a>
        </li>

        <li class="nav-item">
            <a class="nav-link disabled">{{eleve.nom}} {{eleve.prenoms}} </a>
        </li>

    </ul>

    <div class="recherche">
        <div class="row">
            <div class="col-md-6">
                <h4> {{eleve.nom}} {{eleve.prenoms}}
                    <small> {{eleve.classe_nom}} </small>
                </h4>
            </div>
            <div class="col-md-6">
                <h5> {{matiere}} </h5>
            </div>
        </div>

    </div>
    <div class="alert alert-danger" *ngIf="errorMsg">{{errorMsg}} </div>
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Période</th>
                    <th scope="col">Matière</th>
                    <th scope="col">Note</th>
                    <th scope="col">Appréciation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let n of notes; let i=index">
                    <td> {{i+1}} </td>
                    <td> {{n.date | date}}</td>
                    <td> {{n.periode}} </td>
                    <td>
                        {{n.matiere_nom}}
                        <span *ngIf="n.sous_matiere"> - {{n.sous_matiere}} </span>
                    </td>
                    <td> <strong>{{n.note | number}}/{{n.notation}}</strong> </td>
                    <td> {{ appreciation(n.note, n.notation) }}</td>

                </tr>
            </tbody>
        </table>
    </div>

</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>