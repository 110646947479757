import { Component, OnDestroy, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/Suivis';
import { SuivisService } from 'src/app/suivis/Suivis.service';

@Component({
  selector: 'app-evaluations-note-delai',
  templateUrl: './evaluations-note-delai.component.html',
  styleUrls: ['./evaluations-note-delai.component.css']
})
export class EvaluationsNoteDelaiComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() evaluation: Suivis;
  form: FormGroup;
  id: string;
  e: Suivis;
  bilan: Suivis;
  notes: Suivis[];
  loading = true;
  errorMsg = '';
  submitted = false;
  today: Date = new Date();

  types: Suivis[];
  classes: Suivis[];
  matieres: Suivis[];
  periodes = ['1er Trimestre', '2e Trimestre', '3e Trimestre', '1er Semestre', '2e Semestre'];
  jour: any;


  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private service: SuivisService,
    @Inject(LOCALE_ID) private locale: string) {
    this.jour = formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
  }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getEvaluation(this.id);

    this.createForm();
  }
  getEvaluation(id: string): void {
    this.subscription.add(
      this.service.view('evaluations', id)
        .subscribe(
          (arg: Suivis) => {
            this.e = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  open(c): void {
    this.modal.open(c);
  }


  createForm(): void {
    this.form = this.fb.group({
      delai: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('evaluations_delai', this.evaluation.idevaluation, this.form.value)
        .subscribe(
          arg => {
            this.getEvaluation(this.id);
            this.modal.dismissAll();
            this.submitted = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
