import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-directeur',
  templateUrl: './directeur.component.html',
  styleUrls: ['./directeur.component.css']
})
export class DirecteurComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  toggle: boolean;

  constructor(
    private service: AuthenticationService
  ) { }

  ngOnInit(): void { }

  public onToggleMenu(): void {
    this.toggle = !this.toggle;
  }

  logout(): void {
    this.service.logout();
  }
}
