import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';

@Component({
  selector: 'app-seances-lecons-titres',
  templateUrl: './seances-lecons-titres.component.html',
  styleUrls: ['./seances-lecons-titres.component.css']
})
export class SeancesLeconsTitresComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() id: string;
  cahiers: Suivis[];
  loading = true;
  errorMsg = '';

  constructor(
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.getDetail(this.id);
  }

  getDetail(id: string): void {
    this.subscription.add(
      this.service.views('cahier_details', id)
        .subscribe(
          (arg: Suivis[]) => {
            this.cahiers = arg;
            this.loading = false;
          },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
