import { Component, OnInit, OnDestroy } from '@angular/core';
import { Administration } from '../administration';
import { AdministrationService } from '../administration.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-administration-home',
  templateUrl: './administration-home.component.html',
  styleUrls: ['./administration-home.component.css']
})
export class AdministrationHomeComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  eleves = 0;
  ecoles = 0;
  loading = true;
  suscription = new Subscription();

  constructor(private service: AdministrationService) { }

  ngOnInit(): void {
    this.getEcoles();
    this.getEleves();
  }

  getEcoles(): void {
    this.suscription.add(this.service.list('ecoles')
      .subscribe(arg => {
        this.ecoles = arg.length;
        this.loading = false;
      }));
  }

  getEleves(): void {
    this.suscription.add(this.service.list('eleves')
      .subscribe(arg => {
        this.eleves = arg.length;
        this.loading = false;
      }));
  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

}
