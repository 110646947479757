<h1>Séances de cours</h1>
<div *ngIf="seance">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" href="#">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/seances">Séances</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/cahiers-texte/seances/{{seance.classe}}/{{seance.matiere}}">{{seance.classe_nom}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled" tabindex="-1" aria-disabled="true"> {{seance.matiere_nom}} du {{seance.debut | date}} </a>
        </li>
    </ul>
    <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
    <div class="recherche">
        <div class="row">
            <div class="col-lg-7">
                <h4> {{seance.debut | date:'fullDate'}} <br>
                    <small>{{seance.debut | date:'shortTime'}}-{{seance.fin | date:'shortTime'}}</small>
                </h4>
            </div>
            <div class="col-lg-5">
                <h4>
                    {{seance.classe_nom}}
                    <br> <small> {{seance.matiere_nom}} </small>
                </h4>

            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col">Nom et prenoms</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let eleve of eleves">
                    <td>
                        <div class="form-group">
                            <div class="custom-control form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="present" [checked]="eleve.presence == 1" (change)="onAssiduite(eleve)">
                                <label class="form-check-label"> {{eleve.nom}} {{eleve.prenoms}} </label>
                            </div>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

    <p class="text-center">
        <button class="button btn-sm" (click)="onProfpresent(seance)" *ngIf="eleves">Termimner l'appel numérique</button> <br>
        <!-- <a routerLink="/{{user.role}}seances/view/{{seance.idseance}}">Ignorer l'appel numérique</a> -->
    </p>
</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
