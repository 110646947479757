import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationComponent } from './administration.component';
import { AdministrationHomeComponent } from './administration-home/administration-home.component';
import { AdministrationEcolesComponent } from './administration-ecoles/administration-ecoles.component';
import { AdministrationEcoleComponent } from './administration-ecole/administration-ecole.component';
import { AdministrationServicesComponent } from './services/administration-services/administration-services.component';
import { AdministrationServiceComponent } from './services/administration-service/administration-service.component';
import { AdministrationServiceDetailComponent } from './services/administration-service-detail/administration-service-detail.component';

const routes: Routes = [
  {
    path: '',
    component: AdministrationComponent,
    children: [
      { path: 'home', component: AdministrationHomeComponent },
      { path: 'ecoles', component: AdministrationEcolesComponent },
      { path: 'ecoles/:id', component: AdministrationEcoleComponent },
      { path: 'services/:id', component: AdministrationServicesComponent },
      {
        path: 'services/:id/:s',
        component: AdministrationServiceComponent,
        children: [
          { path: ':f', component: AdministrationServiceDetailComponent }
        ]
      },
      {
        path: 'evaluations',
        loadChildren: () => import('../suivis/evaluations/evaluations.module').then(m => m.EvaluationsModule)
      },
      { path: 'seances', loadChildren: () => import('../suivis/seances/seances.module').then(m => m.SeancesModule) },

      { path: 'absences', loadChildren: () => import('../suivis/absences/absences.module').then(m => m.AbsencesModule) },
      { path: 'echeanciers', loadChildren: () => import('./echeanciers/echeanciers.module').then(m => m.EcheanciersModule) },
      { path: 'progressions', loadChildren: () => import('./progressions/progressions.module').then(m => m.ProgressionsModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
