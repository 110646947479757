import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuivisComponent } from './suivis.component';

const routes: Routes = [
  { path: '', component: SuivisComponent },
  {
    path: 'notes',
    loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule)
  },
  { path: 'sanctions', loadChildren: () => import('./sanctions/sanctions.module').then(m => m.SanctionsModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuivisRoutingModule { }
