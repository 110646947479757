import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-zones-chauffeur',
  templateUrl: './zones-chauffeur.component.html',
  styleUrls: ['./zones-chauffeur.component.css']
})
export class ZonesChauffeurComponent implements OnInit, OnDestroy {
  @Input() id: string;
  subscription: Subscription = new Subscription();
  zone: Logistiques;
  encadreurs: Logistiques[];
  form: FormGroup;
  submitted = false;
  errorMg = '';
  errorLoading = '';
  loading = true;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getZone(this.id);
    this.getEncadreurs();
    this.createForm();
  }

  getZone(id: string): void {
    this.subscription.add(
      this.service.view('zones_chauffeur', id)
        .subscribe(arg => {
          this.zone = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          }));
  }

  getEncadreurs(): void {
    this.subscription.add(this.service.list('encadreurs')
      .subscribe(arg => {
        this.encadreurs = arg;
        this.loading = false;
      },
        erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        }));
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      chauffeur: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('zones_chauffeur', this.id, this.form.value)
        .subscribe(arg => {
          this.submitted = false;
          this.getZone(this.id);
          this.modal.dismissAll();
        },
          erg => {
            this.errorMg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
