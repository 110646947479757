<h1>Services scolaires</h1>
<div *ngIf="rubrique">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/activites">Activités</a>
        </li>

        <li class="nav-item">
            <a class="nav-link disabled">{{rubrique.nom}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-6">
                <h2>{{rubrique.nom}} </h2>
            </div>
            <div class="col-md-6">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <input type="date" class="form-control">
                    </div>
                    <div class="form-group col-md-6">
                        <input type="date" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <button class="button">Recherche</button>
                </div>
            </div>
        </div>

    </div>
    <div class="recherche" *ngIf="nZones > 0">
        <h6 class="text-center">Zones</h6>
        <div class="row">
            <div class="col-md-3" *ngFor="let z of zones">
                <p> <a routerLink=""> {{z.zone}} </a> </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
            <p *ngIf="collection"> {{collection}} enrégistrements </p>
        </div>
        <div class="col-md-5">
            <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize" class="float-right">
                <ng-template ngbPaginationFirst>Premier</ng-template>
                <ng-template ngbPaginationLast>Dernier</ng-template>
                <ng-template ngbPaginationPrevious>Précédent</ng-template>
                <ng-template ngbPaginationNext>Suivant</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
        </div>
    </div>

    <div class="table-responsive" *ngIf="rubrique; else noFrais">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Heure</th>
                    <th scope="col">Groupe</th>
                    <th scope="col">Activité</th>
                    <th scope="col">Encadreur</th>
                    <th scope="col">GPS</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of activites | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <td> {{a.activite_date | date}} </td>
                    <td> {{a.activite_heure}} </td>
                    <td> {{a.designation}} </td>
                    <td> {{a.activite}} {{a.action}} </td>
                    <td> {{a.encadreur}}</td>
                    <td> {{a.lat}},{{a.lng}}</td>
                    <td> <a href="/{{user.role}}/activites/view/{{a.idactivite}}">Voir plus</a> </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize" class="float-right">
        <ng-template ngbPaginationFirst>Premier</ng-template>
        <ng-template ngbPaginationLast>Dernier</ng-template>
        <ng-template ngbPaginationPrevious>Précédent</ng-template>
        <ng-template ngbPaginationNext>Suivant</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>

    <ng-template #noFrais>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
