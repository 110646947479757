import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';

@Component({
  selector: 'app-notes-classe',
  templateUrl: './notes-classe.component.html',
  styleUrls: ['./notes-classe.component.css']
})
export class NotesClasseComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  classe: Suivis;
  detail: Suivis;
  eleves: Suivis[];
  bilans: Suivis[];
  matiere: string;
  evaluations: Suivis[] = [];

  ecole: Suivis;
  id: string;
  e: Suivis;
  noteBilan: Suivis;
  loading = true;
  errorMsg = '';
  matieres = [];
  periodes = ['1er Trimestre', '2e Trimestre', '3e Trimestre', '1er Semestre', '2e Semestre'];
  submitted = false;
  periode: string = '2e Trimestre';


  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.matiere = this.ar.snapshot.paramMap.get('matiere');
    this.periode = this.ar.snapshot.paramMap.get('periode');

    this.getClasse(this.id, this.matiere);
    this.getEleves();
    this.getEvaluations(this.id, this.matiere, this.periode);
    this.getBilan(this.id, this.matiere, this.periode);
    this.getSousMatieres(this.matiere);
    this.getEcole();
  }

  getSousMatieres(matiere: string): void {
    if (matiere === 'FR') {
      this.matieres = ['ORTHO', 'CF', 'EXPR', 'FR'];
    } else if (matiere === 'EMR') {
      this.matieres = ['FIQ', 'AL-AQIDAH', 'AS-SIRAH', 'AL-AKHLÂQ', 'MEMO'];
    } else {
      this.matieres = [matiere];
    }
  }
  getClasse(id: string, matiere: string): void {
    this.subscription.add(
      this.service.deuxArg('classe_prof', id, matiere)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  getEleves(): void {
    if (this.matiere === 'ALL' || this.matiere === 'ESP') {
      this.subscription.add(
        this.service.deuxArgs('eleves_classe', this.id, this.matiere)
          .subscribe(arg => {
            this.eleves = arg;
            this.loading = false;
          },
            (erg: never) => {
              this.errorMsg = erg;
              this.loading = false;
            })
      );
    } else {
      this.subscription.add(
        this.service.views('eleves_classe', this.id)
          .subscribe(arg => {
            this.eleves = arg;
            this.loading = false;
          },
            (erg: never) => {
              this.errorMsg = erg;
              this.loading = false;
            })
      );
    }



  }

  getBilan(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`notes_classe?classe=${id}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.bilans = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  /*getEleves(id: string, matiere: string): void {
    this.subscription.add(
      this.service.list(`notes_classe?classe=${id}&matiere=${matiere}&groupe=eleve&periode=1er Trimestre`)
        .subscribe(arg => {
          this.eleves = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }*/
  getEvaluations(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`evaluations_classe_executee?classe=${id}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.evaluations = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getEcole(): void {
    this.subscription.add(
      this.service.one('ecole')
        .subscribe((arg: Suivis) => {
          this.ecole = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  print(): void {
    window.print();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
