import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-convoyeur',
  templateUrl: './convoyeur.component.html',
  styleUrls: ['./convoyeur.component.css']
})
export class ConvoyeurComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  toggle: boolean;

  constructor(
    private service: AuthenticationService
  ) { }

  ngOnInit(): void { console.log(this.toggle); }

  onToggleMenu(): boolean {
    return !this.toggle;
  }

  back(): void {
    history.back();
  }

  logout(): void {
    this.service.logout();
  }

}
