import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, of, EMPTY } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-zones-view',
  templateUrl: './zones-view.component.html',
  styleUrls: ['./zones-view.component.css']
})
export class ZonesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  form: FormGroup;
  zone: Logistiques;
  sexes = ['M', 'F'];
  classes: Logistiques[];
  abonnes: Logistiques[];
  points: Logistiques[];
  collection = 0;
  errorLoading = '';
  loading = true;
  submitted = false;
  errorMsg = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getZone(this.id);
    this.getAbonnes(this.id);
    this.getPoints(this.id);
    this.getClasses();
    this.createForm();
  }
  getZone(id: string): void {
    this.subscription.add(this.service.view('zones', id)
      .subscribe(arg => {
        this.zone = arg;
        this.loading = false;
      },
        erg => {
          this.errorLoading = erg;
          this.loading = false;
        }));
  }
  getAbonnes(id: string): void {
    this.subscription.add(this.service.views('zones_abonnes', id)
      .subscribe(arg => {
        this.abonnes = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        erg => {
          this.errorLoading = erg;
          this.loading = false;
        }));

  }

  getPoints(id: string): void {
    this.subscription.add(
      this.service.views('points_arret', id)
        .subscribe(arg => {
          this.points = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          }));
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe(arg => {
          this.classes = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          }));
  }

  onRechercheNom(search): void {
    this.subscription.add(
      this.service.deuxArgs('zones_nom_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  onRechercheClasse(search): void {
    this.subscription.add(
      this.service.deuxArgs('zones_classe_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  onRechercheSexe(search): void {
    this.subscription.add(
      this.service.deuxArgs('zones_sexe_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  onRechercheService(search): void {
    this.subscription.add(
      this.service.deuxArgs('zones_service_search', this.id, search.target.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }
  createForm(): void {
    this.form = this.fb.group({
      zone: [this.id, Validators.required],
      service: [''],
      classe: [''],
      sexe: ['']
    });
  }

  onSubmit(): void {
    this.subscription.add(
      this.service.search('zones_service_search', this.form.value)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  print(): void {
    window.print();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
