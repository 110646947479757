import { Component, OnInit, OnDestroy } from '@angular/core';
import { Suivis } from '../suivis';
import { SuivisService } from '../suivis.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  classes: Suivis[] = [];
  classesSearch: Suivis[] = [];
  matieres: Suivis[] = [];
  profs: Suivis[] = [];
  seances: Suivis[] = [];
  seance: Suivis;
  loading = true;
  errorMsg = '';
  submitted = false;

  collection = 0;
  page = 1;
  pageSize = 10;
  maxSize = 3;

  constructor(
    private service: SuivisService
  ) { }


  ngOnInit(): void {
    this.getClasses();
    this.getProfesseurs();
    this.getMatieres();
    this.getClassesSearch();
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMatieres(): void {
    this.subscription.add(
      this.service.list('matieres')
        .subscribe((arg: Suivis[]) => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClassesSearch(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe((arg: Suivis[]) => {
          this.classesSearch = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getProfesseurs(): void {
    this.subscription.add(
      this.service.list('professeurs')
        .subscribe((arg: Suivis[]) => {
          this.profs = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    const classe = data.classe ? `classe=${data.classe}&` : '';
    const matiere = data.matiere ? `matiere=${data.matiere}&` : '';
    const prof = data.prof ? `prof=${data.prof}&` : '';

    this.subscription.add(
      this.service.list('classes_details?' + classe + matiere + prof)
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
