<h1>Activités scolaires</h1>
<div *ngIf="activite">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/activites">Activités</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/activites/produits/{{activite.service}}">{{activite.service}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{activite.activite}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-6">
                <h2>{{activite.activite}} <small>{{activite.action}}</small> </h2>
            </div>
            <div class="col-md-6">
                <div class="form-row">
                    <div class="form-group col-md-7">
                        <label for="nom">Nom ou prenoms</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="form-group col-md-5">
                        <label for="nom">Classes</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="form-row">

                    <div class="form-group col-md-6">
                        <label for="nom">Sexe</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <button class="button">Recherche</button>
                </div>
            </div>
        </div>

    </div>
    <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
    <div class="row">
        <div class="col-md-7">
            <p *ngIf="collection"> {{collection}} enrégistrements </p>
        </div>
        <div class="col-md-5">
            <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize" class="float-right">
                <ng-template ngbPaginationFirst>Premier</ng-template>
                <ng-template ngbPaginationLast>Dernier</ng-template>
                <ng-template ngbPaginationPrevious>Précédent</ng-template>
                <ng-template ngbPaginationNext>Suivant</ng-template>
                <ng-template ngbPaginationEllipsis>...</ng-template>
                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
        </div>
    </div>

    <div class="table-responsive" *ngIf="abonnes; else noAbonnes">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Souscripteur</th>
                    <th scope="col">Sexe</th>
                    <th scope="col">Classe</th>
                    <th scope="col">Presence</th>
                    <th scope="col">Date de validation</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of abonnes">
                    <td> {{a.nom}} {{a.prenoms}} </td>
                    <td> {{a.sexe}} </td>
                    <td>
                        <app-activites-classe [id]="a.idclasse"></app-activites-classe>
                    </td>
                    <td [ngClass]="{'text-success': a.present == 1, 'text-danger': a.present == 0}"> {{a.present == 1 ? 'Present':'Absent' }} </td>
                    <td>{{a.validation ? (a.validation | date:'medium') : (activite.activite_date |date) +' à '+ activite.activite_heure }} </td>
                    <td> <a routerLink="/{{user.role}}/souscripteurs/view/{{a.idabonne}}">Voir details</a> </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" [maxSize]="5" *ngIf="collection > pageSize" class="float-right">
        <ng-template ngbPaginationFirst>Premier</ng-template>
        <ng-template ngbPaginationLast>Dernier</ng-template>
        <ng-template ngbPaginationPrevious>Précédent</ng-template>
        <ng-template ngbPaginationNext>Suivant</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>

    <ng-template #noAbonnes>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>