<h4>Echéanciers</h4>
<div class="table-responsive" *ngIf="frais; else noFrais">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Date limite</th>
                <th scope="col">Libellé</th>
                <th scope="col">Montant</th>
                <th scope="col">Payé</th>
                <th scope="col">Reste à payer</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let f of frais" [ngClass]="{'alert-success': f.datelimite < (today | date:'Y-MM-d') && f.rapayer == 0, 'alert-danger': f.datelimite < (today | date:'Y-MM-d') &&  f.rapayer > 0 }">
                <td> {{f.datelimite | date}} </td>
                <td> {{f.libelle}} </td>
                <td> {{f.montant | currency:'XOF'}} </td>
                <td> {{f.paye | currency:'XOF'}} </td>
                <td> {{f.rapayer | currency:'XOF'}} </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #noFrais>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>
