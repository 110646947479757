import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SouscripteursComponent } from './souscripteurs.component';
import { SouscripteursViewComponent } from './souscripteurs-view/souscripteurs-view.component';

const routes: Routes = [
  { path: '', component: SouscripteursComponent },
  { path: 'view/:id', component: SouscripteursViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SouscripteursRoutingModule { }
