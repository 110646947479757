import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../logistiques';
import { LogistiquesService } from '../logistiques.service';

@Component({
  selector: 'app-activites',
  templateUrl: './activites.component.html',
  styleUrls: ['./activites.component.css']
})
export class ActivitesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  services: Logistiques[];
  loading = true;
  submitted = false;
  errorLoading = '';
  errorMsg = '';
  collection = 0;
  page = 1;
  pageSize = 10;

  constructor(private service: LogistiquesService) { }
  ngOnInit(): void {
    this.getServices();
  }

  getServices(): void {
    this.subscription.add(
      this.service.list('services')
        .subscribe(arg => {
          this.services = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
