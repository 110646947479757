import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-logistiques-produits-abonnes',
  templateUrl: './logistiques-produits-abonnes.component.html',
  styleUrls: ['./logistiques-produits-abonnes.component.css']
})
export class LogistiquesProduitsAbonnesComponent implements OnChanges, OnDestroy {
  @Input() id: string;
  subscription = new Subscription();
  p: Logistiques;
  abonnes = 0;
  loading = true;
  errorLoading = '';


  constructor(
    private service: LogistiquesService
  ) { }

  ngOnChanges(): void {
    this.getProduitAbonnes(this.id);
  }

  getProduitAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('produits_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
