<h1>Cahiers de texte</h1>
<div *ngIf="s">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/cahiers-texte">Cahiers de texte</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/cahiers-texte/seances/{{s.classe}}/{{s.matiere}}">{{s.classe_nom}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled" tabindex="-1" aria-disabled="true">{{s.matiere}} du {{s.debut | date}} </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-lg-7">
                <h4>{{s.classe_nom}}
                    <small> {{s.matiere_nom}} </small>
                </h4>

            </div>
            <div class="col-lg-5">
                <h4> {{s.debut | date:'fullDate'}}
                    <small>{{s.debut | date:'shortTime'}}-{{s.fin | date:'shortTime'}}</small>
                </h4>

            </div>
        </div>
    </div>


    <div *ngFor="let classe of classes">
        <div class="blocage" *ngIf="(seancesNonRenseingees > 0 && !classe.dateLimite) || (seancesNonRenseingees > 0 && classe.dateLimite
        < (today | date: 'Y-MM-dd'))">
            <h4>Cahier de textes vérrouillé</h4>
            <p>Veuillez contacter le directeur des études pour le déblocage</p>
            <p> <a routerLink="/{{user.role}}/seances/view/{{id}}">Retourner </a> </p>
        </div>


        <div *ngIf="(seancesNonRenseingees == 0) || (seancesNonRenseingees > 0 && classe.dateLimite
        >= (today | date: 'Y-MM-dd'))">
            <div class="recherche text-center">
                <div class="row">
                    <div class="col-md-6">
                        <h5>Titre de leçon</h5>
                        <p> <button class="button btn-sm" (click)="onLecons(s)" *ngIf="!lecons">Nouveau titre de leçon</button></p>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="lecons">
                            <div class="form-group">
                                <label for="lecon">Titre de lecon</label>
                                <select class="form-control" formControlName="lecon" id="lecon">
                              <option *ngFor="let l of lecons"  [value]="l.idlecon" > {{l.lecon}} </option>
                        </select>
                            </div>
                            <div class="form-group">
                                <button type="submit" [disabled]="form.invalid || submitted === true">
                    <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6">
                        <h5>Prochain cours</h5>

                        <p *ngIf="s.prochaineDate "> {{ s.prochaineDate | date:'fullDate'}}
                            <span *ngIf="s.prochaineNote"><br> {{s.prochaineNote}} </span>
                            <br>
                            <a class="a" (click)="open(content)">Renseigner la date </a>
                        </p>
                    </div>

                </div>
            </div>

            <div class="container-fluid" *ngIf="cahiers">
                <div class="border" *ngFor="let cahier of cahiers">
                    <div class="row">
                        <div class="col-md-7">
                            <h4>
                                <app-cahiers-textes-lecon [id]="cahier.lecon_id"></app-cahiers-textes-lecon>
                            </h4>
                            <app-cahiers-textes-add-lecon-detail [id]="cahier.idcahier"></app-cahiers-textes-add-lecon-detail>

                        </div>
                        <!--div class="col-md-4 offset-md-1">
                            <div class="recherche">
                                <h5 class="text-center">Résumé de cours </h5>
                                <app-cahiers-textes-fichier [id]="cahier.idcahier"></app-cahiers-textes-fichier>
                            </div>
                        </div-->
                    </div>
                    <br><br>
                    <div class="card">
                        <p> <a (click)="onDeleteCahier(cahier)">supprimer</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>





</div>
<ng-template #content let-c="close" let-d="dismiss ">
    <form #prochain="ngForm" (ngSubmit)="onProchainCours(prochain.value) ">
        <div class="modal-header">
            <h6 class="modal-title" id="modal-basic-title">Prochain cours</h6>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg">{{ errorMsg }} </ngb-alert>
            <div class="form-group">
                <label for="prochainDate">Date</label>
                <input type="date" name="prochaine_date" [(ngModel)]="s.prochaineDate" [value]="s.prochaineDate" class="form-control" id="prochaine_date" ngModel>
            </div>
            <div class="form-group">
                <label for="prochainNote">Observation</label>
                <input type="text" name="prochaine_note" [(ngModel)]="s.prochaineNote" class="form-control" id="prochaine_note" ngModel>
            </div>
            <div class="form-group">
                <button type="submit">Enrégistrer</button>
            </div>
        </div>
    </form>
</ng-template>

<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
