import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SanctionsRoutingModule } from './sanctions-routing.module';
import { SanctionsComponent } from './sanctions.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule, NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SanctionsClasseComponent } from './sanctions-classe/sanctions-classe.component';
import { SanctionsViewComponent } from './sanctions-view/sanctions-view.component';


@NgModule({
  declarations: [
    SanctionsComponent,
    SanctionsClasseComponent,
    SanctionsViewComponent
  ],
  imports: [
    CommonModule,
    SanctionsRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbAlertModule,
    NgbPaginationModule,
    FontAwesomeModule
  ]
})
export class SanctionsModule { }
