import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Suivis } from '../suivis';
import { SuivisService } from '../suivis.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.css']
})
export class EvaluationsComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  submitted = false;
  errorMsg = '';
  form: FormGroup;
  errorType = '';
  evaluation: Suivis[];
  branches: Suivis[];
  types: Suivis[];
  classes: Suivis[];
  matieres: Suivis[];
  loading = true;
  periodes = ['1er Trimestre', '2e Trimestre', '3e Trimestre', '1er Semestre', '2e Semestre', 'Composition', 'Composition finale'];
  mois = ['Septembre', 'Octobre', 'Novembre', 'Decembre', 'Janviver', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
  collection: number;
  page = 1;
  pageSize = 10;
  today: Date = new Date();

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: SuivisService
  ) { }


  ngOnInit(): void {
    this.getEvaluations();
    this.getEvaluationTypes();
    this.getClasses();
    this.getBranches();
    this.getMatieres();
    this.createForm();
  }

  getEvaluations(): void {
    this.subscription.add(
      this.service.list('evaluations')
        .subscribe(arg => {
          this.evaluation = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMatieres(): void {
    this.subscription.add(
      this.service.list('matieres')
        .subscribe(arg => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  getEvaluationTypes(): void {
    this.subscription.add(
      this.service.list('evaluations_types')
        .subscribe(arg => {
          this.types = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getNiveaux(): void {
    this.subscription.add(
      this.service.list('classes_evaluation')
        .subscribe(arg => {
          this.branches = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe(arg => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getBranches(): void {
    this.subscription.add(
      this.service.list('branches')
        .subscribe(arg => {
          this.branches = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getSousMatieres(matiere: string): string[] {
    if (matiere === 'FR') {
      return ['ORTHO', 'CF', 'EXPR'];
    } else if (matiere === 'EMR') {
      return ['FIQ', 'AL-AQIDAH', 'AS-SIRAH', 'AL-AKHLÂQ', 'MEMO'];
    } else {
      return null;
    }
  }

  open(c): void {
    this.modal.open(c);
  }


  createForm(): void {
    this.form = this.fb.group({
      type: ['', Validators.required],
      classe: ['', Validators.required],
      note: ['', Validators.required],
      matiere: ['', Validators.required],
      jour: ['', Validators.required],
      periode: ['', Validators.required],
      duree: ['', Validators.required],
      mois: [''],
      executee: [''],
      heure: [''],
      sous_matiere: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    console.log(this.form.value);
    this.subscription.add(
      this.service.add('evaluations', this.form.value)
        .subscribe(
          arg => {
            this.getEvaluations();
            this.form.reset();
            this.modal.dismissAll();
            this.submitted = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }
  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    const classe = data.classe ? `classe=${data.classe}&` : '';
    const matiere = data.matiere ? `matiere=${data.matiere}&` : '';
    const type = data.type ? `type=${data.type}&` : '';
    const periode = data.periode ? `periode=${data.periode}&` : '';
    const numero = data.id ? `id=${data.id}&` : '';
    const ordre = data.ordre ? `ordre=${data.ordre}&` : '';
    const limite = data.limite ? `limite=${data.limite}&` : '';
    const debut = data.debut ? `debut=${data.debut}&fin=${data.fin}` : '';

    this.subscription.add(
      this.service.list('evaluations?' + classe + matiere + type + periode + ordre + numero + limite + debut)
        .subscribe((arg: Suivis[]) => {
          this.evaluation = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
