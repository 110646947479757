<h1>Souscripteurs</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Souscripteurs </a>
    </li>
</ul>

<div class="recherche">
    <div class="row">
        <div class="col-md-7">
            <h2>Services scolaires <small>{{collection | number}} souscripteurs</small> </h2>
            <p> <button (click)="print()" class="button">Imprimer</button> </p>
        </div>
        <div class="col-md-5">
            <h5 class="no-print">Rechercher</h5>
            <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
                <div class="form-group">
                    <label for="recherche">Produit</label>
                    <select class="form-control" name="produit" ngModel>
                      <option *ngFor="let p of points" [value]="p.iddetail">{{p.designation}}</option>
                    </select>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-7">
                        <label for="classe">Classe</label>
                        <select class="form-control" name="classe" ngModel>

                            <option *ngFor="let c of classes" [value]="c.uid">{{c.nom}}</option>
                          </select>
                    </div>
                    <div class="form-group col-md-5">
                        <label for="sexe">Sexe</label>
                        <select name="sexe" class="form-control" ngModel>

                      <option *ngFor="let s of sexes" [value]="s">{{s}}</option>
                    </select>
                    </div>
                </div>
                <div class="form-group">
                    <button class="button">Rechercher</button>
                </div>
            </form>


        </div>
    </div>
</div>

<div class="table-responsive" *ngIf="abonnes; else noAbonnes">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Souscripteur</th>
                <th scope="col">Sexe</th>
                <th scope="col">Classe</th>
                <th scope="col">Produit</th>
                <th scope="col">Prix</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of abonnes; let i=index">
                <td> {{i+1}} </td>
                <td>{{a.nom | uppercase }} {{a.prenoms | uppercase}} </td>
                <td>{{a.sexe }} </td>
                <td>
                    <app-souscripteurs-classe [id]="a.classe"></app-souscripteurs-classe>
                </td>
                <td>{{a.designation | uppercase }} </td>
                <td>{{ a.nouveau === 'OUI' ? (a.prix2 | currency:'XOF'): (a.prix | currency:'XOF') }} </td>
                <td>{{a.abonnement_date | date:'medium'}} </td>
                <td> <a routerLink="view/{{a.idabonne}}">Voir detail</a> </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noAbonnes>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
