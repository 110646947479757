<div class="header-mobile"> Vie-ecoles</div>
<div class="body-mobile">
    <div class="container">
        <p>Salut {{user.name}},<br>{{today | date:'medium'}} </p>
        <div class="row">
            <div class="col-md-3">
                <a class="menu-item" routerLink="/{{user.role}}/groupes">Activités</a>
            </div>

            <div class="col-md-3">
                <a class="menu-item" routerLink="/{{user.role}}/historiques"> Historiques</a>
            </div>

            <div class="col-md-3">
                <a class="menu-item" routerLink="/{{user.role}}/parametres">Paramètres</a>
            </div>
        </div>
    </div>
</div>
