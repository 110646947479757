<div *ngIf="e">
    <a class="a" (click)="open(content)">Voir de detail</a>
    <ng-template #content let-c="close" let-d="dismiss">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title"> Nouvel encadreur </h5>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="modal-body">
                <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

                <div class="form-group">
                    <label for="chauffeur">Nom et prenoms</label>
                    <input type="text" formControlName="encadreur" class="form-control" id="encadreur" [(ngModel)]="e.encadreur">
                </div>
                <div class="form-row">
                    <div class="form-group col-md-7">
                        <label for="chauffeur">Rôle</label>
                        <select formControlName="role" class="form-control" id="role">
                      <option *ngFor="let r of roles" [value]="r">{{r | uppercase}}</option>
                    </select>
                    </div>
                    <div class="form-group col-md-5">
                        <label for="mobile">Téléphone</label>
                        <input type="tel" formControlName="mobile" class="form-control" id="mobile" [(ngModel)]="e.mobile">
                    </div>
                </div>
                <div class="form-group">
                    <label for="pin">Code PIN</label>
                    <input type="password" formControlName="pin" class="form-control" id="pin" maxlength="4" [(ngModel)]="e.pin">
                </div>
                <div class="form-group">
                    <label for="piece">Pièce d'identité</label>
                    <input type="text" formControlName="piece" class="form-control" id="piece" [(ngModel)]="e.piece">
                </div>


                <div class="form-group">
                    <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
                </div>
            </div>
        </form>
    </ng-template>
</div>
