import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdministrationService } from '../../administration.service';
import { Administration } from '../../administration';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-administration-services',
  templateUrl: './administration-services.component.html',
  styleUrls: ['./administration-services.component.css']
})
export class AdministrationServicesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  ecole: string;
  form: FormGroup;
  services: Administration[];
  collection = 0;
  loading = true;
  submitted = false;
  errorMsg = '';
  loadError = '';
  souscription = new Subscription();


  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private modalService: NgbModal,
    private service: AdministrationService
  ) { }

  ngOnInit(): void {
    this.ecole = this.ar.snapshot.params.id;
    this.getServices(this.ecole);
    this.createForm();
  }

  getServices(id: string): void {
    this.souscription.add(this.service.views('services', id)
      .subscribe(arg => {
        this.services = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        erg => {
          this.loading = false;
          this.loadError = 'Impossible de charger les données : ' + erg.error.message;
        })
    );
  }


  open(content): void {
    this.modalService.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      ecole: [this.ecole, Validators.required],
      service: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.souscription.add(
      this.service.add('services', this.form.value)
        .subscribe(
          arg => {
            this.getServices(this.ecole);
            this.form.reset();
            this.submitted = false;
            this.modalService.dismissAll();
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.souscription.unsubscribe();
  }


}
