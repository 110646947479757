import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConvoyeurComponent } from './convoyeur.component';
import { ConvoyeurHomeComponent } from './convoyeur-home/convoyeur-home.component';
import { LoginGuard } from '../guards/login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard],
    component: ConvoyeurComponent,
    children: [
      { path: 'home', component: ConvoyeurHomeComponent },
      { path: 'groupes', loadChildren: () => import('./groupes/groupes.module').then(m => m.GroupesModule) },
      { path: 'parametres', loadChildren: () => import('./parametres/parametres.module').then(m => m.ParametresModule) },
      { path: 'historiques', loadChildren: () => import('./historiques/historiques.module').then(m => m.HistoriquesModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConvoyeurRoutingModule { }
