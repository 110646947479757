<div *ngIf="note">
    <span>
        <input type="number" (focusout)="onNoter({eleve: eleve, evaluation:evaluation.idevaluation, note: $event})"
            [max]="evaluation.notation" min="0" [style.width]="'50px'" [ngClass]="{'border-error': errorType}"
            [(ngModel)]="note.note"
            [disabled]="(user.role ==='professeur' && evaluation.delaiNote < jour)"><strong>/{{evaluation.notation}}</strong>
    </span>
    <span>{{ appreciation(note.note,evaluation.notation) }}</span>
</div>

<span *ngIf="!note">
    <input type="number" (focusout)="onNoter({eleve: eleve, evaluation:evaluation.idevaluation, note: $event})"
        [max]="evaluation.notation" min="0" [style.width]="'50px'" [ngClass]="{'border-error': errorType}"
        [disabled]="(user.role ==='professeur' && evaluation.delaiNote < jour)"><strong>/{{evaluation.notation}}</strong>
</span>