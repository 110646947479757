<p class="text-center">
    <button class="button" (click)="open(content)">Envoyer un message aux parents</button>
</p>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Envoi de message</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
            <div class="form-group">
                <label for="sms">Message</label>
                <textarea formControlName="message" class="form-control" id="message" rows="4"></textarea>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>
