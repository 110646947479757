import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../convoyeur';
import { ConvoyeurService } from '../convoyeur.service';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.css']
})
export class ParametresComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  u: Convoyeur;
  submitted = false;
  loading = true;
  errorMsg = '';


  constructor(
    private service: ConvoyeurService,
    private author: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.subscription.add(
      this.service.one()
        .subscribe(arg => {
          this.u = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  logout(): void {
    this.author.logout();
  }

  back(): void {
    history.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
