<h5> <u>Chauffeur</u> </h5>
<div *ngIf="v; else noV">
    <p *ngIf="v.chauffeur; else noChauffeur">
        <strong> {{v.chauffeur}}</strong>
        <br>Tel.: {{v.chauffeur_mobile}}
        <br> <a class="a" (click)="open(content)">Modifier</a>
    </p>

    <ng-template #noChauffeur>
        <a class="a" (click)="open(content)">Ajouter</a>
    </ng-template>
</div>

<ng-template #noV>
    <p class="errorLoading"> {{errorLoading}} </p>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Ajouter un chauffeur
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="chauffeur">Nom et prenoms</label>
                <input type="text" formControlName="chauffeur" class="form-control" id="chauffeur" [(ngModel)]="v.chauffeur">
            </div>
            <div class="form-group">
                <label for="chauffeur_mobile">Téléphone</label>
                <input type="tel" formControlName="mobile" class="form-control" id="mobile" [(ngModel)]="v.chauffeur_mobile">
            </div>

            <div class="form-group">
                <label for="chauffeur_permis">Permis de conduire du chauffeur</label>
                <input type="text" formControlName="permis" class="form-control" id="permis" [(ngModel)]="v.chauffeur_permis">
            </div>

            <div class="form-group">
                <label for="piece">Pièce d'identité du chauffeur</label>
                <input type="tel" formControlName="piece" class="form-control" id="piece" [(ngModel)]="v.chauffeur_piece">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



            </div>
        </div>
    </form>
</ng-template>