import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cahiers-textes-lecon-details',
  templateUrl: './cahiers-textes-lecon-details.component.html',
  styleUrls: ['./cahiers-textes-lecon-details.component.css']
})
export class CahiersTextesLeconDetailsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() id: string;
  cahiers: Suivis[];
  loading = true;
  errorMsg = '';

  constructor(
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.getDetail(this.id);
  }

  getDetail(id: string): void {
    this.subscription.add(
      this.service.views('cahier_details', id)
        .subscribe(
          (arg: Suivis[]) => {
            this.cahiers = arg;
            this.loading = false;
          },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
