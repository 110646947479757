import { HttpParams, HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Suivis } from './suivis';

@Injectable({
  providedIn: 'root'
})
export class SuivisService {

  private readonly API_URL = 'https://staff.vie-ecoles.com/server/suivis/';
  options = { params: new HttpParams({ fromString: 'token=' + JSON.parse(localStorage.getItem('user')).token }) };

  constructor(private http: HttpClient) { }

  add(table: string, body: any): Observable<string> {
    return this.http.post<string>(this.API_URL + table, body, this.options)
      .pipe(catchError(this.handleError));
  }

  put(table: string, body: Suivis): Observable<string> {
    return this.http.put<string>(this.API_URL + table, body, this.options)
      .pipe(catchError(this.handleError));
  }

  update(table: string, id: string, body: Suivis): Observable<string> {
    return this.http.put<string>(this.API_URL + table + '/' + id, body, this.options)
      .pipe(catchError(this.handleError));
  }

  delete(table: string, id: string, id2: string = null): Observable<string> {
    if (id2) {
      return this.http.delete<string>(this.API_URL + table + '/' + id + '/' + id2, this.options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.delete<string>(this.API_URL + table + '/' + id, this.options)
        .pipe(catchError(this.handleError));
    }

  }

  list(table: string): Observable<Suivis[]> {
    return this.http.get<Suivis[]>(this.API_URL + table, this.options)
      .pipe(
        shareReplay(1),
        catchError(this.handleError));
  }

  view(table: string, id: string = null): Observable<Suivis> {
    if (id) {
      return this.http.get<Suivis>(this.API_URL + table + '/' + id, this.options)
        .pipe(
          shareReplay(1),
          catchError(this.handleError)
        );
    } else {
      return this.http.get<Suivis>(this.API_URL + table, this.options)
        .pipe(shareReplay(1),
          catchError(this.handleError));
    }
  }

  upload(table: string, body: FormData): Observable<HttpEvent<any>> {
    return this.http.post(this.API_URL + table, body, { observe: 'events', reportProgress: true })
      .pipe(catchError(this.handleError));
  }

  views(table: string, id: string): Observable<Suivis[]> {
    return this.http.get<Suivis[]>(this.API_URL + table + '/' + id, this.options)
      .pipe(shareReplay(1), catchError(this.handleError));
  }
  one(table: string = null): Observable<Suivis> {
    if (table) {
      return this.http.get<Suivis>(this.API_URL + table, this.options)
        .pipe(shareReplay(1), catchError(this.handleError));
    } else {
      return this.http.get<Suivis>(this.API_URL, this.options)
        .pipe(shareReplay(1), catchError(this.handleError));
    }
  }

  deuxArg(table: string, id: string, id2: string): Observable<Suivis> {
    return this.http.get<Suivis>(this.API_URL + table + '/' + id + '/' + id2, this.options)
      .pipe(shareReplay(1),
        catchError(this.handleError));
  }

  deuxArgs(table: string, id: string, id2: string): Observable<Suivis[]> {
    return this.http.get<Suivis[]>(this.API_URL + table + '/' + id + '/' + id2, this.options)
      .pipe(shareReplay(1), catchError(this.handleError));
  }

  troisArg(table: string, id: string, id2: string, id3: string): Observable<Suivis> {
    return this.http.get<Suivis>(this.API_URL + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.handleError));
  }

  troisArgs(table: string, id: string, id2: string, id3: string): Observable<Suivis[]> {
    return this.http.get<Suivis[]>(this.API_URL + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(shareReplay(1), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let response = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      response = `Une erreur s'est produite: ${error.error}`;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error.message);
      response = ` ${error.error.message}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(response);
  }
}
