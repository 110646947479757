import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/suivis';
import { SuivisService } from 'src/app/suivis/suivis.service';

@Component({
  selector: 'app-notes-moyennes-eleve',
  templateUrl: './notes-moyennes-eleve.component.html',
  styleUrls: ['./notes-moyennes-eleve.component.css']
})
export class NotesMoyennesEleveComponent implements OnChanges, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  moyennes: Suivis[];
  @Input() matiere: string;
  @Input() eleve: Suivis;
  loading = true;
  submitted = false;
  errorMsg = '';
  errorType = '';

  constructor(private service: SuivisService) { }

  ngOnChanges(): void {
    this.getMoyennes();
  }


  getMoyennes(): void {
    this.subscription.add(
      this.service.list(`moyennes_classe?eleve=${this.eleve.ideleve}&matiere=${this.matiere}&periode=1er Trimestre`)
        .subscribe(arg => {
          this.moyennes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
