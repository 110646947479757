<div class="header-mobile">
    Historiques
    <a (click)="back()"><i class="bi bi-chevron-compact-left"></i></a>
</div>
<div class="body-mobile">
    <div class="container">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p> {{collection}} activités </p>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label>Rechercher une période d'activité</label>
                </div>
                <div class="form-group col-md-3">
                    <input type="date" formControlName="debut" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <input type="date" formControlName="fin" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <button type="submit" class="btn">Rechercher</button>
                </div>
            </div>
        </form>

        <div class="table-responsive table-responsive-sm" *ngIf="activites">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Heure</th>
                        <th scope="col">Point d'arrêt/Groupe </th>
                        <th scope="col">Activité</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let e of activites">
                        <td> {{e.activite_date | date}} </td>
                        <td> {{e.activite_heure}}</td>
                        <td> {{e.designation}} </td>
                        <td> {{e.activite}} / {{e.action}}</td>

                        <td> <a routerLink="view/{{e.idactivite}}">Voir detail</a> </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
