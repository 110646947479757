import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-zones-sms',
  templateUrl: './zones-sms.component.html',
  styleUrls: ['./zones-sms.component.css']
})
export class ZonesSmsComponent implements OnInit, OnDestroy {
  @Input() id: string;
  subscription: Subscription = new Subscription();
  zone: Logistiques;
  encadreurs: Logistiques[];
  form: FormGroup;
  submitted = false;
  errorMg = '';
  errorLoading = '';
  loading = true;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.createForm();
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.update('zones_sms', this.id, this.form.value)
        .subscribe(arg => {
          this.submitted = false;
          this.modal.dismissAll();
          Swal.fire(
            'Message envoyé',
            'Votre message a bien été envoyé ',
            'success'
          );
        },
          erg => {
            this.errorMg = erg.error.message;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
