import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/suivis';
import { SuivisService } from 'src/app/suivis/suivis.service';

@Component({
  selector: 'app-note-eleve-evaluation',
  templateUrl: './note-eleve-evaluation.component.html',
  styleUrls: ['./note-eleve-evaluation.component.css']
})
export class NoteEleveEvaluationComponent implements OnChanges, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  @Input() eleve: Suivis;
  @Input() evaluation: Suivis;
  note: Suivis;

  loading = true;
  errorMsg = '';
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: SuivisService) { }

  ngOnChanges(): void {
    this.getNote(this.evaluation.idevaluation, this.eleve.ideleve);
    this.createForm();
  }

  getNote(ev: string, el: string): void {
    this.subscription.add(
      this.service.deuxArg('notes', ev, el)
        .subscribe(arg => {
          this.note = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  open(c): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      note: ['', Validators.required],
      evaluation: [this.evaluation.idevaluation, Validators.required],
      eleve: [this.eleve.ideleve, Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('notes', this.form.value)
        .subscribe(arg => {
          this.getNote(this.evaluation.idevaluation, this.eleve.ideleve);
          this.submitted = false;
          this.modal.dismissAll();
          location.reload();
        },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }



  onNoteUpdate(): void {
    const data = { eleve: this.eleve.ideleve, note: this.note.note, evaluation: this.evaluation.idevaluation };
    this.subscription.add(
      this.service.add('notes', data)
        .subscribe(arg => {
          this.submitted = false;
          this.getNote(this.evaluation.idevaluation, this.eleve.ideleve);
          this.modal.dismissAll();
          location.reload();
        },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }

  onDeleteNote(): void {
    const r = confirm('Vous êtes sûr de la suppression ?, cette action est irrevestible');
    if (r) {
      this.service.delete('notes', this.evaluation.idevaluation, this.eleve.ideleve)
        .subscribe(arg => {
          this.getNote(this.evaluation.idevaluation, this.eleve.ideleve);
          this.modal.dismissAll();
          location.reload();
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
