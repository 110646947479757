<h1>Tableau de bord</h1>
<h5>Bienvenue {{user.name}} </h5>
<p>Voici ce qui se passe aujourd'hui, {{today | date: 'fullDate'}} </p>

<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>

<div class="row" *ngIf="bilan">
  <div class="col-md-4">
    <div class="recherche text-center">
      <h5>Seances de cours</h5>
      <p>{{bilan.seance | number}} Séances </p>
      <p> <a routerLink="/{{user.role}}/seances">Voir detail</a> </p>
    </div>
  </div>

  <div class="col-md-4">
    <div class="recherche text-center">
      <h5>Cahiers de textes</h5>
      <p>{{nonRenseignes | number}} Séances non renseignées </p>
      <p> <a routerLink="/{{user.role}}/cahiers-textes">Voir detail</a> </p>
    </div>
  </div>

  <div class="col-md-4">
    <div class="recherche text-center">
      <h5>Absences des élèves</h5>
      <p> {{ absences | number }} élèves absents</p>
      <p> <a routerLink="/{{user.role}}/absences">Voir detail</a> </p>
    </div>
  </div>
</div>

<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Veuillez patienter ...
</div>
