import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ZonesRoutingModule } from './zones-routing.module';
import { ZonesComponent } from './zones.component';
import { ZonesViewComponent } from './zones-view/zones-view.component';
import { ZonesVehiculeComponent } from './zones-vehicule/zones-vehicule.component';
import { ZonesChauffeurComponent } from './zones-chauffeur/zones-chauffeur.component';
import { ZonesEncadreurComponent } from './zones-encadreur/zones-encadreur.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ZonesSmsComponent } from './zones-sms/zones-sms.component';
import { ZonesClasseComponent } from './zones-classe/zones-classe.component';


@NgModule({
  declarations: [
    ZonesComponent,
    ZonesViewComponent,
    ZonesVehiculeComponent,
    ZonesChauffeurComponent,
    ZonesEncadreurComponent,
    ZonesSmsComponent,
    ZonesClasseComponent
  ],
  imports: [
    CommonModule,
    ZonesRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class ZonesModule { }
