import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogistiquesRoutingModule } from './logistiques-routing.module';
import { LogistiquesComponent } from './logistiques.component';
import { LogistiquesHomeComponent } from './logistiques-home/logistiques-home.component';
import { LogistiquesServicesComponent } from './services/logistiques-services/logistiques-services.component';
import { LogistiquesServiceComponent } from './services/logistiques-services/logistiques-service/logistiques-service.component';
import { LogistiquesProduitsAbonnesComponent } from './services/logistiques-produits-abonnes/logistiques-produits-abonnes.component';
import { LogistiquesProduitsComponent } from './services/logistiques-produits/logistiques-produits.component';
import { LogistiquesSouscripteurComponent } from './services/logistiques-souscripteur/logistiques-souscripteur.component';
import { LogistiquesServicesAbonnesComponent } from './services/logistiques-services-abonnes/logistiques-services-abonnes.component';
import { ParcAutoVehiculeComponent } from './parc-auto/parc-auto-vehicule/parc-auto-vehicule.component';
import { ParcAutoVehiculesComponent } from './parc-auto/parc-auto-vehicules/parc-auto-vehicules.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ParcAutoConvoyeurComponent } from './parc-auto/parc-auto-convoyeur/parc-auto-convoyeur.component';
import { ParcAutoChauffeurComponent } from './parc-auto/parc-auto-chauffeur/parc-auto-chauffeur.component';
import { ParcAutoAssuranceComponent } from './parc-auto/parc-auto-assurance/parc-auto-assurance.component';
import { ParcAutoVisiteComponent } from './parc-auto/parc-auto-visite/parc-auto-visite.component';
import { ParcAutoPatenteComponent } from './parc-auto/parc-auto-patente/parc-auto-patente.component';
import { ParcAutoStationnementComponent } from './parc-auto/parc-auto-stationnement/parc-auto-stationnement.component';
import { ProduitsItinerairesComponent } from './services/logistiques-produits/produits-itineraires/produits-itineraires.component';
import { ProduitsAbonneGroupeComponent } from './services/logistiques-produits/produits-abonne-groupe/produits-abonne-groupe.component';
import { ServicesZonesComponent } from './services/services-zones/services-zones.component';
import { ServicesZoneComponent } from './services/services-zones/services-zone/services-zone.component';
import { ZonesModule } from './zones/zones.module';
import { EncadreursModule } from './encadreurs/encadreurs.module';
import { ProduitsModule } from './produits/produits.module';
import { ActivitesModule } from './activites/activites.module';
import { SouscripteursModule } from './souscripteurs/souscripteurs.module';


@NgModule({
  declarations: [
    LogistiquesComponent,
    LogistiquesHomeComponent,
    ProduitsItinerairesComponent,
    ProduitsAbonneGroupeComponent,
    LogistiquesServicesComponent,
    LogistiquesServiceComponent,
    LogistiquesProduitsAbonnesComponent,
    LogistiquesProduitsComponent,
    LogistiquesSouscripteurComponent,
    LogistiquesServicesAbonnesComponent,
    ParcAutoVehiculeComponent,
    ParcAutoVehiculesComponent,
    ParcAutoConvoyeurComponent,
    ParcAutoChauffeurComponent,
    ParcAutoAssuranceComponent,
    ParcAutoVisiteComponent,
    ParcAutoPatenteComponent,
    ParcAutoStationnementComponent,
    ServicesZonesComponent,
    ServicesZoneComponent
  ],
  imports: [
    CommonModule,
    LogistiquesRoutingModule,
    ZonesModule,
    EncadreursModule,
    ProduitsModule,
    ActivitesModule,
    SouscripteursModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class LogistiquesModule { }
