import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from 'src/app/logistiques/logistiques';
import { LogistiquesService } from 'src/app/logistiques/logistiques.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-produits-itineraires',
  templateUrl: './produits-itineraires.component.html',
  styleUrls: ['./produits-itineraires.component.css']
})
export class ProduitsItinerairesComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() prod: string;
  subscription: Subscription = new Subscription();
  groupes: Logistiques[];
  vehicules: Logistiques[];
  form: FormGroup;
  submitted = false;
  errorMg = '';
  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.getGroupes(this.id);
    this.getVehicules();
    this.createForm();
  }

  getGroupes(id: string): void {
    this.subscription.add(
      this.service.deuxArgs('groupes_produit', this.prod, id)
        .subscribe(arg => {
          this.groupes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }
  getVehicules(): void {
    this.subscription.add(this.service.list('vehicules')
      .subscribe(arg => {
        this.vehicules = arg;
        this.loading = false;
      },
        erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        }));
  }

  open(c: any): void {
    this.modal.open(c);
  }


  createForm(): void {
    this.form = this.fb.group({
      groupe: ['', Validators.required],
      service: [this.prod, Validators.required],
      produit: [this.id, Validators.required],
      car: [''],
      formateur: [''],
      mobile: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('groupes', this.form.value)
        .subscribe(arg => {
          this.getGroupes(this.id);
          this.modal.dismissAll();
          this.submitted = false;
          location.reload();
        },
          erg => {
            this.errorMg = erg.error.message;
            this.submitted = false;
          },
          () => {
            this.submitted = false;
          })
    );

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
