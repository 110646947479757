import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent } from './administration.component';
import { AdministrationHomeComponent } from './administration-home/administration-home.component';
import { EcheanciersModule } from './echeanciers/echeanciers.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AdministrationEcolesComponent } from './administration-ecoles/administration-ecoles.component';
import { AdministrationEcoleComponent } from './administration-ecole/administration-ecole.component';
import { AdministrationServicesComponent } from './services/administration-services/administration-services.component';
import { AdministrationServiceComponent } from './services/administration-service/administration-service.component';
import { AdministrationServiceDetailComponent } from './services/administration-service-detail/administration-service-detail.component';



@NgModule({
  declarations: [
    AdministrationComponent,
    AdministrationHomeComponent,
    AdministrationEcolesComponent,
    AdministrationEcoleComponent,
    AdministrationServicesComponent,
    AdministrationServiceComponent,
    AdministrationServiceDetailComponent
  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    EcheanciersModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbModalModule,
    NgbPaginationModule
  ]
})
export class AdministrationModule { }
