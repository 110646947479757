import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeancesComponent } from './seances.component';
import { SeancesViewComponent } from './seances-view/seances-view.component';
import { SeancesJourComponent } from './seances-jour/seances-jour.component';
import { SeancesAppelComponent } from './seances-appel/seances-appel.component';

const routes: Routes = [
  { path: '', component: SeancesComponent },
  { path: 'jour/:id', component: SeancesJourComponent },
  { path: 'view/:id', component: SeancesViewComponent },
  { path: 'appel/:id/:classe', component: SeancesAppelComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeancesRoutingModule { }
