import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';

@Component({
  selector: 'app-seances-lecons',
  templateUrl: './seances-lecons.component.html',
  styleUrls: ['./seances-lecons.component.css']
})
export class SeancesLeconsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() id: string;
  lecon: Suivis;
  errorMsg = '';

  constructor(
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.getLecon(this.id);
  }

  getLecon(id: string): void {
    this.subscription.add(
      this.service.view('lecons', id)
        .subscribe(
          (arg: Suivis) => {
            this.lecon = arg;
          },
          (erg: never) => {
            this.errorMsg = erg;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
