<h1>Notes et moyennes</h1>
<ul class="nav">
  <li class="nav-item">
    <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled">Notes et moyennes</a>
  </li>
</ul>
<div class="recherche">
  <div class="row">
    <div class="col-md-4">
      <h4>Notes et moyennes</h4>
      <p *ngIf="collection"> {{collection | number}} Classes </p>
    </div>
    <div class="col-md-8" *ngIf="user.role !== 'professeur' ">
      <h5>Recherche</h5>
      <form #search="ngForm" (ngSubmit)="onClickSubmit(search.value)">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="classe">Classe</label>
            <select name="classe" class="form-control" id="classe" ngModel>
              <option *ngFor="let c of classesSearch" [value]="c.idclasse">{{c.classe_nom}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="matiere">Matière</label>
            <select name="matiere" class="form-control" id="matiere" ngModel>
              <option *ngFor="let m of matieres" [value]="m.idmatiere">{{m.matiere_nom}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="professeur">Enseignant</label>
            <select name="prof" class="form-control" id="professeur" ngModel>
              <option *ngFor="let p of profs" [value]="p.idprof">{{p.nom}} {{p.prenoms}} </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <button class="btn-sm" type="submit">Rechercher</button>
        </div>

      </form>
    </div>
  </div>

</div>
<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Classe</th>
        <th scope="col">LV2</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let classe of classes; let i = index">
        <td> {{i+1}} </td>
        <td>{{classe.classe_nom}}</td>
        <td>{{classe.lv2}}</td>
        <td> <a routerLink="matieres/{{classe.idclasse}}">Voir detail</a> </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
