import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/suivis';
import { SuivisService } from 'src/app/suivis/suivis.service';

@Component({
  selector: 'app-notes-classe-global-eleves',
  templateUrl: './notes-classe-global-eleves.component.html',
  styleUrls: ['./notes-classe-global-eleves.component.css']
})
export class NotesClassyGlobalElevesComponent implements OnChanges, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() classe: string;
  @Input() matiere: string;
  eleves: Suivis[];
  loading = true;
  errorMsg = '';

  constructor(private service: SuivisService) { }


  ngOnChanges(): void {
    this.getEleves(this.classe, this.matiere);
  }

  getEleves(id: string, matiere: string): void {
    this.subscription.add(
      this.service.list(`notes_classe?classe=${id}&matiere=${matiere}&groupe=eleve`)
        .subscribe(arg => {
          this.eleves = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
