import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotesRoutingModule } from './notes-routing.module';
import { NotesComponent } from './notes.component';
import { NotesViewComponent } from './notes-view/notes-view.component';
import { NotesAddComponent } from './notes-add/notes-add.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NotesClasseComponent } from './notes-classe/notes-classe.component';
import { NotesNoterComponent } from './notes-noter/notes-noter.component';
import { NotesEleveComponent } from './notes-eleve/notes-eleve.component';
import { NotesClasseGlobalComponent } from './notes-classe-global/notes-classe-global.component';
import { NotesClassyGlobalElevesComponent } from './notes-classe-global/notes-classe-global-eleves/notes-classe-global-eleves.component';
import { NotesMoyennesComponent } from './notes-moyennes/notes-moyennes.component';
import { NotesMoyennesEleveComponent } from './notes-moyennes/notes-moyennes-eleve/notes-moyennes-eleve.component';
import { NoteMatiereEleveComponent } from './notes-classe/note-matiere-eleve/note-matiere-eleve.component';
import { NotesMatieresComponent } from './notes-matieres/notes-matieres.component';
import { NoteEleveEvaluationComponent } from './notes-classe/note-eleve-evaluation/note-eleve-evaluation.component';
import { NoteELeveMoyenneComponent } from './notes-classe/note-eleve-moyenne/note-eleve-moyenne.component';


@NgModule({
  declarations: [
    NotesComponent,
    NotesViewComponent,
    NotesAddComponent,
    NotesClasseComponent,
    NotesNoterComponent,
    NotesEleveComponent,
    NotesClasseGlobalComponent,
    NotesClassyGlobalElevesComponent,
    NotesMoyennesComponent,
    NotesMoyennesEleveComponent,
    NoteMatiereEleveComponent,
    NotesMatieresComponent,
    NoteEleveEvaluationComponent,
    NoteELeveMoyenneComponent
  ],
  imports: [
    CommonModule,
    NotesRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    FontAwesomeModule
  ]
})
export class NotesModule { }
