import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConvoyeurRoutingModule } from './convoyeur-routing.module';
import { ConvoyeurComponent } from './convoyeur.component';
import { ConvoyeurHomeComponent } from './convoyeur-home/convoyeur-home.component';
import { GroupesModule } from './groupes/groupes.module';
import { ParametresModule } from './parametres/parametres.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HistoriquesModule } from './historiques/historiques.module';


@NgModule({
  declarations: [
    ConvoyeurComponent,
    ConvoyeurHomeComponent
  ],
  imports: [
    CommonModule,
    ConvoyeurRoutingModule,
    GroupesModule,
    ParametresModule,
    HistoriquesModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule,
    FontAwesomeModule
  ]
})
export class ConvoyeurModule { }
