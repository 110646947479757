import { Component, OnInit, OnDestroy } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Suivis } from '../Suivis';
import { SuivisService } from '../Suivis.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cahiers-textes',
  templateUrl: './cahiers-textes.component.html',
  styleUrls: ['./cahiers-textes.component.css']
})
export class CahiersTextesComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  cahiers: Suivis[] = [];
  classes: Suivis[] = [];
  matieres: Suivis[] = [];
  profs: Suivis[] = [];
  seances: Suivis[] = [];
  seance: Suivis;
  loading = true;
  errorMsg = '';
  submitted = false;
  faCoffee = faCalendar;
  collection = 0;
  page = 1;
  pageSize = 10;
  maxSize = 3;

  constructor(
    private service: SuivisService
  ) { }


  ngOnInit(): void {
    this.getCahiers();
    this.getClasses();
    this.getMatieres();
    this.getProfesseurs();
    this.getPremiereSeance();
    this.getSeances();
  }

  getCahiers(): void {
    this.subscription.add(
      this.service.list('classes_details')
        .subscribe((arg: Suivis[]) => {
          this.cahiers = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMatieres(): void {
    this.subscription.add(
      this.service.list('matieres')
        .subscribe((arg: Suivis[]) => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getProfesseurs(): void {
    this.subscription.add(
      this.service.list('professeurs')
        .subscribe((arg: Suivis[]) => {
          this.profs = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getSeances(): void {
    this.subscription.add(
      this.service.list('seances_invalides')
        .subscribe((arg: Suivis[]) => {
          this.seances = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getPremiereSeance(): void {
    this.subscription.add(
      this.service.view('seances_invalides_premiere')
        .subscribe((arg: Suivis) => {
          this.seance = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    const classe = data.classe ? `classe=${data.classe}&` : '';
    const matiere = data.matiere ? `matiere=${data.matiere}&` : '';
    const prof = data.prof ? `prof=${data.prof}&` : '';

    this.subscription.add(
      this.service.list('classes_details?' + classe + matiere + prof)
        .subscribe((arg: Suivis[]) => {
          this.cahiers = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
