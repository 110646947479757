import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupesComponent } from './groupes.component';
import { GroupesViewComponent } from './groupes-view/groupes-view.component';

const routes: Routes = [
  { path: '', component: GroupesComponent },
  { path: 'view/:id', component: GroupesViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupesRoutingModule { }
