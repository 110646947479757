import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';

@Component({
  selector: 'app-cahiers-textes-add',
  templateUrl: './cahiers-textes-add.component.html',
  styleUrls: ['./cahiers-textes-add.component.css']
})
export class CahiersTextesAddComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  s: Suivis;
  lecons: Suivis[];
  cahiers: Suivis[];
  classes: Suivis[];
  loading = true;
  submitted = false;
  errorMsg = '';
  form: FormGroup;
  seancesNonRenseingees = 0;
  today: Date = new Date();



  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private service: SuivisService,
    private modal: NgbModal
  ) { }
  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getSeance(this.id);
    this.getCahiers(this.id);
    this.createForm();
  }

  getSeance(id: string): void {
    this.subscription.add(
      this.service.view('seances', id)
        .subscribe((arg: Suivis) => {
          this.s = arg;
          this.getSeancesNonRenseigne(this.s);
          this.getClasse(this.s);
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getClasse(seance: Suivis): void {
    this.subscription.add(
      this.service.list(`classes_details?classe=${seance.idclasse}&matiere=${seance.idmatiere}`)
        .subscribe((arg: Suivis[]) => {
          this.classes = arg;
          this.getSeancesNonRenseigne(this.s);
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getSeancesNonRenseigne(cahier: Suivis): void {
    this.subscription.add(
      this.service.list(`seances?classe=${cahier.idclasse}&matiere=${cahier.idmatiere}&cahier=0&presence=1`)
        .subscribe((arg: Suivis[]) => {
          this.seancesNonRenseingees = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onLecons(doc: Suivis): void {
    this.subscription.add(
      this.service.deuxArgs('lecons_classe', doc.branche, doc.idmatiere)
        .subscribe((arg: Suivis[]) => {
          this.lecons = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getCahiers(id: string): void {
    this.subscription.add(
      this.service.list('cahier_texte?seance=' + id)
        .subscribe((arg: Suivis[]) => {
          this.cahiers = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }


  createForm(): void {
    this.form = this.fb.group({
      seance: [this.id, Validators.required],
      lecon: ['', Validators.required]
    });

  }

  onSubmit(): void {
    this.submitted = true;
    this.service.add('cahier_texte', this.form.value)
      .subscribe((arg: string) => {
        this.getCahiers(this.id);
        this.form.get('lecon').reset();
        this.lecons = null;
        this.submitted = false;
      },
        erg => {
          this.errorMsg = erg;
          this.submitted = false;
        });
  }

  onDeleteCahier(cahier: Suivis): void {
    this.subscription.add(
      this.service.delete('cahier_texte', cahier.idcahier)
        .subscribe(arg => {
          this.getCahiers(this.id);
        },
          (erg: never) => {
            this.errorMsg = erg;
          })
    );
  }

  open(content): void {
    this.modal.open(content);
  }


  onProchainCours(data: Suivis): void {
    this.subscription.add(
      this.service.update('seances_prochain', this.id, data)
        .subscribe((arg: string) => {
          this.getSeance(this.id);
          this.modal.dismissAll();
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onSeanceTerminer(seance: Suivis): void {
    this.subscription.add(
      this.service.update('seances_cahier', seance.idseance, seance)
        .subscribe(arg => {
          Swal.fire(
            'Cahier de texte',
            'Le cahier de texte a bien été renseigné',
            'success'
          );
          this.router.navigate(['/' + this.user.role + '/seances/view/' + this.id]);
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );

  }




  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
