import { Component, HostListener, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-notes-noter',
  templateUrl: './notes-noter.component.html',
  styleUrls: ['./notes-noter.component.css']
})

export class NotesNoterComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  classe: string;
  e: Suivis;
  eleves: Suivis[];
  noteBilan: Suivis;
  loading = true;
  errorMsg = '';
  errorType = '';
  submitted = false;

  faSuccess = faCheck;
  faError = faTimes;


  constructor(
    private el: ElementRef,
    private ar: ActivatedRoute,
    private service: SuivisService) {

  }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.classe = this.ar.snapshot.paramMap.get('classe');
    this.getEvaluation(this.id);
    this.getEleves(this.classe);
  }

  getEvaluation(id: string): void {
    this.subscription.add(
      this.service.view('evaluations', id)
        .subscribe(arg => {
          this.e = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }

  getEleves(id: string): void {
    this.subscription.add(
      this.service.views('eleves_classe', id)
        .subscribe(arg => {
          this.eleves = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onNoter(data): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('notes', {
        eleve: data.eleve,
        note: data.note.target.value,
        evaluation: data.evaluation
      })
        .subscribe(arg => {
          const index = data.input + 1;
          const next = index === this.eleves.length ? 0 : index;
          document.getElementById(`input${next}`).focus();
          this.errorType = 'success';
        },
          erg => {
            this.errorMsg = erg;
            this.errorType = 'error';
            this.submitted = false;
          }
        )
    );

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
