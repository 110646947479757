import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirecteurComponent } from './directeur.component';
import { DirecteurHomeComponent } from './directeur-home/directeur-home.component';
import { LoginGuard } from '../guards/login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard],
    component: DirecteurComponent,
    children: [
      { path: 'home', component: DirecteurHomeComponent },
      {
        path: 'cahiers-textes',
        loadChildren: () => import('../suivis/cahiers-textes/cahiers-textes.module')
          .then(m => m.CahiersTextesModule)
      },
      {
        path: 'seances',
        loadChildren: () => import('../suivis/seances/seances.module').then(m => m.SeancesModule)
      },
      { path: 'evaluations', loadChildren: () => import('../suivis/evaluations/evaluations.module').then(m => m.EvaluationsModule) },

      { path: 'absences', loadChildren: () => import('../suivis/absences/absences.module').then(m => m.AbsencesModule) },
      {
        path: 'echeanciers',
        loadChildren: () => import('../administration/echeanciers/echeanciers.module').then(m => m.EcheanciersModule)
      },
      {
        path: 'progressions',
        loadChildren: () => import('../administration/progressions/progressions.module').then(m => m.ProgressionsModule)
      },
      {
        path: 'notes',
        loadChildren: () => import('../suivis/notes/notes.module').then(m => m.NotesModule)
      },
      { path: 'sanctions', loadChildren: () => import('../suivis/sanctions/sanctions.module').then(m => m.SanctionsModule) }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DirecteurRoutingModule { }
