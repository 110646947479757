<h1>Services scolaires</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/produits">Produits scolaires</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Zones</a>
    </li>
</ul>
<div class="recherche">
    <a class="a button float-md-right" (click)="open(content)">Ajouter </a>
    <h2>Zones de transport </h2>
</div>

<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>



<div class="table-responsive" *ngIf="zones; else noProduits">
    <div class="table-responsive" *ngIf="zones; else noAbonnes">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Zone</th>
                    <th scope="col">Code</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let z of zones">
                    <td>
                        <a routerLink="/{{user.role}}/zones/{{z.idzone}}" title="Voir le detail">{{ z.zone }}</a>
                    </td>
                    <td> {{z.code}} </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #noProduits>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Nouvelle zone</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="groupe">Code de la zone </label>
                <input type="text" formControlName="code" class="form-control" id="code">
            </div>
            <div class="form-group">
                <label for="groupe">Nom de la zone </label>
                <input type="text" formControlName="zone" class="form-control" id="zone">
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
