import { Component, OnDestroy, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-notes-view',
  templateUrl: './notes-view.component.html',
  styleUrls: ['./notes-view.component.css']
})
export class NotesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  matiere: string;
  notes: Suivis[];
  eleve: Suivis;
  noteBilan: Suivis;
  loading = true;
  errorMsg = '';
  jour: any;

  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.jour = formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
  }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.matiere = this.ar.snapshot.paramMap.get('matiere');
    this.getEleve(this.id);
    this.getNotes(this.id, this.matiere);
  }

  getEleve(id: string): void {
    this.subscription.add(
      this.service.view('eleves', id)
        .subscribe(arg => {
          this.eleve = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  getNotes(eleve: string, matiere: string): void {
    this.subscription.add(
      this.service.deuxArgs('notes_matiere', matiere, eleve)
        .subscribe(arg => {
          this.notes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }

  appreciation(note: number, notation: number): string {

    if (note > (notation * 0.7)) {
      return 'Excellent';
    }
    else if (note <= (notation * 0.7) && note >= (notation * 0.6)) { return 'Bon'; }
    else if (note <= (notation * 0.59) && note >= (notation * 0.5)) { return 'Passable'; }
    else if (note <= (notation * 0.5) && note >= (notation * 0.4)) { return 'Passable'; }
    else { return 'Faible'; }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
