<h1>Evaluations </h1>
<div *ngIf="classe">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/notes">Notes et moyennes</a>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled">{{classe.classe_nom}}</a>
    </li>
  </ul>

  <div class="recherche">
    <div class="row">
      <div class="col-md-4">
        <h4> {{classe.classe_nom}} </h4>
        <div *ngIf="detail">
          <p>
            Matière: <strong> {{detail.matiere_nom}} </strong>
            <br>Professeur: <strong> {{detail.profNom}} {{detail.profPrenoms}} </strong>
          </p>
        </div>

      </div>
      <div class="col-md-5" *ngFor="let bilan of bilans">
        <h5 class="text-center">Moyenne du {{periode}} de la classe</h5>
        <h4 class="text-center">{{bilan.moyenne | number:'1.0-2'}}</h4>
      </div>
      <div class="col-md-3">
        <p> <button (click)="print()" class="button">Imprimer</button> </p>

      </div>
    </div>

  </div>
  <div class="table-responsive">
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom et prenoms</th>
          <th scope="col">Sexe</th>
          <th scope="col" class="text-center">Note</th>
          <th scope="col">Moyenne</th>
          <th scope="col"> Moy Modifié </th>
          <th scope="col">Rang </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of eleves; let i=index">
          <td> {{i+1}} </td>
          <td> {{el.nom}} {{el.prenoms}} </td>
          <td> {{el.sexe}} </td>
          <td>
            <app-notes-eleve [eleve]="el" [matiere]="matiere" [classe]="classe.idclasse" [periode]="periode"
              (getUpdate)="updateNote($event)"></app-notes-eleve>
          </td>
          <td> <strong *ngIf="el.moyenne > 0">{{el.moyenne | number:'2.0-2'}}</strong> </td>
          <td>
            <input type="text"
              (keyup.enter)="onSubmit({eleve: el.ideleve, moyenne: $event, periode:'1er Trimestre', matiere: matiere, input: i })"
              id="input{{i}}" [value]="el.moyenne  | number:'2.0-2':'en'" style="width: 50px;">
          </td>
          <td> {{el.rang}}{{el.rang == 1 ? 'er(e)' : 'e'}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="loading" class="text-center">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
