import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../../logistiques';
import { LogistiquesService } from '../../../logistiques.service';

@Component({
  selector: 'app-services-zone',
  templateUrl: './services-zone.component.html',
  styleUrls: ['./services-zone.component.css']
})
export class ServicesZoneComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  form: FormGroup;
  zone: Logistiques;
  abonnes: Logistiques[];
  points: Logistiques[];
  collection = 0;
  errorLoading = '';
  loading = true;
  submitted = false;
  errorMsg = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getZone(this.id);
    this.getAbones(this.id);
    this.getPoints(this.id);
  }

  getZone(id: string): void {
    this.subscription.add(
      this.service.view('zones', id)
        .subscribe(arg => {
          this.zone = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbones(id: string): void {
    this.subscription.add(
      this.service.views('zones_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getPoints(id: string): void {
    this.subscription.add(
      this.service.views('points_arret', id)
        .subscribe(arg => {
          this.points = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
