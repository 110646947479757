<h1>Tableau de bord</h1>
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link disabled">Tableau de bord</a>
    </li>
</ul>
<h4> {{today | date:'fullDate' }} <br> <small>Séances du jour</small> </h4>
<div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg | json}} </div>
<div class="row">
    <div class="col-md-3" *ngFor="let s of seances">
        <div class="cahier">
            <p> <strong>{{s.classe_nom}}</strong></p>
            <h5>{{s.matiere_nom}} </h5>
            <p>
                <strike *ngIf="s.fin < (today | date: 'Y-MM-dd H:mm:ss'); else noTard">
                  {{s.debut | date:'shortTime'}} - {{s.fin | date:'shortTime'}}
                </strike>
                <ng-template #noTard>
                    <span>{{s.debut | date:'shortTime'}} - {{s.fin | date:'shortTime'}}</span>
                </ng-template>
            </p>

            <p>
                <button class="button" routerLink="/{{user.role}}/seances/view/{{s.idseance}}">Ouvrir</button>
            </p>
        </div>

    </div>
</div>
<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>