import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Convoyeur } from './convoyeur';

@Injectable({
  providedIn: 'root'
})
export class ConvoyeurService {
  url = 'https://staff.vie-ecoles.com/server/convoyeurs/';
  options = { params: new HttpParams({ fromString: 'token=' + JSON.parse(localStorage.getItem('user')).token }) };

  constructor(private http: HttpClient) { }

  add(table: string, body: Convoyeur): Observable<string> {
    return this.http.post<string>(this.url + table, body, this.options)
      .pipe(catchError(this.handleError));
  }

  put(body: Convoyeur, table: string = null): Observable<string> {
    if (table) {
      return this.http.put<string>(this.url + table, body, this.options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.put<string>(this.url, body, this.options)
        .pipe(catchError(this.handleError));
    }
  }


  update(table: string, id: string, body: Convoyeur): Observable<string> {
    return this.http.put<string>(this.url + table + '/' + id, body, this.options)
      .pipe(catchError(this.handleError));
  }

  list(table: string): Observable<Convoyeur[]> {
    return this.http.get<Convoyeur[]>(this.url + table, this.options)
      .pipe(catchError(this.handleError));
  }
  one(table: string = null): Observable<Convoyeur> {
    if (table) {
      return this.http.get<Convoyeur>(this.url + table, this.options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.get<Convoyeur>(this.url, this.options)
        .pipe(catchError(this.handleError));
    }

  }
  view(table: string, id: string = null): Observable<Convoyeur> {
    if (id) {
      return this.http.get<Convoyeur>(this.url + table + '/' + id, this.options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.get<Convoyeur>(this.url + table, this.options)
        .pipe(catchError(this.handleError));
    }

  }

  views(table: string, id: string): Observable<Convoyeur[]> {
    return this.http.get<Convoyeur[]>(this.url + table + '/' + id, this.options)
      .pipe(catchError(this.handleError));
  }

  deuxArg(table: string, id: string, id2: string): Observable<Convoyeur> {
    return this.http.get<Convoyeur>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.handleError));
  }


  deuxArgs(table: string, id: string, id2: string): Observable<Convoyeur[]> {
    return this.http.get<Convoyeur[]>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.handleError));
  }

  troisArg(table: string, id: string, id2: string, id3: string): Observable<Convoyeur> {
    return this.http.get<Convoyeur>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.handleError));
  }

  troisArgs(table: string, id: string, id2: string, id3: string): Observable<Convoyeur[]> {
    return this.http.get<Convoyeur[]>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
