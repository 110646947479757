import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-activites-produits',
  templateUrl: './activites-produits.component.html',
  styleUrls: ['./activites-produits.component.css']
})
export class ActivitesProduitsComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  rubrique: Logistiques;
  activites: Logistiques[];
  zones: Logistiques[];
  nZone = 0;
  collection = 0;
  errorLoading = '';
  loading = true;
  page = 1;
  pageSize = 10;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getRubrique(this.id);
    this.getActivite(this.id);
    this.getZones(this.id);
  }

  getRubrique(id: string): void {
    this.subscription.add(
      this.service.view('services', id)
        .subscribe(arg => {
          this.rubrique = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  getZones(id): void {
    this.subscription.add(
      this.service.views('zones_service', id)
        .subscribe(arg => {
          this.zones = arg;
          this.nZone = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }
  getActivite(id: string): void {
    this.subscription.add(
      this.service.views('activites_produit', id)
        .subscribe(arg => {
          this.activites = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
