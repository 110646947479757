<h1>Établissements scolaires</h1>
<div class="recherche">
    <h2>Etablissements scolaires</h2>
</div>
<div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6">
        <ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize="maxSize" *ngIf="collection > pageSize">
            <ng-template ngbPaginationFirst>Premier</ng-template>
            <ng-template ngbPaginationLast>Dernier</ng-template>
            <ng-template ngbPaginationPrevious>Précédent</ng-template>
            <ng-template ngbPaginationNext>Suivant</ng-template>
            <ng-template ngbPaginationEllipsis>...</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
    </div>
</div>

<div class="table-re sponsive">
    <table class="table table-bordered ">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Etablissement</th>
                <th scope="col">Code</th>
                <th scope="col">Effectif</th>
                <th scope="col">quote-part wicsoft</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of ecoles | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                <td> {{i+1}} </td>
                <td> {{e.nom}} </td>
                <td> {{e.code}} </td>
                <td> {{e.effectif}} </td>
                <td>
                    <a routerLink="{{e.code}}">Voir detail</a> </td>
            </tr>
        </tbody>
    </table>
</div>
<ngb-pagination [collectionSize]="collection" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true" maxSize="maxSize" *ngIf="collection > pageSize">
    <ng-template ngbPaginationFirst>Premier</ng-template>
    <ng-template ngbPaginationLast>Dernier</ng-template>
    <ng-template ngbPaginationPrevious>Précédent</ng-template>
    <ng-template ngbPaginationNext>Suivant</ng-template>
    <ng-template ngbPaginationEllipsis>...</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
</ngb-pagination>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
