import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SanctionsComponent } from './sanctions.component';
import { SanctionsClasseComponent } from './sanctions-classe/sanctions-classe.component';
import { SanctionsViewComponent } from './sanctions-view/sanctions-view.component';

const routes: Routes = [
  { path: '', component: SanctionsComponent },
  { path: 'view/:id', component: SanctionsViewComponent },
  { path: 'classe/:id', component: SanctionsClasseComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SanctionsRoutingModule { }
