import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormattingService } from '../../../config/formatting.service';
import { faCheck, faTimes, faLock } from '@fortawesome/free-solid-svg-icons';
import { Subscription,  Observable, Subject } from 'rxjs';
import { SuivisService } from '../../suivis.service';
import { Suivis } from '../../suivis';

import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Position {
  coords: Coordinates;
}

@Component({
  selector: 'app-seances-view',
  templateUrl: './seances-view.component.html',
  styleUrls: ['./seances-view.component.css']
})
export class SeancesViewComponent implements OnInit, OnDestroy {
  @Output() getPicture = new EventEmitter<WebcamImage>();
  showWebcam = true;
  isCameraExist = true;
  errors: WebcamInitError[] = [];

  afficherLien = false;

    // Coordonnées de l'établissement (à remplacer par les vraies coordonnées)
  // etablissementCoords: Coordinates = { latitude: 12.345, longitude: 67.890 };
  etablissementCoords: Coordinates = { latitude: 5.332183, longitude: -4.098509 };
  // Limite de distance en kilomètres (par exemple, 100 mètres)
  distanceLimite: number = 1.1;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  s: Suivis;
  cahiers: Suivis[] = [];
  eleves: Suivis[] = [];
  effectif = 0;
  presents = 0;
  loading = true;
  errorMsg = '';
  faError = faTimes;
  faSuccess = faCheck;
  faVerrou = faLock;
  today: Date = new Date();


  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService,
    public formatting: FormattingService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getSeance(this.id);
    this.getCahiers(this.id);
    this.getPresent(this.id);
    this.getEleves(this.id);
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
    });
  }


  takeSnapshot(): void {
    // this.trigger.next();
    // this.getLocation();
    // Vérifier si la permission de la caméra est accordée
  navigator.mediaDevices.getUserMedia({ video: true })
  .then((stream) => {
    // La permission est accordée, vous pouvez prendre un instantané ici
    this.trigger.next();
    this.getLocation();
  })
  .catch((error) => {
    // La permission de la caméra n'a pas été accordée
    console.error('Permission de la caméra non accordée:', error);
    const userConfirmed = window.confirm("Pour prendre un votre photo, veuillez autoriser l'accès à la caméra.");
    if (userConfirmed) {
      // Redirigez l'utilisateur vers les paramètres pour autoriser l'accès à la caméra
      window.location.href = "chrome://settings/content/camera";
    }
  });
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    // Utilise la webcamImage (par exemple, pour l'afficher dans le template)
    this.webcamImage = webcamImage;
    this.getPicture.emit(webcamImage);
    this.showWebcam = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  getSeance(id: string): void {
    this.subscription.add(
      this.service.view('seances', id)
        .subscribe(
          (arg: Suivis) => {
            this.s = arg;

            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getCahiers(id: string): void {
    this.subscription.add(
      this.service.list('cahier_texte?seance=' + id)
        .subscribe((arg: Suivis[]) => {
          this.cahiers = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getEleves(id: string): void {
    this.subscription.add(
      this.service.list('seances_eleves?seance=' + id)
        .subscribe((arg: Suivis[]) => {

          this.effectif = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getPresent(id: string): void {
    this.subscription.add(
      this.service.list(`seances_eleves?seance=${id}`)
        .subscribe((arg: Suivis[]) => {
          this.eleves = arg;
          this.presents = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  webcamImage:WebcamImage | undefined

  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  // public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // Options vidéo, par exemple, la résolution :
    width: { ideal: 1024 },
    height: { ideal: 576 },
  };

  // Capture une photo
  // public async capture(): Promise<void> {
  //   const imageAsBase64 = await this.webcam.captureBase64({
  //     mimeType: 'image/jpeg',
  //   });
  //   // Maintenant, tu peux utiliser `imageAsBase64` comme source d'image ou la télécharger, etc.
  // }

  // Change de caméra si plusieurs caméras sont disponibles
  public switchCamera(): void {
    this.allowCameraSwitch = !this.allowCameraSwitch;
  }

  locationData: Coordinates = { latitude: 0, longitude: 0 };


  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition.bind(this));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
}


  showPosition(position: Position) {
    this.locationData = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    this.afficherLien = true;
    console.log(this.locationData); // Vérifie dans la console que les données sont correctes

    const distance = this.calculateDistance(this.locationData, this.etablissementCoords);

    if (distance <= this.distanceLimite) {
      this.afficherLien = true;
      alert("Vous pouvez pointer depuis cet emplacement.");
    } else {
      this.afficherLien = false;
      alert("Vous ne pouvez pas pointer depuis cet emplacement.");
    }
  }

  // Fonction pour calculer la distance entre deux points géographiques
  calculateDistance(coords1: Coordinates, coords2: Coordinates): number {
    const R = 6371; // Rayon de la Terre en kilomètres
    const dLat = this.degreesToRadians(coords2.latitude - coords1.latitude);
    const dLon = this.degreesToRadians(coords2.longitude - coords1.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(coords1.latitude)) * Math.cos(this.degreesToRadians(coords2.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  // Fonction pour convertir les degrés en radians
  degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

}
