import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-cahiers-textes-add-lecon-detail',
  templateUrl: './cahiers-textes-add-lecon-detail.component.html',
  styleUrls: ['./cahiers-textes-add-lecon-detail.component.css']
})
export class CahiersTextesAddLeconDetailComponent implements OnInit, OnChanges, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() id: string;
  form: FormGroup;
  cahiers: Suivis[];
  loading = true;
  errorMsg = '';
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private service: SuivisService
  ) { }


  ngOnChanges(): void {
    this.getDetail(this.id);
  }

  ngOnInit(): void {
    this.getDetail(this.id);
    this.createForm();
  }

  getDetail(id: string): void {
    this.subscription.add(
      this.service.views('cahier_details', id)
        .subscribe(
          (arg: Suivis[]) => {
            this.cahiers = arg;
            this.loading = false;
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }


  createForm(): void {
    this.form = this.fb.group({
      cahier: [this.id, Validators.required],
      activite: ['', Validators.required],
      reference: ['', Validators.required],
      titre: ['']
    });

  }

  onSubmit(): void {
    const r = confirm('Vous êtes sûr de valider cette activité ?');
    if (r) 
    {
      alert("Vous avez cliqué sur OK");
      this.submitted = true;
      this.subscription.add(
      this.service.add('cahier_details', this.form.value)
        .subscribe(arg => {
          this.getDetail(this.id);
          this.form.get('activite').reset();
          this.form.get('reference').reset();
          this.form.get('titre').reset();
          this.submitted = false;
        },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
      );
    }
    else alert("Vous avez cliqué sur ANNULER ou vous avez fermé");
    
    
  }

  onDelete(a: Suivis): void {
    const r = confirm('Vous êtes sûr de la suppression ?');
    if (r) {
      this.subscription.add(
        this.service.delete('cahier_details', a.idactivite)
          .subscribe(arg => {
            this.getDetail(this.id);
          },
            (erg: never) => {
              this.errorMsg = erg;
              this.loading = false;
            })
      );
    }
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
