import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SeancesRoutingModule } from './seances-routing.module';
import { SeancesComponent } from './seances.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SeancesViewComponent } from './seances-view/seances-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SeancesJourComponent } from './seances-jour/seances-jour.component';
import { SeancesAppelComponent } from './seances-appel/seances-appel.component';
import { SeancesLeconsComponent } from './seances-lecons/seances-lecons.component';
import { SeancesLeconsTitresComponent } from './seances-lecons-titres/seances-lecons-titres.component';
import {WebcamModule} from 'ngx-webcam';

@NgModule({
  declarations: [
    SeancesComponent,
    SeancesViewComponent,
    SeancesJourComponent,
    SeancesAppelComponent,
    SeancesLeconsComponent,
    SeancesLeconsTitresComponent
  ],
  imports: [
    CommonModule,
    SeancesRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    FontAwesomeModule,
    WebcamModule
  ]
})
export class SeancesModule { }
