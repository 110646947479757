<h1>Services scolaires</h1>
<div *ngIf="zone">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits">Produits scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/zones">Zones de transport</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{zone.zone}}</a>
        </li>
    </ul>

    <div class="recherche">
        <div class="row">
            <div class="col-md-5">
                <h2>{{zone.zone}}
                    <small>Code {{zone.code}} </small>
                </h2>
                <div class="row">
                    <div class="col-md-6">
                        <app-zones-sms [id]="zone.idzone"></app-zones-sms>
                    </div>
                    <div class="col-md-6">
                        <a (click)="print()" class="a">Imprimer</a>
                    </div>
                </div>

            </div>
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-4">
                        <app-zones-vehicule [id]="zone.idzone"></app-zones-vehicule>
                    </div>
                    <div class="col-md-4">
                        <app-zones-encadreur [id]="zone.idzone"></app-zones-encadreur>
                    </div>
                    <div class="col-md-4">
                        <app-zones-chauffeur [id]="zone.idzone"></app-zones-chauffeur>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
    <div class="recherche">
        <label>Recherche</label>
        <p> {{collection | number}} enregistrements </p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-5">
                    <input type="text" class="form-control" placeholder="Nom ou prenoms" (keyup)="onRechercheNom($event)">
                </div>
                <div class="form-group col-md-3">
                    <label for="recherche" class="sr-only">Service</label>
                    <select formControlName="service" class="form-control" (change)="onRechercheService($event)">
                        <option>Service</option>
                      <option *ngFor="let p of points" [value]="p.iddetail">{{p.designation}}</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="classe" class="sr-only">Classe</label>
                    <select formControlName="classe" class="form-control" (change)="onRechercheClasse($event)">
                            <option>Classe</option>
                            <option *ngFor="let c of classes" [value]="c.uid">{{c.nom}}</option>
                          </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="sexe" class="sr-only">Sexe</label>
                    <select formControlName="sexe" class="form-control" (change)="onRechercheSexe($event)">
                            <option>Sexe</option>
                            <option *ngFor="let s of sexes" [value]="s">{{s}}</option>
                        </select>
                </div>

                <div class="form-group col-md-2">
                    <button type="submit" class="button">Rechercher</button>
                </div>
            </div>
        </form>


    </div>
    <div class="table-responsive" *ngIf="abonnes; else noAbonnes">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Souscripteur</th>
                    <th scope="col">Sexe</th>
                    <th scope="col">Classe</th>
                    <th scope="col">Point d'arrêt</th>
                    <th scope="col">Prix</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of abonnes">
                    <td>{{ a.nom | uppercase}} {{a.prenoms | uppercase}} </td>
                    <td> {{a.sexe}} </td>
                    <td>
                        <app-zones-classe [id]="a.classe"></app-zones-classe>
                    </td>
                    <td> {{ a.designation}}</td>
                    <td> {{ a.nouveau === 'NON' ? (a.prix | currency:'XOF') : (a.prix2 | currency:'XOF') }}</td>
                    <td>
                        <a routerLink="/{{user.role}}/souscripteurs/view/{{a.idabonne}}">Voir detail</a> </td>
                </tr>
            </tbody>
        </table>
    </div>



    <ng-template #noAbonnes>
        <p class="errorLoading text-center"> {{errorLoading}} </p>
    </ng-template>
</div>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
