import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupesRoutingModule } from './groupes-routing.module';
import { GroupesComponent } from './groupes.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupesViewComponent } from './groupes-view/groupes-view.component';
import { GroupesStatutComponent } from './groupes-statut/groupes-statut.component';

import { GroupesEleveClasseComponent } from './groupes-eleve-classe/groupes-eleve-classe.component';
import { GroupesActionComponent } from './groupes-action/groupes-action.component';
import { GroupesEleveComponent } from './groupes-eleve/groupes-eleve.component';


@NgModule({
  declarations: [
    GroupesComponent,
    GroupesViewComponent,
    GroupesStatutComponent,
    GroupesEleveClasseComponent,
    GroupesActionComponent,
    GroupesEleveComponent
  ],
  imports: [
    CommonModule,
    GroupesRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule,
    FontAwesomeModule
  ]
})
export class GroupesModule { }
