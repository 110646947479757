<h1>Services scolaires</h1>

<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Services scolaires</a>
    </li>
</ul>
<div class="recherche">
    <h2>Services scolaires </h2>
</div>

<div class="table-responsive" *ngIf="services; else noProduits">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Services</th>
                <th scope="col">Inscrits</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of services; let i=index">
                <td> {{i+1}} </td>
                <td> {{ s.nom }} </td>
                <td>
                    <app-produits-abonnes [id]="s.code"></app-produits-abonnes>
                </td>
                <td> <a routerLink="/{{user.role}}/produits/view/{{s.code}}" title="Voir le detail">Voir detail</a> </td>

            </tr>
        </tbody>
    </table>
</div>

<ng-template #noProduits>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Nouveau service
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="service">Nom du service</label>
                <input type="text" formControlName="service" class="form-control">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
            </div>
        </div>
    </form>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
