import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-souscripteurs-classe',
  templateUrl: './souscripteurs-classe.component.html',
  styleUrls: ['./souscripteurs-classe.component.css']
})
export class SouscripteursClasseComponent implements OnInit, OnDestroy {
  @Input() id: string;
  subscription = new Subscription();
  classe: Logistiques;
  errorLoading = '';
  loading = true;

  constructor(
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.getClasse(this.id);
  }


  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
