import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faCalendar, faLock, faCalendarTimes, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from '../../Suivis';
import { SuivisService } from '../../Suivis.service';

@Component({
  selector: 'app-cahiers-textes-etat',
  templateUrl: './cahiers-textes-etat.component.html',
  styleUrls: ['./cahiers-textes-etat.component.css']
})
export class CahiersTextesEtatComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() cahier: Suivis;
  form: FormGroup;
  seances = 0;
  retard = 0;
  loading = true;
  errorMsg = '';
  submitted = false;
  today: Date = new Date();
  faCalendarTimes = faCalendarTimes;
  faCalendar = faCalendar;
  faLock = faLock;
  faLockOpen = faLockOpen;

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: SuivisService
  ) { }


  ngOnInit(): void {
    this.getSeances(this.cahier);
    this.getRetard(this.cahier);
    this.createForm();
  }
  getSeances(cahier: Suivis): void {
    this.subscription.add(
      this.service.list(`seances?classe=${cahier.idclasse}&matiere=${cahier.idmatiere}&cahier=0&presence=1`)
        .subscribe((arg: Suivis[]) => {
          this.seances = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getRetard(cahier: Suivis): void {
    this.subscription.add(
      this.service.list(`cahier_texte?classe=${cahier.idclasse}&matiere=${cahier.idmatiere}&retard=1`)
        .subscribe((arg: Suivis[]) => {
          this.retard = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  open(content): void {
    this.modal.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      debloque: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.cahier.debloque = this.form.get('debloque').value;
    this.subscription.add(
      this.service.update('cahier_debloque', this.cahier.id, this.cahier)
        .subscribe(
          arg => {
            location.reload();
            this.submitted = false;
            this.modal.dismissAll();
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
