import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdministrationService } from '../../administration.service';
import { Administration } from '../../administration';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-administration-service-detail',
  templateUrl: './administration-service-detail.component.html',
  styleUrls: ['./administration-service-detail.component.css']
})
export class AdministrationServiceDetailComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  d: Administration;
  frais: Administration[];
  branches: Administration[];
  echeances: Administration[];
  form: FormGroup;
  id: string;
  ecole = '';
  s = '';
  collection = 0;
  loading = true;
  submitted = false;
  errorLoading = '';
  errorMsg = '';
  statuts = [
    { code: '*', nom: 'Tous les statuts' },
    { code: 'AFF', nom: 'AFFECTES' },
    { code: 'NAFF', nom: 'NON-AFFECTES' },
    { code: 'PC', nom: 'PRISES EN CHARGE' },
    { code: 'ECOLIER', nom: 'ECOLIER' }
  ];

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private modalService: NgbModal,
    private service: AdministrationService
  ) { }


  ngOnInit(): void {
    this.ar.parent.params.subscribe(pr => {
      this.ecole = pr.id;
      this.s = pr.s;
    });

    this.ar.paramMap.subscribe(p => {
      this.id = p.get('f');
      this.getDetail(this.id);
      this.getFrais(this.id);
    });
    this.getBranches();
    this.getEcheances();
    this.createForm();
  }


  getDetail(id: string): void {
    this.subscription.add(
      this.service.deuxArg('produit', this.ecole, id)
        .subscribe(arg => {
          this.d = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getBranches(): void {
    this.subscription.add(
      this.service.views('branches', this.ecole)
        .subscribe(arg => {
          this.branches = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getEcheances(): void {
    this.subscription.add(
      this.service.views('echeances', this.ecole)
        .subscribe(arg => {
          this.echeances = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }


  getFrais(id: string): void {
    this.subscription.add(
      this.service.deuxArgs('frais', this.ecole, id)
        .subscribe(arg => {
          this.frais = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  open(content: any): void {
    this.modalService.open(content, { size: 'lg' });
  }

  createForm(): void {
    this.form = this.fb.group({
      ecole: [this.ecole, Validators.required],
      rubrique: [this.id, Validators.required],
      niveau: ['', Validators.required],
      datelimite: ['', Validators.required],
      statut: ['', Validators.required],
      montant: ['', Validators.required],
      montant2: ['', Validators.required],
      libelle: [''],
      echeance: [''],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('frais', this.form.value)
        .subscribe(
          arg => {
            this.getFrais(this.id);
            this.submitted = false;
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          }));
  }

  onDelete(id): void {
    this.subscription.add(
      this.service.delete('frais', id, this.ecole)
        .subscribe(arg => {
          this.getDetail(this.id);
          location.reload();
        }, erg => {
          this.errorMsg = erg.error.message;
          this.loading = false;
        }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
