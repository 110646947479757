import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-logistiques-souscripteur',
  templateUrl: './logistiques-souscripteur.component.html',
  styleUrls: ['./logistiques-souscripteur.component.css']
})
export class LogistiquesSouscripteurComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  id: string;
  rubrique: string;
  p: Logistiques;
  frais: Logistiques[];
  abonne: Logistiques;
  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.rubrique = this.ar.snapshot.params.service;
    this.getAbonne(this.id);
    this.getEcheancier(this.id);
  }


  getAbonne(id: string): void {
    this.subscription.add(
      this.service.view('abonnes', id)
        .subscribe(arg => {
          this.abonne = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getEcheancier(id: string): void {
    this.subscription.add(
      this.service.deuxArgs('abonnes_echeancier', id, this.rubrique)
        .subscribe(arg => {
          this.frais = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
