import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormattingService } from 'src/app/config/formatting.service';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-parc-auto-vehicule',
  templateUrl: './parc-auto-vehicule.component.html',
  styleUrls: ['./parc-auto-vehicule.component.css']
})
export class ParcAutoVehiculeComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  v: Logistiques;
  parcours: Logistiques;
  charges: Logistiques[];
  rappels: Logistiques[];
  typeCharge: Logistiques[];
  total: Logistiques;
  form: FormGroup;
  rappelForm: FormGroup;
  submitted = false;
  loading = true;
  errorMsg = '';
  errorLoading = '';
  collection = 0;
  page = 1;
  pageSize = 10;
  today = Date.now();

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private directeurSce: LogistiquesService,
    private modalService: NgbModal,
    public formatting: FormattingService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getVehicule(this.id);
    this.getCharges(this.id);
    this.getChargeTotal(this.id);
    this.getChargeType();
    this.getParcours(this.id);
    this.createForm();
  }

  getVehicule(id: string): void {
    this.subscription.add(
      this.directeurSce.view('vehicules', id)
        .subscribe(arg => {
          this.v = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getCharges(id: string): void {
    this.subscription.add(
      this.directeurSce.views('vehiculeCharges', id)
        .subscribe(arg => {
          this.charges = arg;
          this.collection = arg.length;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getParcours(id: string): void {
    this.subscription.add(
      this.directeurSce.view('vehiculeParcours', id)
        .subscribe(arg => {
          this.parcours = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getChargeTotal(id: string): void {
    this.subscription.add(
      this.directeurSce.view('vehiculeChargesTotal', id)
        .subscribe(arg => {
          this.total = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }

  getChargeType(): void {
    this.subscription.add(
      this.directeurSce.list('charge_types')
        .subscribe(arg => {
          this.typeCharge = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }
  onTypeCharge(e): void {
    this.subscription.add(
      this.directeurSce.deuxArg('vehiculeChargesTotal', this.id, e.target.value)
        .subscribe(arg => {
          this.total = arg;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
    this.subscription.add(
      this.directeurSce.deuxArgs('vehiculeCharges', this.id, e.target.value)
        .subscribe(arg => {
          this.charges = arg;
          this.collection = arg.length;
          this.loading = false;
        }, erg => {
          this.errorLoading = 'Erreur de chargement de données';
          this.loading = false;
        })
    );
  }


  open(content): void {
    this.modalService.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      vehicule: [this.id, Validators.required],
      chargeType: ['', Validators.required],
      charge: ['', Validators.required],
      cout: ['', Validators.required],
      fournisseur: [''],
      autre: ['']
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.directeurSce.add('vehiculeCharges', this.form.value)
        .subscribe(
          arg => {
            this.getChargeTotal(this.id);
            this.getCharges(this.id);
            this.submitted = false;
            this.modalService.dismissAll();
          },
          erg => {
            this.errorMsg = erg.error.message;
            this.submitted = false;
          })
    );
  }


}
