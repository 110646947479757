<p *ngIf="g; else noG">
    <strong *ngIf="prod ==='TRANS' "> {{g.arret}}</strong> <br>
    <span> {{g.groupe}} </span>
</p>
<ng-template #noG>
    <p><a class="a button" (click)="open(content)">Ajouter</a> </p>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Point d'arrêt </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="groupe"> {{ prod === 'TRANS' ? 'Itinéraire' : 'Groupe' }} </label>
                <select formControlName="groupe" class="form-control" id="groupe">
                  <option *ngFor="let g of groupes" [value]="g.idgroupement">{{g.groupe}}</option>
                </select>
            </div>

            <div class="form-group" *ngIf="prod === 'TRANS' ">
                <label for="chauffeur">Point d'arrêt</label>
                <input type="text" formControlName="arret" class="form-control" id="arret">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



            </div>
        </div>
    </form>
</ng-template>
