import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';

@Component({
  selector: 'app-notes-add',
  templateUrl: './notes-add.component.html',
  styleUrls: ['./notes-add.component.css']
})
export class NotesAddComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() evaluation: Suivis;
  @Input() eleve: string;
  form: FormGroup;
  note: Suivis;
  e: Suivis;
  loading = true;
  submitted = false;
  errorMsg = '';
  errorType = '';

  faSuccess = faCheck;
  faError = faTimes;


  constructor(
    private fb: FormBuilder,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.getNote(this.eleve);
    this.createForm();
  }

  getNote(eleve: string): void {
    this.subscription.add(
      this.service.deuxArg('notes', this.evaluation.idevaluation, eleve)
        .subscribe(arg => {
          this.note = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      evaluation: [this.evaluation.idevaluation, Validators.required],
      eleve: [this.eleve, Validators.required],
      note: ['', [Validators.required, Validators.min(1), Validators.max(this.evaluation.notation)]]
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('notes', this.form.value)
        .subscribe(arg => {
          this.errorType = 'success';
          this.submitted = false;
        },
          erg => {
            this.errorMsg = erg;
            this.errorType = 'error';
            this.submitted = false;
          }
        )
    );
  }
}
