import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';

@Component({
  selector: 'app-logistiques-produits',
  templateUrl: './logistiques-produits.component.html',
  styleUrls: ['./logistiques-produits.component.css']
})
export class LogistiquesProduitsComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  id: string;
  p: Logistiques;
  frais: Logistiques[];
  abonnes: Logistiques[];
  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.getProduit(this.id);
    this.getAbonnes(this.id);
    this.getEcheanciers(this.id);
  }

  getProduit(id: string): void {
    this.subscription.add(
      this.service.view('produit', id)
        .subscribe(arg => {
          this.p = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('produits_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  getEcheanciers(id: string): void {
    this.subscription.add(
      this.service.views('frais', id)
        .subscribe(arg => {
          this.frais = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
