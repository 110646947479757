import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';

@Component({
  selector: 'app-sanctions-classe',
  templateUrl: './sanctions-classe.component.html',
  styleUrls: ['./sanctions-classe.component.css']
})
export class SanctionsClasseComponent implements OnInit, OnDestroy {
  @Input() id: string;
  subscription = new Subscription();
  classe: Suivis;
  errorLoading = '';
  loading = true;

  constructor(
    private service: SuivisService
  ) { }

  ngOnInit(): void {
    this.getClasse(this.id);
  }

  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
