import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/suivis';
import { SuivisService } from 'src/app/suivis/suivis.service';

@Component({
  selector: 'app-note-eleve-moyenne',
  templateUrl: './note-eleve-moyenne.component.html',
  styleUrls: ['./note-eleve-moyenne.component.css']
})
export class NoteELeveMoyenneComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input() eleve: string;
  @Input() matiere: string;
  @Input() periode: string;
  notes = 0;
  moyenne: Suivis;
  loading = true;
  errorMsg = '';
  submitted = false;

  constructor(private service: SuivisService) { }

  ngOnInit(): void {
    this.getMoyenne(this.eleve, this.matiere, this.periode);
  }

  getMoyenne(eleve: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.one(`moyennes?eleve=${eleve}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.moyenne = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getNotes(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`notes_eleve?eleve=${id}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.notes = arg.length;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
