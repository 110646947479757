<h1>Services scolaires</h1>
<div *ngIf="zone">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Zones de transport</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{zone.zone}}</a>
        </li>
    </ul>

    <div class="recherche">
        <div class="row">
            <div class="col-md-7">
                <h2> {{zone.zone}}
                    <small>Code {{zone.code}} </small>
                </h2>
            </div>
            <div class="col-md-3">
            </div>
        </div>
    </div>
</div>

<div class="recherche">
    <h4 class="text-center">Points d'arrêt</h4>
    <div class="row">
        <div class="col-md-4" *ngFor="let p of points">
            <h6> {{p. designation}}</h6>
            <p>Ancien {{p.prix | currency:'XOF'}} / Nouveau {{p.prix2 | currency:'XOF'}}</p>
        </div>
    </div>
</div>

<h4>Souscripteurs</h4>
<div class="table-responsive" *ngIf="abonnes; else noAbonnes">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Souscripteur</th>
                <th scope="col">Sexe</th>
                <th scope="col">Classe</th>
                <th scope="col">Prix</th>
                <th scope="col">Point d'arrêt</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of abonnes">
                <td>
                    <a routerLink="/{{user.role}}/services/souscripteurs/{{a.eleve}}" title="Voir le detail">
                  {{ a.nom | uppercase}} {{a.prenoms | uppercase}}</a>
                </td>
                <td> {{a.sexe}} </td>
                <td> {{a.classe}} </td>
                <td> {{ a.nouveau === 'NON' ? (a.prix | currency:'XOF') : (a.prix2 | currency:'XOF') }}</td>
                <td> {{ a.designation}}</td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noAbonnes>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>



<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
