import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Convoyeur } from '../../convoyeur';
import { ConvoyeurService } from '../../convoyeur.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-groupes-view',
  templateUrl: './groupes-view.component.html',
  styleUrls: ['./groupes-view.component.css']
})
export class GroupesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  groupe: Convoyeur;
  abonnes: Convoyeur[];
  collection = 0;
  loading = true;
  errorMsg = '';
  today: Date = new Date();
  moments = [];
  actions = [];
  constructor(
    private ar: ActivatedRoute,
    private service: ConvoyeurService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getGroupe(this.id);
    this.getAbonnes(this.id);
  }

  getGroupe(id: string): void {
    this.subscription.add(
      this.service.view('activites', id)
        .subscribe(arg => {
          this.groupe = arg;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getAbonnes(id: string): void {
    this.subscription.add(
      this.service.views('activites_abonnes', id)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }

  back(): void {
    history.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
