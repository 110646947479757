<div *ngIf="groupe">
    <div class="header-mobile">{{groupe.designation}}
        <i class="bi bi-chevron-compact-left" (click)="back()"></i>
    </div>

    <div class="body-mobile">
        <div class="container">
            <h4>{{groupe.activite}} <small>{{groupe.action}}</small> </h4>
            <h5>{{today | date:'medium'}} </h5>

            <div class="table-responsible" *ngIf="collection > 0; else noAbonnes">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Souscripteur</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of abonnes">
                            <td>
                                <app-groupes-eleve [eleve]="a" [rubrique]="groupe.service"></app-groupes-eleve>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p class="text-center"> <button routerLink="/{{user.role}}/groupes" class="button btn-lg">Terminer et quitter</button> </p>
            <ng-template #noAbonnes>
                <p class="errorLoading"> Aucune données </p>
            </ng-template>
        </div>

    </div>

</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>
