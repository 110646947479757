import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notes-classe-global',
  templateUrl: './notes-classe-global.component.html',
  styleUrls: ['./notes-classe-global.component.css']
})
export class NotesClasseGlobalComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  errorMsg = '';
  errorType = '';
  id: string;
  classe: Suivis;
  matieres: Suivis[];
  loading = true;
  periodes = ['1er Trimestre', '2e Trimestre', '3e Trimestre', '1er Semestre', '2e Semestre'];

  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService
  ) { }


  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getClasse(this.id);
    this.getMatieres(this.id);
  }

  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes', id)
        .subscribe(arg => {
          this.classe = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }));
  }

  getMatieres(id: string): void {
    this.subscription.add(
      this.service.list('classes_details?classe=' + id)
        .subscribe((arg: Suivis[]) => {
          this.matieres = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }


  print(): void {
    window.print();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
