<h6>Encadreur</h6>

<div *ngIf="zone; else noZone">
    <p>
        {{ zone.encadreur }}
        <br> <a class="a" (click)="open(content)">Modifier </a>
    </p>

</div>
<ng-template #noZone>
    <a class="a" (click)="open(content)">Ajouter </a>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Encadreur</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
            <div class="form-group">
                <label for="vehicule">Encadreur</label>
                <select formControlName="encadreur" class="form-control" id="encadreur">
                  <option *ngFor="let e of encadreurs" [value]="e.idencadreur">{{e.encadreur}}  {{e.role | uppercase}} </option>
                </select>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>
            </div>
        </div>
    </form>
</ng-template>
