import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CahiersTextesRoutingModule } from './cahiers-textes-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CahiersTextesAddComponent } from './cahiers-textes-add/cahiers-textes-add.component';
import { CahiersTextesContenuComponent } from './cahiers-textes-contenu/cahiers-textes-contenu.component';
import { CahiersTextesLeconDetailsComponent } from './cahiers-textes-lecon-details/cahiers-textes-lecon-details.component';
import { CahiersTextesLeconComponent } from './cahiers-textes-lecon/cahiers-textes-lecon.component';
import { CahiersTextesViewComponent } from './cahiers-textes-view/cahiers-textes-view.component';
import { CahiersTextesComponent } from './cahiers-textes.component';
import { CahiersTextesFichierComponent } from './cahiers-textes-fichier/cahiers-textes-fichier.component';
import { CahiersTextesEtatComponent } from './cahiers-textes-etat/cahiers-textes-etat.component';
import { CahiersTextesAddLeconDetailComponent } from './cahiers-textes-add-lecon-detail/cahiers-textes-add-lecon-detail.component';

@NgModule({
  declarations: [
    CahiersTextesComponent,
    CahiersTextesViewComponent,
    CahiersTextesContenuComponent,
    CahiersTextesLeconComponent,
    CahiersTextesLeconDetailsComponent,
    CahiersTextesAddComponent,
    CahiersTextesFichierComponent,
    CahiersTextesEtatComponent,
    CahiersTextesAddLeconDetailComponent
  ],
  imports: [
    CommonModule,
    CahiersTextesRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbAlertModule,
    NgbPaginationModule,
    FontAwesomeModule
  ]
})
export class CahiersTextesModule { }
