import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HistoriquesRoutingModule } from './historiques-routing.module';
import { HistoriquesComponent } from './historiques.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HistoriquesViewComponent } from './historiques-view/historiques-view.component';


@NgModule({
  declarations: [
    HistoriquesComponent,
    HistoriquesViewComponent
  ],
  imports: [
    CommonModule,
    HistoriquesRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule,
    FontAwesomeModule
  ]
})
export class HistoriquesModule { }
