<div class="authentication">
  <div class="container">
    <div class="auth-form">
      <div class="text-center">
        <!-- <img src="assets/images/logo.png" alt="Vie-Ecoles">-->
      </div>
      <router-outlet></router-outlet>
      <p><small>Fièrement devéloppée par Wicsoft</small></p>
    </div>
  </div>
</div>
