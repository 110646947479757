<h1>Souscripteurs</h1>
<div *ngIf="abonne; else noAbonne">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/souscripteurs">Souscripteurs</a>
        </li>

        <li class="nav-item">
            <a class="nav-link disabled">{{abonne.nom}} {{abonne.prenoms}}  </a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-2">
                <div class="photo_identite">
                    <img src="https://s3.eu-west-3.amazonaws.com/vie-ecoles/ecole/photos/eleves/{{abonne.photo}}" alt="photo de {{abonne.nom}}">
                </div>
            </div>
            <div class="col-md-10">
                <h2>{{abonne.nom}} {{abonne.prenoms}}</h2>
                <div class="row">
                    <div class="col-md-3">
                        Classe: <strong> <app-souscripteurs-classe [id]="abonne.classe"></app-souscripteurs-classe></strong>
                    </div>
                    <div class="col-md-3">
                        Statut: <strong> {{abonne.statut}} </strong>
                    </div>
                    <div class="col-md-3">
                        Sexe: <strong> {{abonne.sexe}} </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        Souscription: <strong> {{abonne.rubrique}} </strong>
                    </div>
                    <div class="col-md-3">
                        Produit: <strong> {{abonne.designation}} </strong>
                    </div>
                    <div class="col-md-3">
                        Prix: <strong> {{abonne.nouveau === 'OUI' ? (abonne.prix2 | currency:'XOF') : (abonne.prix | currency:'XOF')}} </strong>
                    </div>
                    <div class="col-md-3">
                        Date: <strong> {{abonne.abonnement_date | date }} </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="recherche">
        <div class="row">
            <div class="col-md-7">
                <app-souscripteurs-echeancier [eleve]="abonne.eleve" [produit]="abonne.rubrique"></app-souscripteurs-echeancier>
            </div>
            <div class="col-md-5">
                <app-souscripteurs-medical [eleve]="abonne.eleve"></app-souscripteurs-medical>
            </div>
        </div>
    </div>
    <app-souscripteurs-activites [eleve]="abonne.eleve" [produit]="abonne.iddetail"></app-souscripteurs-activites>
</div>






<ng-template #noAbonne>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
