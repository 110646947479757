import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Logistiques } from '../logistiques';
import { LogistiquesService } from '../logistiques.service';

@Component({
  selector: 'app-souscripteurs',
  templateUrl: './souscripteurs.component.html',
  styleUrls: ['./souscripteurs.component.css']
})
export class SouscripteursComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  abonnes: Logistiques[];
  points: Logistiques[];
  sexes = ['M', 'F'];
  classes: Logistiques[];
  collection = 0;
  errorLoading = '';
  loading = true;
  submitted = false;

  constructor(
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.getAbonnes();
    this.getPoints();
    this.getClasses();
  }


  getAbonnes(): void {
    this.subscription.add(
      this.service.list('abonnes')
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }
  getPoints(): void {
    this.subscription.add(
      this.service.list('points_arret')
        .subscribe(arg => {
          this.points = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement';
            this.loading = false;
          }));
  }

  getClasses(): void {
    this.subscription.add(
      this.service.list('classes')
        .subscribe(arg => {
          this.classes = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement';
            this.loading = false;
          }));
  }

  print(): void {
    window.print();
  }


  onClickSubmit(data: Logistiques): void {
    const produit = data.produit !== '' ? 'service=' + data.produit + '&' : '';
    const classe = data.classe !== '' ? 'classe=' + data.classe + '&' : '';
    const sexe = data.sexe !== '' ? 'sexe=' + data.sexe + '&' : '';
    this.subscription.add(
      this.service.list('abonnes?' + produit + classe + sexe)
        .subscribe(arg => {
          this.abonnes = arg;
          this.collection = arg.length;
          this.loading = false;
        },
          erg => {
            this.errorLoading = 'Erreur de chargement de données';
            this.loading = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
