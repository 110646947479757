<h1>Produits et services</h1>
<div *ngIf="s">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/services">Produits et services</a>
        </li>

        <li class="nav-item">
            <a class="nav-link disabled">{{s.nom}} </a>
        </li>
    </ul>
    <div class="recherche">
        <a class="a float-md-right" (click)="open(content)">Ajouter nouveau produit</a>
        <h5>Produits du Service {{s.nom}} </h5>
    </div>

    <div class="row">
        <div class="col-md-6">
            <h5>Prix des produits</h5>
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="table-responsive" *ngIf="details; else noDetails">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Produits</th>
                            <th scope="col">Nouveau</th>
                            <th scope="col">Ancien</th>
                            <th scope="col" colspan="1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of details">
                            <td>
                                <a routerLink="{{d.iddetail}}" title="Voir le detail"> {{ d.designation }}</a>
                            </td>
                            <td> {{d.prix2 | currency:'XOF'}} </td>
                            <td> {{d.prix | currency:'XOF'}} </td>
                            <td>
                                <a (click)="onDelete(d.iddetail)" class="text-danger" title="Supprimer"> X </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ng-template #noDetails>
                <p class="text-center" *ngIf="errorLoading"> {{errorLoading}} </p>
            </ng-template>

        </div>
        <div class="col-md-6">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>

<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>



<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title"> Nouveau detail du service </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="designation">Désignation</label>
                <input type="text" formControlName="designation" class="form-control">
            </div>

            <div class="form-group">
                <label for="cout">Prix ancien</label>
                <div class="input-group mb-2 mr-sm-2">
                    <input type="number" formControlName="prix" class="form-control" id="prix2" placeholder="0">
                    <div class="input-group-prepend">
                        <div class="input-group-text">CFA</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="cout">Prix nouveau</label>
                <div class="input-group mb-2 mr-sm-2">
                    <input type="number" formControlName="prix2" class="form-control" id="prix" placeholder="0">
                    <div class="input-group-prepend">
                        <div class="input-group-text">CFA</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="description">Description</label>
                <textarea formControlName="description" class="form-control" id="description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
            </div>
        </div>
    </form>
</ng-template>