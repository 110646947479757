import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  constructor() { }

  account() {
    return JSON.parse(localStorage.getItem('user'));
  }


  numberFormat(n: number): string {
    return isNaN(n) ? new Intl.NumberFormat('ci-CI', { useGrouping: true }).format(0) : new Intl.NumberFormat('ci-CI', { useGrouping: true, minimumIntegerDigits: 2, maximumFractionDigits: 2 }).format(n);
  }

  percent(nombre: number, effectif: number): string {
    if (nombre && effectif) {
      const n = (nombre / effectif) * 100;
      return new Intl.NumberFormat('ci-CI', { useGrouping: true, minimumIntegerDigits: 2, maximumFractionDigits: 2 }).format(n) + '%';
    } else {
      return '0%';
    }
  }

  phone(phone: number) {
    return new Intl.NumberFormat('ci-CI', { useGrouping: true, minimumIntegerDigits: 8 }).format(phone);
  }

  textLimit(text: string) {
    return text.substr(0, 20);
  }

  secondTime(s: number) {
    const today = new Date(0);
    today.setSeconds(s); // specify value for SECONDS here
    const timeString = today.toISOString().substr(11, 8);
    return timeString;
  }
  secondDay(s: number) {
    const today = new Date(0);
    today.setDate(s); // specify value for SECONDS here
    const timeString = today.toISOString().substr(11, 8);
    return timeString;
  }

  milliseconde(d) {

    return d ? new Date(d).getTime() : new Date('1990-01-01').getTime();
  }

  age(naissance) {
    const bdate = new Date(naissance);
    const timeDiff = Math.abs(Date.now() - bdate.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  yesNo(r) {
    return r === '1' ? 'OUI' : 'NON';
  }

  sex(sex: string) {
    return sex === 'M' ? 'Masculin' : 'Féminin';
  }

  evaluation(d) {
    const t = ['Non éxécutée', 'Éxécutéé', 'Reportée', 'Annuler'];
    return t[d];
  }

  appreciation(note: number) {
    if (note <= 8) { return 'Faible'; }
    else if (note > 8 && note <= 9.99) {
      return 'Insuffisant';
    }
    else if (note >= 10 && note <= 11.99) {
      return 'Passable';
    }
    else if (note >= 12 && note <= 14) {
      return 'Bon';
    } else {
      return 'Excellent';
    }
  }



}
