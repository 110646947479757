<span class="note" *ngFor="let note of notes">
  <span (dblclick)="open(contentNote)" *ngIf="user.role === 'directeur' ">{{note.note | number:'2.0-2'}}</span>
  <span *ngIf="user.role !== 'directeur' ">{{note.note | number:'2.0-2'}}</span>

  <ng-template #contentNote let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title"> Modifier note </h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click ')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert type="danger" (close)="errorMsg=' '" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
      <h5> Evaluation {{note.id}} </h5>
      <div class="form-group row">
        <label for="note" class="col-sm-2 col-form-label">Note</label>
        <div class="col-sm-5">
          <input type="text" class="form-control" [(ngModel)]="note.note"
            (keyup.enter)="onNoteUpdate({eleve: eleve.ideleve, note: note.note, evaluation: note.evaluation})" id="note"
            required aria-describedby="noteBlock">
          <small id="noteBlock" class="form-text text-muted">Taper "Entrer" pour enregister la nouvelle note ou
            "Echap"
            pour annuler</small>
        </div>

      </div>
      <div class="form-group">
        <p><button (click)="onDeleteNote(note.evaluation)" class="btn btn-danger">Supprimer</button></p>
      </div>
    </div>
  </ng-template>
</span>
<span> <a (click)="open(content)" *ngIf="user.role === 'directeur' " class="text-bold">+</a> </span>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Ajouter note </h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click ')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert type="danger" (close)="errorMsg=' '" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
    <h5 class="text-center"> {{eleve.nom}} {{eleve.prenoms}} </h5>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group row">
        <label for="evaluation" class="col-sm-2 col-form-label">Evaluation</label>
        <div class="col-sm-7">
          <select formControlName="evaluation" id="evaluation" class=" form-control"
            (change)="onEvaluationNote($event)">
            <option *ngFor="let evaluation of evaluations" [value]="evaluation.idevaluation">Evaluation
              {{evaluation.id}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="note" class="col-sm-2 col-form-label">Note</label>
        <div class="col-sm-7">
          <input type="text" formControlName="note" class="form-control" id="note" [value]="!!note ? note.note : '' ">
        </div>
      </div>

      <div class="form-group">
        <button type="submit" [disabled]="form.invalid || submitted === true">
          <span *ngIf="!submitted">Enrégistrer</span>
          <span *ngIf="submitted">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
          </span>
        </button>
      </div>
    </form>

  </div>
</ng-template>
