import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-authentication-oublie',
  templateUrl: './authentication-oublie.component.html',
  styleUrls: ['./authentication-oublie.component.css']
})
export class AuthenticationOublieComponent implements OnInit {
  form: FormGroup;
  errorMsg: string;
  errorType = '';
  submitted = false;

  constructor(
    private authenticationSce: AuthenticationService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      code: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.authenticationSce.oublie(this.form.value).subscribe(
      arg => {
        this.errorMsg = arg;
        this.errorType = 'success';
        this.submitted = false;
      },
      erg => {
        this.errorMsg = erg.error.message;
        this.errorType = 'danger';
        this.submitted = false;
      }
    );
  }

}
