import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { SuivisRoutingModule } from './suivis-routing.module';
import { SuivisComponent } from './suivis.component';
import { EvaluationsModule } from './evaluations/evaluations.module';
import { CahiersTextesModule } from './cahiers-textes/cahiers-textes.module';
import { NotesModule } from './notes/notes.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbAlertModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SeancesModule } from './seances/seances.module';
import { SanctionsModule } from './sanctions/sanctions.module';


@NgModule({
  declarations: [
    SuivisComponent
  ],
  imports: [
    CommonModule,
    SuivisRoutingModule,
    CahiersTextesModule,
    EvaluationsModule,
    NotesModule,
    SeancesModule,
    SanctionsModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ]
})
export class SuivisModule { }
