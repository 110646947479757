import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProduitsRoutingModule } from './produits-routing.module';
import { ProduitsComponent } from './produits.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ProduitsAbonnesComponent } from './produits-abonnes/produits-abonnes.component';
import { ProduitsViewComponent } from './produits-view/produits-view.component';
import { ProduitsDetailComponent } from './produits-detail/produits-detail.component';
import { ProduitsClasseComponent } from './produits-classe/produits-classe.component';
import { ProduitsEncadreurComponent } from './produits-encadreur/produits-encadreur.component';



@NgModule({
  declarations: [
    ProduitsComponent,
    ProduitsAbonnesComponent,
    ProduitsViewComponent,
    ProduitsDetailComponent,
    ProduitsClasseComponent,
    ProduitsEncadreurComponent
  ],
  imports: [
    CommonModule,
    ProduitsRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class ProduitsModule { }
