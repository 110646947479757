<p class="text-center">
    <a class="a" (click)="open(content)">Changer de mot de passe</a>
</p>

<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Changer de mot de passe</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>
            <div class="form-group">
                <label for="opassword">Ancien mot de passe</label>
                <input type="password" formControlName="opassword" class="form-control" id="opassword">
            </div>
            <div class="form-group">
                <label for="password">Nouveau mot de passe</label>
                <input type="password" formControlName="password" class="form-control" id="password">
            </div>
            <div class="form-group">
                <label for="cpassword">Repeter le mot de passe</label>
                <input type="password" formControlName="cpassword" class="form-control" id="cpassword">
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">ENREGISTRER</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Enregistrement
                  </span>
                </button>



            </div>
        </div>
    </form>
</ng-template>
