import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EcheanciersRoutingModule } from './echeanciers-routing.module';
import { EcheanciersComponent } from './echeanciers.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    EcheanciersComponent
  ],
  imports: [
    CommonModule,
    EcheanciersRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbModalModule,
    NgbPaginationModule
  ]
})
export class EcheanciersModule { }
