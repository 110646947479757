import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faCalendarTimes, faCalendarCheck, faLock, faLockOpen, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormattingService } from 'src/app/config/formatting.service';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cahiers-textes-view',
  templateUrl: './cahiers-textes-view.component.html',
  styleUrls: ['./cahiers-textes-view.component.css']
})
export class CahiersTextesViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  id: string;
  seances: Suivis[] = [];
  c: Suivis;
  loading = true;
  errorMsg = '';
  faError = faTimes;
  faSuccess = faCheck;
  today = Date.now();
  submitted = false;

  faRetard = faCalendarTimes;
  faDansTemps = faCalendarCheck;
  faVerrou = faLock;
  faDeverrou = faLockOpen;

  constructor(
    private ar: ActivatedRoute,
    private service: SuivisService,
    private fb: FormBuilder,
    private modalSce: NgbModal,
    public formatting: FormattingService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getClasse(this.id);
    this.getSeances(this.id);
  }

  getClasse(id: string): void {
    this.subscription.add(
      this.service.view('classes_details', id)
        .subscribe((arg: Suivis) => {
          this.c = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getSeances(id: string): void {
    this.subscription.add(
      this.service.views('seances_classes', id)
        .subscribe((arg: Suivis[]) => {
          this.seances = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }


  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    this.subscription.add(
      this.service.list(`seances?classe=${this.c.idclasse}&debut=${data.debut}&fin=${data.fin}`)
        .subscribe((arg: Suivis[]) => {
          this.seances = arg;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
