import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logistiques } from '../../logistiques';
import { LogistiquesService } from '../../logistiques.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-souscripteurs-view',
  templateUrl: './souscripteurs-view.component.html',
  styleUrls: ['./souscripteurs-view.component.css']
})
export class SouscripteursViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription = new Subscription();
  abonne: Logistiques;
  activites: Logistiques[];
  id: string;
  collection = 0;
  errorLoading = '';
  loading = true;

  constructor(
    private ar: ActivatedRoute,
    private service: LogistiquesService
  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getAbonne(this.id);
    this.getActivites(this.id);
  }


  getAbonne(id: string): void {
    this.subscription.add(
      this.service.view('abonnes', id)
        .subscribe(arg => {
          this.abonne = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }

  getActivites(id: string): void {
    this.subscription.add(
      this.service.views('activites', id)
        .subscribe(arg => {
          this.activites = arg;
          this.loading = false;
        },
          erg => {
            this.errorLoading = erg;
            this.loading = false;
          })
    );
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
