import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-professeur',
  templateUrl: './professeur.component.html',
  styleUrls: ['./professeur.component.css']
})
export class ProfesseurComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  toggle = false;

  constructor(
    private service: AuthenticationService
  ) { }

  ngOnInit(): void { }

  public onToggleMenu(): void {
    this.toggle = !this.toggle;
  }

  logout(): void {
    this.service.logout();
  }

}
