<h1>Tableau de bord</h1>

<div class="row">
    <div class="col-md-4">
        <div class="encard">
            <h4>
                <small>Etablissements scolaire</small> {{ecoles | number}}
            </h4>
            <p> <a routerLink="/{{user.role}}/ecoles">Voir plus</a> </p>
        </div>

    </div>
    <div class="col-md-4">
        <div class="encard">
            <h4> <small>Elèves</small>{{eleves | number}}</h4>
            <p> <a routerLink="">Voir plus</a> </p>
        </div>

    </div>
</div>


<div *ngIf="loading " class="text-center ">
    <span class="spinner-border spinner-border-sm " role="status " aria-hidden="true "></span> Veuillez patienter ...
</div>