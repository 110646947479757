import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { Logistiques } from '../logistiques';
import { LogistiquesService } from '../logistiques.service';
import { NgbModalModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-encadreurs',
  templateUrl: './encadreurs.component.html',
  styleUrls: ['./encadreurs.component.css']
})
export class EncadreursComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  public encadreurs: Logistiques[] = [];
  public form: FormGroup;
  submitted = false;
  errorMsg = '';
  roles = ['chauffeur', 'convoyeur', 'cantinier', 'maitre', 'entraineur'];

  subscription: Subscription = new Subscription();
  errorLoading = '';
  horsservice = 0;
  cantine = 0;
  garde = 0;
  vehicules = 0;
  itineraires = 0;
  loading = true;

  constructor(
    private service: LogistiquesService,
    private modal: NgbModal,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getEncadreurs();
    this.createForm();
  }

  getEncadreurs(): void {
    this.subscription.add(
      this.service.list('encadreurs')
        .subscribe(arg => {
          this.encadreurs = arg;
          this.loading = false;
        },
          erg => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  open(c: any): void {
    this.modal.open(c);
  }

  createForm(): void {
    this.form = this.fb.group({
      encadreur: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      pin: ['', Validators.required],
      piece: ['']

    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('encadreurs', this.form.value)
        .subscribe(
          arg => {
            this.getEncadreurs();
            this.submitted = false;
            this.modal.dismissAll();
          },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
