import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sanctions-view',
  templateUrl: './sanctions-view.component.html',
  styleUrls: ['./sanctions-view.component.css']
})
export class SanctionsViewComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  eleve: Suivis;
  sanctions: Suivis[] = [];
  types: Suivis[] = [];
  form: FormGroup;
  id: string;
  collection = 0;
  loading = true;
  today: Date = new Date();
  errorMsg = '';
  submitted = false;

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: SuivisService) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.paramMap.get('id');
    this.getEleve(this.id);
    this.getSanctions(this.id);
    this.getTypes();
    this.createForm();
  }
  getEleve(id: string): void {
    this.service.view('eleves', id)
      .subscribe((arg: Suivis) => {
        this.eleve = arg;
        this.loading = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }
  getSanctions(id: string): void {
    this.service.list(`sanctions?eleve=${id}`)
      .subscribe((arg: Suivis[]) => {
        this.sanctions = arg;
        this.collection = arg.length;
        this.loading = false;
      },
        (erg: never) => {
          this.errorMsg = erg;
          this.loading = false;
        });
  }

  getTypes(): void {
    this.subscription.add(
      this.service.list('sanctions_types')
        .subscribe((arg: Suivis[]) => {
          this.types = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  onClickSubmit(data: Suivis): void {
    this.submitted = true;
    this.subscription.add(
      this.service.list(`sanctions?eleve=${this.id}&debut=${data.debut}&fin=${data.fin}`)
        .subscribe((arg: Suivis[]) => {
          this.sanctions = arg;
          this.collection = arg.length;
          this.submitted = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  open(content: any): void {
    this.modal.open(content);
  }

  createForm(): void {
    this.form = this.fb.group({
      eleve: [this.id, Validators.required],
      fauteType: ['', Validators.required],
      faute: ['', Validators.required],
      sanctionDate: ['', Validators.required],
      convocation: [''],
      convocationDate: [''],
      convocationHeure: [''],
      reparation: [''],
      punition: [''],
      point: [''],
      exclu: [''],
      excluDebut: [''],
      excluFin: [''],
      levee: [''],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('sanctions', this.form.value)
        .subscribe(
          arg => {
            this.getSanctions(this.id);
            this.submitted = false;
            this.modal.dismissAll();
          },
          (erg: never) => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}


/*
 *   createForm(): void {
    this.form = this.fb.group({
      eleve: [this.id, Validators.required],
      fauteType: ['', Validators.required],
      faute: ['', Validators.required],
      sanctionDate: ['', Validators.required],
      convocation: [''],
      convocationDate: [''],
      convocationHeure: [''],
      reparation: [''],
      punition: [''],
      point: [''],
      exclu: [''],
      excluDebut: [''],
      excluFin: [''],
      levee: [''],
    });
  }
 */
