import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CahiersTextesComponent } from './cahiers-textes.component';
import { CahiersTextesViewComponent } from './cahiers-textes-view/cahiers-textes-view.component';
import { CahiersTextesAddComponent } from './cahiers-textes-add/cahiers-textes-add.component';

const routes: Routes = [
  { path: '', component: CahiersTextesComponent },
  { path: 'view/:id', component: CahiersTextesViewComponent },
  { path: 'add/:id', component: CahiersTextesAddComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CahiersTextesRoutingModule { }
