import { Component, OnInit } from '@angular/core';
import { DirecteurService } from '../../directeur/directeur.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Directeur } from '../../directeur/directeur';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-echeanciers',
  templateUrl: './echeanciers.component.html',
  styleUrls: ['./echeanciers.component.css']
})
export class EcheanciersComponent implements OnInit {
  user = JSON.parse(localStorage.getItem('user'));
  form: FormGroup;
  rubriques: Directeur[];
  frais: Directeur[];
  branches: Directeur[];
  echeances: Directeur[];
  statuts = [{
    code: '*',
    nom: 'Tous les statuts'
  }, {
    code: 'AFF',
    nom: 'AFFECTES'
  },
  {
    code: 'NAFF',
    nom: 'NON-AFFECTES'
  }, {
    code: 'PC',
    nom: 'PRISES EN CHARGE'
  }];
  loading = true;
  collection = 0;
  page = 1;
  pageSize = 10;
  submitted = false;
  errorMsg = '';

  constructor(
    private fb: FormBuilder,
    private service: DirecteurService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getRubriques();
    this.getFrais();
    this.getBranches();
    this.getEcheances();
    this.createForm();
  }

  getRubriques(): void {
    this.service.list('rubriques')
      .subscribe(arg => {
        this.rubriques = arg;
        this.loading = false;
      });
  }

  getFrais(): void {
    this.service.list('frais')
      .subscribe(arg => {
        this.frais = arg;
        this.collection = arg.length;
        this.loading = false;
      });
  }

  getBranches(): void {
    this.service.list('branches')
      .subscribe(arg => {
        this.branches = arg;
        this.loading = false;
      });
  }
  getEcheances() {
    this.service.list('echeances')
      .subscribe(arg => {
        this.echeances = arg;
        this.loading = false;
      });
  }

  open(content) {
    this.modalService.open(content);
  }

  createForm() {
    this.form = this.fb.group({
      branche: ['', Validators.required],
      rubrique: ['', Validators.required],
      echeance: ['', Validators.required],
      datelimite: ['', Validators.required],
      statut: ['', Validators.required],
      libelle: ['', Validators.required],
      montant: ['', Validators.required],
      montant2: ['', Validators.required],

    });
  }

  onSubmit() {
    this.submitted = true;
    this.service.add('frais', this.form.value)
      .subscribe(arg => {
        this.getFrais();
        this.form.reset();
        this.modalService.dismissAll();
        this.submitted = false;
      }, erg => {
        this.errorMsg = erg.error.message;
        this.submitted = false;
      });

  }

}
