import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivitesRoutingModule } from './activites-routing.module';
import { ActivitesComponent } from './activites.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivitesProduitsComponent } from './activites-produits/activites-produits.component';
import { ActivitesViewComponent } from './activites-view/activites-view.component';
import { ActivitesClasseComponent } from './activites-classe/activites-classe.component';


@NgModule({
  declarations: [
    ActivitesComponent,
    ActivitesProduitsComponent,
    ActivitesViewComponent,
    ActivitesClasseComponent
  ],
  imports: [
    CommonModule,
    ActivitesRoutingModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule
  ]
})
export class ActivitesModule { }
