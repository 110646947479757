import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfesseurService } from '../professeur.service';
import { Professeur } from '../professeur';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-professeur-home',
  templateUrl: './professeur-home.component.html',
  styleUrls: ['./professeur-home.component.css']
})
export class ProfesseurHomeComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  errorMsg = '';
  seances: Professeur[] = [];
  classes: Professeur[] = [];
  seancesInvalides = 0;
  seancesAbsents = 0;
  evaluation = 0;
  loading = true;
  today: Date = new Date();

  constructor(private service: ProfesseurService) { }


  ngOnInit(): void {
    this.getSeances();
    this.getClasses();
    this.getSeancesInvalides();
    this.getSeancesAbsents();

    this.getEvaluations();
  }

  getSeances(): void {
    this.subscription.add(
      this.service.list('seances_jour')
        .subscribe((arg: Professeur[]) => {
          this.seances = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          }
        )
    );
  }

  getSeancesInvalides(): void {
    this.service.list('seancesInvalides')
      .subscribe(arg => {
        this.seancesInvalides = arg.length;
        this.loading = false;
      });
  }

  getSeancesAbsents(): void {
    this.service.list('seancesAbsents')
      .subscribe(arg => {
        this.seancesAbsents = arg.length;
        this.loading = false;
      });
  }

  getClasses(): void {
    this.service.list('classes')
      .subscribe(arg => {
        this.classes = arg;
        this.loading = false;
      });

  }


  getEvaluations(): void {
    this.service.list('evaluations')
      .subscribe(arg => {
        this.evaluation = arg.length;
        this.loading = false;
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
