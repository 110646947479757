<h1>Services scolaires</h1>
<div *ngIf="s">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/produits">Services scolaires</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled">{{s.nom}}</a>
        </li>
    </ul>
    <div class="recherche">
        <div class="row">
            <div class="col-md-5">
                <h2>
                    {{s.nom}}
                    <small *ngIf="abonnes"> {{abonnes | number}} inscrits  </small>
                </h2>
            </div>
            <div class="col-md-7"></div>
        </div>

    </div>
</div>
<div class="table-responsive" *ngIf="produits; else noProduits">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Produits</th>
                <th scope="col" *ngIf="s.service === 'TRANS' ">Zone</th>
                <th scope="col">Nouveau</th>
                <th scope="col">Anciens</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of produits">
                <td> {{ p.designation }}
                    <small> {{p.description}} </small>
                </td>
                <td *ngIf="s.service === 'TRANS' "> {{p.zone}} </td>
                <td> {{p.prix2 | currency:'XOF'}} </td>
                <td> {{p.prix | currency:'XOF'}} </td>
                <td>
                    <a routerLink="/{{user.role}}/produits/detail/{{p.iddetail}}">Voir detail</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noProduits>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>
