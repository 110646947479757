import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Suivis } from '../../suivis';
import { SuivisService } from '../../suivis.service';
import { take } from 'rxjs/operators';
import { CompressImageService } from '../../../config/compress-image.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-cahiers-textes-fichier',
  templateUrl: './cahiers-textes-fichier.component.html',
  styleUrls: ['./cahiers-textes-fichier.component.css']
})
export class CahiersTextesFichierComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  @Input() id: string;
  c: Suivis;
  submitted = false;
  errorMsg = ''; errorType = '';
  selectedFile: File;
  charger = false;
  progress = 0;
  loading = true;



  constructor(
    private compressImage: CompressImageService,
    private service: SuivisService,
  ) { }
  ngOnInit(): void {
  }





  onSelectFile(event): void {
    if (event.target.files.length > 0) {
      if (event.target.files[0].type !== 'application/pdf') {
        this.subscription.add(
          this.compressImage.compress(event.target.files[0])
            .pipe(take(1))
            .subscribe(compressedImage => {
              this.selectedFile = compressedImage;
            })
        );
      } else {
        this.selectedFile = event.target.files[0];
      }
    }
  }


  onSubmit(): void {
    this.submitted = true;
    const formData = new FormData();
    formData.append('cahier', this.id);
    formData.append('fichier', this.selectedFile);
    this.subscription.add(
      this.service.upload('cahier_upload', formData)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Request has been header');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              setTimeout(() => {
                this.selectedFile = null;
                this.progress = 0;
                this.submitted = false;
              }, 1500);
          }
        }, (erg: never) => {
          this.progress = 0;
          this.errorMsg = erg;
          this.submitted = false;
        })
    )
      ;

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
