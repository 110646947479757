<div class="row justify-content-center">
    <div class="col-lg-5">
        <ngb-alert *ngIf="errorMsg" type="danger" (close)="errorMsg=''"> {{errorMsg}} </ngb-alert>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="col-lg-7">
                    <div class="form-group">
                        <input type="text" formControlName="login" placeholder="IDENTIFIANT" class="form-control">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="form-group">
                        <input type="text" formControlName="code" placeholder="CODE ÉCOLE" class="form-control">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <input type="password" formControlName="password" placeholder="MOT DE PASSE" class="form-control">
                <p class="text-right"><a routerLink="/authentication/mot-passe-oublie">Mot de passe oublié ?</a></p>

            </div>

            <div class="form-group">
                <button type="submit" [disabled]="form.invalid || submitted === true">
                  <span *ngIf="submitted === false">Connexion</span>
                  <span *ngIf="submitted === true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Vérification en cours
                  </span>
                </button>
            </div>
        </form>

    </div>
</div>