import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProduitsComponent } from './produits.component';
import { ProduitsViewComponent } from './produits-view/produits-view.component';
import { ProduitsDetailComponent } from './produits-detail/produits-detail.component';

const routes: Routes = [
  { path: '', component: ProduitsComponent },
  { path: 'view/:id', component: ProduitsViewComponent },
  { path: 'detail/:id', component: ProduitsDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProduitsRoutingModule { }
