<h6>Assurance </h6>
<p *ngIf="charge; else noCharge">
    <strong>{{charge.debut | date}} au {{charge.fin | date}}</strong>
    <br> <a class="a" (click)="open(content)">Modifier</a>
</p>
<ng-template #noCharge>
    <p> <a class="a" (click)="open(content)">Ajouter</a></p>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">
                Assurances
            </h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <ngb-alert type="danger" (close)="errorMsg=''" *ngIf="errorMsg"> {{ errorMsg }} </ngb-alert>

            <div class="form-group">
                <label for="lecon">Nom de la charge :</label>
                <input type="text" formControlName="charge" class="form-control">
            </div>
            <div class="form-row">
                <div class="form-group col-md-5">
                    <label for="cout">Prix</label>
                    <div class="input-group mb-2 mr-sm-2">
                        <input type="number" formControlName="cout" class="form-control" id="cout" placeholder="0">
                        <div class="input-group-prepend">
                            <div class="input-group-text">CFA</div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-7">
                    <label for="fournisseur">Fournisseur :</label>
                    <input type="text" formControlName="fournisseur" class="form-control">
                </div>
            </div>

            <h6>Période</h6>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="debut">Date de debut</label>
                    <input type="date" formControlName="debut" class="form-control">
                </div>

                <div class="form-group col-md-6">
                    <label for="fin">Date de fin</label>
                    <input type="date" formControlName="fin" class="form-control">
                </div>
            </div>

            <div class="form-group">
                <button type="submit" [disabled]="!form.valid || submitted">
                <span *ngIf="!submitted">Enrégistrer</span>
                    <span *ngIf="submitted">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>enrégistrement
                    </span>
              </button>
            </div>
        </div>


    </form>
</ng-template>