<h1>Services scolaires</h1>

<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
    </li>
    <li class="nav-item">
        <a class="nav-link disabled">Services scolaires</a>
    </li>
</ul>
<div class="recherche">
    <h2>Services scolaires </h2>
</div>

<div class="table-responsive" *ngIf="services; else noProduits">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Services</th>
                <th scope="col">Inscrits</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let s of services; let i=index">
                <td> {{i+1}} </td>
                <td> <a routerLink="/{{user.role}}/services/{{s.code}}" title="Voir le detail">{{ s.nom }}</a> </td>

                <td>
                    <app-logistiques-services-abonnes [id]="s.code"></app-logistiques-services-abonnes>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noProduits>
    <p class="errorLoading text-center"> {{errorLoading}} </p>
</ng-template>


<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>