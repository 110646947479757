import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Directeur } from './directeur';


@Injectable({
  providedIn: 'root'
})
export class DirecteurService {
  url = 'https://staff.vie-ecoles.com/server/directeurs/';

  options = { params: new HttpParams({ fromString: 'token=' + JSON.parse(localStorage.getItem('user')).token }) };

  constructor(private http: HttpClient) { }

  add(table: string, body: Directeur): Observable<string> {
    return this.http.post<string>(this.url + table, body, this.options)
      .pipe(catchError(this.errorHandler));
  }

  put(table: string, body: Directeur): Observable<string> {
    return this.http.put<string>(this.url + table, body, this.options)
      .pipe(catchError(this.errorHandler));
  }

  list(table: string): Observable<Directeur[]> {
    return this.http.get<Directeur[]>(this.url + table, this.options)
      .pipe(catchError(this.errorHandler));
  }

  view(table: string, id: string = null): Observable<Directeur> {
    if (id) {
      return this.http.get<Directeur>(this.url + table + '/' + id, this.options)
        .pipe(catchError(this.errorHandler));
    } else {
      return this.http.get<Directeur>(this.url + table, this.options)
        .pipe(catchError(this.errorHandler));
    }

  }

  views(table: string, id: string): Observable<Directeur[]> {
    return this.http.get<Directeur[]>(this.url + table + '/' + id, this.options)
      .pipe(catchError(this.errorHandler));
  }

  deuxArg(table: string, id: string, id2: string): Observable<Directeur> {
    return this.http.get<Directeur>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.errorHandler));
  }


  deuxArgs(table: string, id: string, id2: string): Observable<Directeur[]> {
    return this.http.get<Directeur[]>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.errorHandler));
  }

  troisArg(table: string, id: string, id2: string, id3: string): Observable<Directeur> {
    return this.http.get<Directeur>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.errorHandler));
  }

  troisArgs(table: string, id: string, id2: string, id3: string): Observable<Directeur[]> {
    return this.http.get<Directeur[]>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.errorHandler));
  }


  public upload(table: string, formData): Observable<any> {
    return this.http.post<any>(this.url + table, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }

}
