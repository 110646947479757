import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Suivis } from 'src/app/suivis/suivis';
import { SuivisService } from 'src/app/suivis/suivis.service';

@Component({
  selector: 'app-note-matiere-eleve',
  templateUrl: './note-matiere-eleve.component.html',
  styleUrls: ['./note-matiere-eleve.component.css']
})
export class NoteMatiereEleveComponent implements OnChanges, OnDestroy {
  user = JSON.parse(localStorage.getItem('user'));
  subscription: Subscription = new Subscription();
  form: FormGroup;
  @Input() eleve: Suivis;
  @Input() classe: string;
  @Input() matiere: string;
  @Input() periode: string;
  evaluations: Suivis[];
  evaluation = '';
  notes: Suivis[];
  note: Suivis;
  moyennes: Suivis;
  loading = true;
  errorMsg = '';
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private service: SuivisService) { }

  ngOnChanges(): void {
    this.getNotes(this.eleve.ideleve, this.matiere);
    this.getMoyenne();
    this.getEvaluations(this.eleve.idclasse, this.matiere, this.periode);
    this.createForm();
  }

  open(c): void {
    this.modal.open(c);
  }
  getEvaluations(id: string, matiere: string, periode: string): void {
    this.subscription.add(
      this.service.list(`evaluations_classe_executee?classe=${id}&matiere=${matiere}&periode=${periode}`)
        .subscribe(arg => {
          this.evaluations = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      note: ['', Validators.required],
      evaluation: ['', Validators.required],
      eleve: [this.eleve.ideleve, Validators.required]
    });
  }
  onSubmit(): void {
    this.submitted = true;
    this.subscription.add(
      this.service.add('notes', this.form.value)
        .subscribe(arg => {
          this.getNotes(this.eleve.ideleve, this.matiere);
          this.submitted = false;
          // location.reload();
        },
          erg => {
            this.errorMsg = erg;
            this.submitted = false;
          }
        )
    );
  }

  getNotes(id: string, matiere: string): void {
    this.subscription.add(
      this.service.list(`notes_eleve?eleve=${id}&matiere=${matiere}&periode=${this.periode}`)
        .subscribe(arg => {
          this.notes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onEvaluationNote(data): void {
    this.subscription.add(
      this.service.deuxArg('notes', data.target.value, this.eleve.ideleve)
        .subscribe(arg => {
          this.note = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  getMoyenne(): void {
    this.subscription.add(
      this.service.one(`moyennes?eleve=${this.eleve.ideleve}&matiere=${this.matiere}&periode=${this.periode}`)
        .subscribe(arg => {
          this.moyennes = arg;
          this.loading = false;
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          })
    );
  }

  onNoteUpdate(data): void {
    console.log(data);
    if (!!data.note) {
      this.subscription.add(
        this.service.add('notes', data)
          .subscribe(arg => {
            this.submitted = false;
            this.modal.dismissAll();
            location.reload();
          },
            erg => {
              this.errorMsg = erg;
              this.submitted = false;
            }
          )
      );
    }
  }

  onDeleteNote(evaluation: string): void {
    const r = confirm('Vous êtes sûr de la suppression ?, cette action est irrevestible');
    if (r) {
      this.service.delete('notes', evaluation, this.eleve.ideleve)
        .subscribe(arg => {
          this.getNotes(this.eleve.ideleve, this.matiere);
          this.modal.dismissAll();
          location.reload();
        },
          (erg: never) => {
            this.errorMsg = erg;
            this.loading = false;
          });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
